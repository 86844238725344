import axios from 'axios'
import React, {useEffect, useState} from 'react'
import { Link, useNavigate  } from 'react-router-dom'
import { useApiContext  } from '../layout/Template';
import TextInput from '../../form/InputText';
import SelectInput from '../../form/SelectInput';
import SearchSelect from '../../form//SearchSelect';
import QuillEditor from '../../form/QuillEditor';
import FileInput from '../../form/FileInput';
import DateInput from '../../form/DateInput';
import DateTimeInput from '../../form/DateTimeInput';
import DatosGeneralesContext from '../widget/DatosGeneralesContext';

const FormContent = ({ formData, setFormData, isEdit = false, isShow = false, rubrosPrevios, errors }) => {
    const navigate = useNavigate();
    const { apiUrl, accessToken } = useApiContext();
    const [cuentas, setCuentas] = useState([]);
    const [cajas, setCajas] = useState([]);
    const [rubros, setRubros] = useState([]);
    const [rubrosSeleccionados, setRubrosSeleccionados] = useState(isEdit ? rubrosPrevios : []);

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(`${apiUrl}/movimientos/create`, { headers: { Authorization: `Bearer ${accessToken}` } });
            setCuentas(response.data.cuentas);
            setCajas(response.data.cajas);
        };
        fetchData();
    }, [apiUrl, accessToken]);

    const { bancos } = DatosGeneralesContext(apiUrl);
    
    // Definir handleInputChange dentro del componente
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleFileChange = (fileItems, name) => {
        // Extraer el archivo de fileItems y establecer en el estado
        const file = fileItems.length > 0 ? fileItems[0].file : null;
        setFormData((prevState) => ({
            ...prevState,
            [name]: file, // Usa el nombre del input para actualizar el estado
        }));
    };
    
    useEffect(() => {
        //console.log("ID Representante:", formData.id_representante);
        
        const fetchRubros = async () => {
            if (formData.id_representante) {
                try {
                    const response = await axios.get(`${apiUrl}/movimientos/rubros/${formData.id_representante}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });
                    setRubros(response.data); // Almacena las deudas en el estado
                    //console.log("Deudas:", response.data); // Muestra las deudas en consola
                    setRubrosSeleccionados(isEdit ? rubrosPrevios : []);
                    setFormData(prev => ({
                        ...prev,
                    rubros: isEdit ? rubrosPrevios.map(rubro => rubro.id) : [],
                    valor_pago: isEdit ? rubrosPrevios.map(rubro => rubro.valor) : []
                    }));
                } catch (error) {
                    //console.error("Error al obtener deudas:", error);
                    setRubros([]); // Limpiar las deudas en caso de error
                    setRubrosSeleccionados([]);
                    setFormData(prev => ({
                        ...prev,
                        rubros: [], // Reinicia el array de rubros
                        valor_pago: [] // Reinicia los valores de pago
                    }));
                }
            } else {
                // Limpiar rubros si el id_representante está vacío
                setRubros([]);
                setRubrosSeleccionados([]);
                setFormData(prev => ({
                    ...prev,
                    rubros: [], // Reinicia el array de rubros
                    valor_pago: [] // Reinicia los valores de pago
                }));
            }
        };
    
        fetchRubros();
    }, [formData.id_representante, apiUrl, accessToken]);

    //tabla rubros

    const agregarRubro = (rubro) => {
        // Evitar agregar rubros duplicados
        if (!rubrosSeleccionados.some(selected => selected.id === rubro.id)) {
            setRubrosSeleccionados((prev) => [...prev, rubro]);
        
            // Agregar ID del rubro a formData.rubros
            setFormData(prev => ({
                ...prev,
                rubros: [...prev.rubros, rubro.id], // Agrega el ID al array
                valor_pago: [...prev.valor_pago, rubro.valor] // Inicializa el valor de pago correspondiente
            }));
            //console.log(rubrosSeleccionados)
        }
    };

    const eliminarRubro = (id) => {
        // Filtra `rubrosSeleccionados` para eliminar el rubro seleccionado
        setRubrosSeleccionados((prev) => prev.filter(rubro => rubro.id !== id));
    
        setFormData((prev) => {
            const rubrosActualizados = prev.rubros.filter(rubroId => rubroId !== id);
            const valorPagoActualizado = prev.valor_pago.filter((_, index) => prev.rubros[index] !== id);
    
            return {
                ...prev,
                rubros: rubrosActualizados,
                valor_pago: valorPagoActualizado
            };
        });
    };

    const handleInputInv = (index, maxValue) => (e) => {
        const value = e.target.value;
        // Expresión regular para validar: solo números y un punto decimal, máximo 2 decimales
        const regex = /^\d*\.?\d{0,2}$/;
    
        // Verificar que el valor no exceda el máximo
        if (regex.test(value) || value === '') {
            if (value !== '' && parseFloat(value) > maxValue) {
                // Si el valor es mayor que el máximo, no actualizamos el estado
                return; // Salimos sin hacer nada
            }
    
            setFormData(prev => {
                const newValorPago = [...prev.valor_pago]; // Crea una copia del array actual
                newValorPago[index] = value; // Asigna el valor en el índice correspondiente
    
                return {
                    ...prev,
                    valor_pago: newValorPago // Actualiza el valor_pago
                };
            });
        }
    };

    const [editingIndex, setEditingIndex] = useState(null);
    const [originalMontoValues, setOriginalMontoValues] = useState([]);
    const [montoValues, setMontoValues] = useState(
        rubrosSeleccionados.map((rubro) => Number(rubro.monto_total).toFixed(2))
    );

    const handleMontoChange = (index, value) => {
        const newValues = [...montoValues];
        newValues[index] = value;
        setMontoValues(newValues);
    };

    const handleEdit = (index) => {
        // Guardar el valor original de monto_total antes de editar
        setOriginalMontoValues((prevValues) => {
          const updatedValues = [...prevValues];
          updatedValues[index] = rubrosSeleccionados[index].monto_total;
          return updatedValues;
        });
        setEditingIndex(index);
    };

    const handleCancel = () => {
        // Restaurar el valor original de monto_total
        setMontoValues((prevValues) => {
          const updatedValues = [...prevValues];
          updatedValues[editingIndex] = originalMontoValues[editingIndex];
          return updatedValues;
        });
        setEditingIndex(null);
    };

    const handleSave = async (index) => {
        try {
          const rubro = rubrosSeleccionados[index];
          const endpoint = `${apiUrl}/actualizarRubro2/${rubro.id}`;
          const response = await axios.put(
            endpoint,
            { monto_total: montoValues[index] },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          window.showSuccessPopup(response.data.message);

          // Calcular el nuevo valor de pendiente
          const nuevoPendiente = Number(montoValues[index]) - Number(formData.valor_pago[index]);
  
          // Actualizar el valor de monto_total y pendiente en el array rubrosSeleccionados
          setRubrosSeleccionados((prevRubros) => {
              const updatedRubros = [...prevRubros];
              updatedRubros[index].monto_total = montoValues[index];
              updatedRubros[index].pendiente = nuevoPendiente; // Actualizar el valor de pendiente
              return updatedRubros;
          });
        } catch (error) {
            handleCancel();
            // Verificar si existe un mensaje de error en la respuesta
            if (error.response) {
                // Manejar errores de validación
                if (error.response.data.errors) {
                    const validationErrors = error.response.data.errors;
                    const errorMessages = Object.values(validationErrors)
                        .flat() // Aplana el array de mensajes
                        .join(', '); // Junta los mensajes en una sola cadena
                    window.showErrorPopup(errorMessages);
                } else if (error.response.data.error) {
                    // Manejar errores específicos
                    window.showErrorPopup(error.response.data.error);
                } else {
                    console.error('Error desconocido al actualizar el rubro:', error);
                }
            } else {
                console.error('Error al realizar la solicitud:', error);
            }
        }
        setEditingIndex(null);
    };

    return (
        <>
            <div className='row'>
                <div className='col-12 col-md-3 col-lg-3'>
                    <SelectInput
                        id="forma_pago"
                        name="forma_pago"
                        value={formData.forma_pago}
                        onChange={handleInputChange}
                        placeholder="Forma de pago"
                        options={[
                            { value: 1, label: 'Efectivo' },
                            { value: 2, label: 'Transferencia' },
                            { value: 3, label: 'Cheque' },
                        ]}
                        disabled={isShow}
                        error={errors['forma_pago'] && errors['forma_pago'][0]}
                    />
                </div>
                <div className='col-12 col-md-3 col-lg-3'>
                    <SelectInput
                        id="id_caja"
                        name="id_caja"
                        value={formData.id_caja}
                        onChange={handleInputChange}
                        placeholder="Caja"
                        options={cajas.map(caja => ({
                            value: caja.id,
                            label: caja.num + ' - ' + caja.nombre
                        }))}
                        disabled={isShow}
                        error={errors['id_caja'] && errors['id_caja'][0]}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-12 col-md-3 col-lg-3'>
                    <TextInput
                        name="referencia_pago"
                        value={formData.forma_pago == 1 ? 'EFECTIVO' : formData.referencia_pago}
                        onChange={handleInputChange}
                        placeholder="# Referencia"
                        validacion={'alfanumerico'}
                        readonly={(formData.forma_pago == 1 || isShow == true)}
                        errors={errors}
                    />
                </div>
                <div className={`col-12 col-md-3 col-lg-3 ${formData.forma_pago == 1 ? 'd-none' : ''}`}>
                    <SelectInput
                        id="id_banco"
                        name="id_banco"
                        value={formData.id_banco}
                        onChange={handleInputChange}
                        placeholder="Banco origen"
                        options={bancos.map(banco => ({
                            value: banco.id,
                            label: banco.descripcion
                        }))}
                        disabled={isShow}
                        error={errors['id_banco'] && errors['id_banco'][0]}
                    />
                </div>
            </div>
            <div className={`row ${formData.forma_pago == 1 ? 'd-none' : ''}`}>
                <div className='col-12 col-md-3 col-lg-3'>
                    <DateInput
                        label="Fecha"
                        name="fecha"
                        value={formData.fecha}
                        onChange={handleInputChange}
                        placeholder="Fecha"
                        isDateTime={true}
                        errors={errors}
                        disabled={isShow}
                    />
                </div>
                <div className='col-12 col-md-3 col-lg-3'>
                    <SelectInput
                        id="id_cuenta"
                        name="id_cuenta"
                        value={formData.id_cuenta}
                        onChange={handleInputChange}
                        placeholder="Cuenta Deposito"
                        options={cuentas.map(cuenta => ({
                            value: cuenta.id,
                            label: cuenta.banco.descripcion + ' - ' + cuenta.cuenta
                        }))}
                        disabled={isShow}
                        error={errors['id_cuenta'] && errors['id_cuenta'][0]}
                    />
                </div>
            </div>
            {!isEdit ? (
                <SearchSelect 
                    apiUrl={apiUrl}
                    endpoint="busqueda/personas"
                    accessToken={accessToken}
                    label="Persona"
                    name="id_representante"
                    formData={formData}
                    setFormData={setFormData}
                    errors={errors}
                />
            ):(
                <div className="form-group">
                    <label>Persona</label>
                    <input
                        value={formData.representante} 
                        type="text"
                        className="form-control"
                        disabled
                    />
                </div>
            )}
            {rubros.length > 0 && !isShow && (
                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#rubrosModal">
                    Ver Rubros
                </button>
            )}
            
            <hr/>

            {rubrosSeleccionados.length > 0 && (
                <div className="table-responsive">
                    <table className="table" id="tablaItems">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Estudiante</th>
                                <th>Tipo</th>
                                <th>Facturado</th>
                                <th>Valor Total</th>
                                <th>Saldo</th>
                                <th>Valor a Pagar</th>
                            </tr>
                        </thead>
                        <tbody>
                        {rubrosSeleccionados.map((rubro, index) => (
                            <tr key={rubro.id}>
                                <td>
                                    <button disabled={isShow} className='btn btn-sm btn-outline-danger' type="button" onClick={() => eliminarRubro(rubro.id)}><i className='fas fa-trash text-danger'></i></button>
                                </td>
                                <td>
                                    <Link to={`/MisCursos/Matricula/${rubro.id_estudiante}`} className='text-decoration-none'>{rubro.nombre_estudiante}</Link>
                                </td>
                                <td>
                                    <Link to={`/MisCursos/Matricula/${rubro.id_estudiante}`} className='text-decoration-none'>{rubro.tipoText}</Link>
                                </td>
                                <td>
                                    {rubro.facturado === 1 ? (
                                        <p>Facturado</p>
                                    ) : (
                                        <button 
                                            type='button' 
                                            className='btn btn-sm btn-primary' 
                                            onClick={() => navigate(`/facturacionv1/create/${rubro.id}`)}
                                        >
                                            <i className="fas fa-file-import"></i> Facturar
                                        </button>
                                    )}
                                </td>
                                <td>
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            disabled={editingIndex !== index}
                                            value={editingIndex === index ? montoValues[index] : Number(rubro.monto_total).toFixed(2)}
                                            onChange={(e) => handleMontoChange(index, e.target.value)}
                                        />
                                        {!isShow && (
                                            editingIndex !== index ? (
                                                <button
                                                    type="button"
                                                    className="input-group-text btn-sm btn-primary"
                                                    onClick={() => handleEdit(index)}
                                                >
                                                    <i className="fas fa-edit"></i>
                                                </button>
                                            ) : (
                                                <>
                                                    <button
                                                        type="button"
                                                        className="input-group-text btn-sm btn-success"
                                                        onClick={() => handleSave(index)}
                                                    >
                                                        <i className="fas fa-save"></i>
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="input-group-text btn-sm btn-secondary"
                                                        onClick={handleCancel}
                                                    >
                                                        <i className="fas fa-times"></i>
                                                    </button>
                                                </>
                                            )
                                        )}
                                    </div>
                                </td>
                                <td>${(Number(rubro.pendiente)).toFixed(2)}</td>
                                <td>
                                    {/* Input oculto para almacenar el ID del rubro, similar a name="rubros[]" */}
                                    <input type="hidden" name="rubros[]" value={rubro.id} />

                                    {/* Input de valor de pago utilizando handleInputInv */}
                                    <input 
                                        className={`form-control ${errors?.[`valor_pago.${index}`] ? 'is-invalid' : ''}`}
                                        type="text"
                                        name="valor_pago[]"
                                        value={formData.valor_pago[index] !== undefined ? formData.valor_pago[index] : rubro.valor}
                                        onChange={handleInputInv(index, rubro.maximo)}
                                        disabled={isShow}
                                    />
                                    {errors?.[`valor_pago.${index}`] && (
                                        <div className="invalid-feedback">
                                            {errors[`valor_pago.${index}`][0]} {/* Muestra el primer mensaje de error */}
                                        </div>
                                    )}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )}
            
            <div className='row'>
                <div className='col-lg-6 col-md-4 col-sm-12'>
                    <div className="form-floating">
                        <textarea
                            disabled={isShow}
                            className="form-control"
                            id="descripcion"
                            name="descripcion"
                            maxLength="250"
                            style={{ height: 'calc(6.5rem + 2px)' }} // Corregido
                            value={formData.descripcion}
                            onChange={handleInputChange}
                        />
                        <label htmlFor="descripcion_beca">Descripción:</label>
                    </div>
                </div>
                <div className='col-lg-6 col-md-8 col-sm-12'>
                    <FileInput
                        label="Archivo"
                        name="adjunto"
                        onChange={(files) => handleFileChange(files, 'adjunto')} // Pasar el nombre
                        errors={errors['adjunto']}
                        maxFiles={1}
                        multiple={false}
                        instantUpload={false}
                        maxSize={3 * 1024 * 1024}
                        fileUrl={isEdit && formData.adjunto ? formData.adjunto : null}
                        isEdit={isEdit}
                    />
                </div>
            </div>

            {/* Modal */}
            <div className="modal fade" id="rubrosModal" tabIndex="-1" aria-labelledby="rubrosModalLabel" aria-hidden="true">
                <div className="modal-dialog  modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="rubrosModalLabel">Rubros Pendientes</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body" style={{padding: "0px"}}>
                            <div className='table-responsive'>
                                {rubros.length > 0 ? (
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Periodo</th>
                                                <th>Estudiante</th>
                                                <th>Vencimiento</th>
                                                <th>Tipo</th>
                                                <th>Monto Pendiente</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {rubros.map((rubro) => {
                                                const isSelected = rubrosSeleccionados.some(selected => selected.id === rubro.id);

                                                return (
                                                    <tr 
                                                        onClick={() => agregarRubro(rubro)} 
                                                        key={rubro.id}
                                                        style={{ 
                                                            cursor: 'pointer',
                                                            backgroundColor: isSelected ? '#e0f7fa' : 'transparent',
                                                            fontWeight: isSelected ? 'bold' : 'normal'
                                                        }}
                                                    >
                                                        <td>
                                                            {isSelected && <i className="fas fa-check" style={{ color: '#4caf50', marginRight: '5px' }}></i>}
                                                            {rubro.periodo_descripcion}
                                                        </td>
                                                        <td>{rubro.nombre_estudiante}</td>
                                                        <td>{rubro.vencimiento}</td>
                                                        <td>{rubro.tipoText}</td>
                                                        <td>${(Number(rubro.pendiente)).toFixed(2)}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                ) : (
                                    <p>No hay rubros disponibles.</p>
                                )}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FormContent;