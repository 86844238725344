// Tooltip.js
import React, { useState } from 'react';

const Tooltip = ({ text, children }) => {
    const [visible, setVisible] = useState(false);

    const handleMouseEnter = () => setVisible(true);
    const handleMouseLeave = () => setVisible(false);

    const tooltipStyle = {
        position: 'absolute',
        backgroundColor: 'black',
        color: 'white',
        padding: '5px',
        borderRadius: '5px',
        zIndex: 1000,
        top: '30px', // Ajusta la posición vertical del tooltip hacia abajo
        left: '50%', // Centra el tooltip
        transform: 'translateX(-50%)', // Centra el tooltip horizontalmente
        whiteSpace: 'nowrap', // Evita que el texto se divida
        fontSize: '12px', // Tamaño de fuente del tooltip
        display: visible ? 'block' : 'none', // Mostrar u ocultar el tooltip
    };

    return (
        <div 
            onMouseEnter={handleMouseEnter} 
            onMouseLeave={handleMouseLeave} 
            style={{ position: 'relative', display: 'inline-block' }}
        >
            {children}
            <div style={tooltipStyle}>{text}</div>
        </div>
    );
};

export default Tooltip;
