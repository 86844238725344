import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useApiContext } from '../layout/Template';
import { descargarPdf } from '../widget/DescargarPdf'; // Importa la función
import DatosGeneralesContext from '../widget/DatosGeneralesContext';
import SelectInput from '../../form//SelectInput';
import ListaEstudiantes from '../cursos/ListaEstudiantes ';

const Index = () => {
    const { apiUrl, accessToken } = useApiContext();
    
    // Estados para los campos del formulario
    const [cursos, setCursos] = useState([]);
    const [meses, setMeses] = useState([]);
    const [periodo, setPeriodo] = useState('');
    const [curso, setCurso] = useState('');
    //Lista de
    const [estudiantes, setEstudiantes] = useState([]);
    const [pagination, setPagination] = useState({
        current_page: 1,
        from: 0,
        to: 0,
        total: 0,
        links: [],
    });
    
    const [currentPage, setCurrentPage] = useState(1);

    // Estado para almacenar los parámetros de descarga, inicializándolos a ''
    const [pdfParams, setPdfParams] = useState({
        curso: '',
    });

    const { periodos } = DatosGeneralesContext(apiUrl);

    // Función para manejar el envío del formulario
    const handleSubmit = (e) => {
        e.preventDefault();
        // Establecer los parámetros para los PDFs
        setPdfParams({
            curso: curso || '',
        });
    };

    const handleClearFilters = () => {
        // Resetear todos los campos del formulario y los parámetros PDF
        setCursos([]);
        setPeriodo('');
        setCurso('');
        setEstudiantes([]);
        // Restablecer los parámetros PDF a ''
        setPdfParams({
            curso: '',
        });
    };
    
    const payload = {
        curso: curso || '',
    };
    
    const handlePeriodoChange = async (e, setPeriodo) => {
        const selectedValue = e.target.value;
        setPeriodo(selectedValue);
        setCurso('');
        setCursos([]);
        setMeses([]);
        setEstudiantes([]);
        const cursosResponse = await axios.get(`${apiUrl}/cursos/listar/${selectedValue}`, { headers: { Authorization: `Bearer ${accessToken}` } });
        setCursos(cursosResponse.data.cursos);
        const mesesResponse = await axios.get(`${apiUrl}/periodos/${selectedValue}`, { headers: { Authorization: `Bearer ${accessToken}` } });
        setMeses(mesesResponse.data.meses);
    };

    useEffect(() => {
        if (curso) {
            fetchEstudiantes(currentPage);
        }
    }, [curso, currentPage]);
    
    const handleCursoChange = (e) => {
        const selected = e.target.value;
        setCurso(selected);
        setCurrentPage(1);
    };

    const fetchEstudiantes = async (page = 1) => {
        try {
            const estudiantesResponse = await axios.get(`${apiUrl}/miscursos/matriculados/${curso}`, { headers: { Authorization: `Bearer ${accessToken}` } });
            setEstudiantes(estudiantesResponse.data.estudiantes.data);
            setPagination({
                current_page: estudiantesResponse.data.estudiantes.current_page,
                from: estudiantesResponse.data.estudiantes.from,
                to: estudiantesResponse.data.estudiantes.to,
                total: estudiantesResponse.data.estudiantes.total,
                links: estudiantesResponse.data.estudiantes.links,
            });
        } catch (error) {
            console.error('Error fetching estudiantes', error.estudiantesResponse ? error.estudiantesResponse.data : error.message);
        }
    };
    

    return (
        <div className='py-3 px-3 content-wrapper'>
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2>Analisis de pagos de pensiones</h2>
                </div>
                <div>
                    <div className="btn-group" role="group" aria-label="Basic outlined example">
                        <div className="btn-group" role="group">
                            <button type="button" className="btn btn-outline-primary btn-sm dropdown-toggle" id="pdfDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="far fa-file-pdf"></i>
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="pdfDropdown">
                                <li>
                                    {periodo && (
                                        <button className="dropdown-item" onClick={() => descargarPdf(`${apiUrl}/analisipagos/anual/pdf/${periodo}`, null, 'analisis_pagos_periodo.pdf', accessToken, '')}>
                                            Reporte General Periodo Lectivo
                                        </button>
                                    )}
                                    {curso && (
                                        <button className="dropdown-item" onClick={() => descargarPdf(`${apiUrl}/analisipagos/pdf/${curso}`, null, 'analisis_pagos_curso.pdf', accessToken, '')}>
                                            Reporte General Por Curso
                                        </button>
                                    )}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="accordionExample">
                        <button 
                            className="accordion-button" 
                            type="button" 
                            data-bs-toggle="collapse" 
                            data-bs-target="#collapseOne" 
                            aria-expanded="true" 
                            aria-controls="collapseOne"
                            style={{ 
                                padding: '0.6rem 0.5rem',  /* Relleno reducido */
                                height: 'auto',  /* Ajuste automático de la altura */
                                lineHeight: '1.2',  /* Altura de línea más pequeña para reducir la altura del botón */
                            }}
                        >
                            <i className="fas fa-filter"></i>
                            Filtros
                        </button>
                    </h2>

                    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <form onSubmit={handleSubmit}>
                                <div className="row g-3">
                                    <div className="col-6">
                                        <SelectInput
                                            id="periodo"
                                            name="periodo"
                                            value={periodo}
                                            onChange={(e) => handlePeriodoChange(e, setPeriodo)}
                                            placeholder="Periodo Lectivo"
                                            options={periodos.map(periodoActual => ({
                                                value: periodoActual.id,
                                                label: periodoActual.descripcion
                                            }))}
                                        />
                                    </div>
                                    {cursos && (
                                        <div className="col-6">
                                            <SelectInput
                                                id="curso"
                                                name="curso"
                                                value={curso}
                                                onChange={(e) => handleCursoChange(e, setCurso)}
                                                placeholder="Curso"
                                                options={cursos.map(cursoActual => ({
                                                    value: cursoActual.id,
                                                    label: cursoActual.nombre
                                                }))}
                                            />
                                        </div>
                                    )}
                                </div>
                                <button type="button" onClick={handleClearFilters} className="btn btn-danger text-white mt-2">
                                    <i className="fas fa-window-close"></i> Limpiar Filtros
                                </button>
                            </form>
                        </div>
                    </div>
                </div>

                {curso && (
                    <div className="grid-margin stretch-card mt-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="btn-group" role="group">
                                    <button type="button" className="btn btn-primary btn-sm dropdown-toggle" id="pdfDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="far fa-calendar-alt"></i> Reporte por pensiones
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="pdfDropdown">
                                        <li>
                                            {meses && meses.length > 0 && (
                                                meses.map((mes) => (
                                                    <button key={mes.id} className="dropdown-item" onClick={() => descargarPdf(`${apiUrl}/analisipagos/mensual/pdf/${curso}/${mes.id}`, null, 'analisis_pagos', accessToken, '' )}>
                                                        {`${mes.nombre} - ${mes.alias}`}
                                                    </button>
                                                ))
                                            )}
                                        </li>
                                    </ul>
                                </div>
                                <div className='table-responsive'>
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th>Estudiante</th>
                                                <th>Contacto</th>
                                                <th>Correo</th>
                                                <th>Representante</th>
                                                <th>Contacto</th>
                                                <th>Correo</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {estudiantes && estudiantes.length > 0 && (
                                                estudiantes.map((estudiante) => (
                                                    <tr key={estudiante.id}>
                                                        <td>{estudiante.estudiante.nombres}</td>
                                                        <td>{estudiante.estudiante.telefono}</td>
                                                        <td>{estudiante.estudiante.representante.correo}</td>
                                                        <td>{estudiante.estudiante.representante.nombres}</td>
                                                        <td>{estudiante.estudiante.representante.telefono}</td>
                                                        <td>{estudiante.estudiante.representante.correo}</td>
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row mt-2 pb-0 mb-0">
                                    <div className="col-sm-12 col-md-5">
                                        <div>
                                            Mostrando {pagination.from} a {pagination.to} de {pagination.total} resultados
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-7">
                                        <ul className="pagination">
                                            {pagination.links && pagination.links.length > 0 && (
                                                pagination.links.map((link, index) => (
                                                    <li key={index} className={`paginate_button page-item ${link.active ? 'active' : ''}`}>
                                                        <button
                                                            onClick={() => link.url && setCurrentPage(new URL(link.url).searchParams.get('page'))}
                                                            className="page-link"
                                                            disabled={!link.url}
                                                            dangerouslySetInnerHTML={{ __html: link.label }}
                                                        />
                                                    </li>
                                                ))
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Index;
