import React from 'react';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';

const DateInput = ({
  label,         // Etiqueta opcional
  name,          // Nombre del campo
  value,         // Valor actual
  onChange,      // Función para manejar el cambio
  placeholder,   // Texto del placeholder
  errors,        // Manejo de errores
  disabled = false, // Si el campo está deshabilitado
  divClass = true    // Clase de estilo opcional
}) => {
  const error = errors?.[name];  // Obtenemos el error si existe

  // Maneja el cambio de fecha
  const handleDateChange = (date) => {
    if (moment.isMoment(date)) {
      onChange({ target: { name, value: date.format('YYYY-MM-DD') } });
    }
  };

  return (
    <div className={divClass ? 'form-group' : ''}>
      {label && <label htmlFor={name}>{label}</label>}
      <Datetime
        id={name}
        name={name}
        value={value ? moment(value) : ''} // Solo muestra la fecha si existe
        onChange={handleDateChange}
        dateFormat="YYYY-MM-DD"  // El formato de fecha será 'YYYY-MM-DD'
        timeFormat={false}       // Deshabilitar la hora
        inputProps={{
          placeholder: placeholder || 'Selecciona una fecha',
          disabled: disabled,
          pattern: "\\d{4}-\\d{2}-\\d{2}",
          className: `form-control ${error ? 'is-invalid' : ''}`  // Aplicamos la clase is-invalid si hay un error
        }}
        closeOnSelect={true} // Cerrar el selector después de elegir la fecha
      />
      {error && <div className="text-danger">{error[0]}</div>}
    </div>
  );
};

export default DateInput;
