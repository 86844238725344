import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import { useApiContext  } from './Template';
import { useNavigate } from 'react-router-dom'
import $ from 'jquery';

const Sidebar = ({ sidebarOpen, closeSidebar }) => {
    const { apiUrl, accessToken, user, subdominio } = useApiContext();

    return (
        <nav className={`sidebar sidebar-offcanvas ${sidebarOpen ? 'active' : ''}`} id="sidebar" style={{ overflow: 'auto' }}>
            <ul className="nav">
                <li className="nav-item">
                    <Link to="/dashboard" className="nav-link" onClick={closeSidebar}>
                        <i className="fas fa-tachometer-alt menu-icon"></i>
                        <span className="menu-title">Inicio</span>
                    </Link>
                </li>
                <li className="nav-item nav-category">Modulos</li>
                
                {user ? (
                    <>
                        {/* Mostrar menú si user.docente es 1 */}
                        {user.docente === 1 && (
                            <>
                            <li className="nav-item">
                                <Link to="/MisCursos" className="nav-link" onClick={closeSidebar}>
                                    <i className="menu-icon fas fa-chalkboard"></i>
                                    <span className="menu-title">Mis Cursos</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/Tutorias" className="nav-link" onClick={closeSidebar}>
                                <i className="menu-icon fas fa-chalkboard-teacher"></i>
                                    <span className="menu-title">Tutorias</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/MisMaterias" className="nav-link" onClick={closeSidebar}>
                                    <i className="menu-icon fas fa-book-reader"></i>
                                    <span className="menu-title">Mis Materias</span>
                                </Link>
                            </li>
                            </>
                        )}
                    </>
                ) : (
                    <p>Cargando..</p>
                )}

                
                {user ? (
                    <>
                        {/* Mostrar menú si user.estudiante es 1 */}
                        {user.estudiante === 1 && (
                            <>
                            <li className="nav-item">
                                <Link to="/MisCursos" className="nav-link" onClick={closeSidebar}>
                                <i className="menu-icon fas fa-chalkboard-teacher"></i>
                                    <span className="menu-title">Mis Cursos</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/MisMaterias" className="nav-link" onClick={closeSidebar}>
                                    <i className="menu-icon fas fa-book-reader"></i>
                                    <span className="menu-title">Mis Materias</span>
                                </Link>
                            </li>
                            </>
                        )}
                    </>
                ) : (
                    <p>Cargando..</p>
                )}
                
                {user ? (
                    <>
                        {/* Mostrar menú si id_rol no es nulo */}
                        {user.id_rol && (
                            <>
                            <li className="nav-item">
                                <Link to="/periodos" className="nav-link" onClick={closeSidebar}>
                                    <i className="fas fa-calendar-alt menu-icon"></i>
                                    <span className="menu-title">Periodos</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="collapse" href="#side-menu1" aria-expanded="false" aria-controls="side-menu1">
                                    <i className="menu-icon fas fa-users"></i>
                                    <span className="menu-title"> Personas </span>
                                </a>
                                <div className="collapse" id="side-menu1">
                                    <ul className="nav flex-column sub-menu">
                                        <li className="nav-item">
                                            <Link to="/personas" className="nav-link">
                                                <span className="menu-title">Personas</span>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/estudiantes" className="nav-link">
                                                <span className="menu-title">Estudiantes</span>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/docentes" className="nav-link">
                                                <span className="menu-title">Docentes</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="collapse" href="#side-menu2" aria-expanded="false" aria-controls="side-menu2">
                                    <i className="menu-icon fas fa-chalkboard-teacher"></i>
                                    <span className="menu-title"> Cursos </span>
                                </a>
                                <div className="collapse" id="side-menu2">
                                    <ul className="nav flex-column sub-menu">
                                        <li className="nav-item">
                                            <Link to="/Cursos" className="nav-link">
                                                <span className="menu-title">Cursos</span>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/niveles" className="nav-link">
                                                <span className="menu-title">Niveles</span>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/modalidades" className="nav-link">
                                                <span className="menu-title">Modalidades</span>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/especialidades" className="nav-link">
                                                <span className="menu-title">Especialidades</span>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/jornadas" className="nav-link">
                                                <span className="menu-title">Jornadas</span>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/secciones" className="nav-link">
                                                <span className="menu-title">Secciones</span>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/tipocurso" className="nav-link">
                                                <span className="menu-title">Tipos</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item">
                                <Link to="/Materias" className="nav-link" onClick={closeSidebar}>
                                    <i className="menu-icon fas fa-book-reader"></i>
                                    <span className="menu-title">Materias</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/comunicados" className="nav-link" onClick={closeSidebar}>
                                    <i className="menu-icon fas fa-bullhorn"></i>
                                    <span className="menu-title">Comunicados</span>
                                </Link>
                            </li>
                            {user.superuser === 1 &&(
                            <>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="collapse" href="#side-menu3" aria-expanded="false" aria-controls="side-menu3">
                                    <i className="menu-icon fas fa-exchange-alt"></i>
                                    <span className="menu-title"> Transacciones</span>
                                </a>
                                <div className="collapse" id="side-menu3">
                                    <ul className="nav flex-column sub-menu">
                                        <li className="nav-item">
                                            <Link to="/facturacionv1" className="nav-link">
                                                <span className="menu-title">Facturación</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="collapse" href="#side-menu4" aria-expanded="false" aria-controls="side-menu4">
                                    <i className="menu-icon fas fa-wallet"></i>
                                    <span className="menu-title"> Cobros/Pagos</span>
                                </a>
                                <div className="collapse" id="side-menu4">
                                    <ul className="nav flex-column sub-menu">
                                        <li className="nav-item">
                                            <Link to="/movimientos" className="nav-link">
                                                <span className="menu-title">Movimientos</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            </>
                            )}
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="collapse" href="#side-menu5" aria-expanded="false" aria-controls="side-menu5">
                                    <i className="menu-icon fas fa-chart-bar"></i>
                                    <span className="menu-title"> Reportes</span>
                                </a>
                                <div className="collapse" id="side-menu5">
                                    <ul className="nav flex-column sub-menu">
                                        <li className="nav-item">
                                            <Link to="/analisispensiones" className="nav-link">
                                                <span className="menu-title">Analisis Pensiones</span>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/test" className="nav-link">
                                                <span className="menu-title">Por Curso</span>
                                            </Link>
                                            <div class="collapse2" id="side-menu_1">
                                                <ul class="nav flex-column sub-menu" style={{ padding: '0.25rem 0 0 1.07rem !important' }}>
                                                    <li class="nav-item">
                                                        <Link class="nav-link" to="/flujocaja" >Caja</Link>
                                                    </li>
                                                    <li class="nav-item">
                                                        <Link class="nav-link" to="/repmovimientos" >Movimientos</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="collapse" href="#side-menu6" aria-expanded="false" aria-controls="side-menu6">
                                    <i className="menu-icon fas fa-cogs"></i>
                                    <span className="menu-title"> Configuraciones</span>
                                </a>
                                <div className="collapse" id="side-menu6">
                                    <ul className="nav flex-column sub-menu">
                                        <li className="nav-item">
                                            <Link to="/academico" className="nav-link">
                                                <span className="menu-title">Academico</span>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/cajas" className="nav-link">
                                                <span className="menu-title">Cajas</span>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/ctaBancaria" className="nav-link">
                                                <span className="menu-title">Cuentas Bancarias</span>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/confFact" className="nav-link">
                                                <span className="menu-title">Facturación</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            {/* 
                            <li className="nav-item active">
                                <a className="nav-link" data-bs-toggle="collapse" href="#side-menu3" aria-expanded="false" aria-controls="side-menu3">
                                    <i className="menu-icon fas fa-users"></i>
                                    <span className="menu-title"> Roles </span>
                                    <i className="menu-arrow"></i>*
                                </a>
                                <div className="collapse" id="side-menu3">
                                    <ul className="nav flex-column sub-menu">
                                        <li className="nav-item">
                                            <Link className="nav-link active" to="#"> Cursos</Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            */}
                            </>
                        )}
                    </>
                ) : (
                    <p>Cargando..</p>
                )}
            </ul>
        </nav>
    );
};

export default Sidebar;
