import React from 'react';
import TextInput from '../../form/InputText';

const FormContent = ({ formData, setFormData, errors, handleInputChange }) => {
    return (
        <div className="">
            {/* Checkboxes */}
            <div className="form-group">
                <div className="form-check form-check-flat form-check-primary">
                    <label htmlFor="upd_asistencias" className="form-check-label">
                        <input 
                            type="checkbox" 
                            className="form-check-input" 
                            name="upd_asistencias" 
                            id="upd_asistencias" 
                            checked={formData.upd_asistencias || false} 
                            onChange={handleInputChange} 
                        />
                        Actualizar Asistencias (Docentes)
                        <i className="input-helper"></i>
                    </label>
                </div>
            </div>

            <div className="form-group">
                <div className="form-check form-check-flat form-check-primary">
                    <label htmlFor="upd_notas" className="form-check-label">
                        <input 
                            type="checkbox" 
                            className="form-check-input" 
                            name="upd_notas" 
                            id="upd_notas" 
                            checked={formData.upd_notas || false} 
                            onChange={handleInputChange} 
                        />
                        Actualizar Calificaciones (Docentes)
                        <i className="input-helper"></i>
                    </label>
                </div>
            </div>

            <div className="row">
                <div className="form-group col-4">
                    <div className="form-check form-check-flat form-check-primary">
                        <label htmlFor="intento_tarea" className="form-check-label">
                            <input 
                                type="checkbox" 
                                className="form-check-input" 
                                name="intento_tarea" 
                                id="intento_tarea" 
                                checked={formData.intento_tarea || false} 
                                onChange={handleInputChange} 
                            />
                            Limitar Intentos Actualización De Tareas (Estudiantes)
                            <i className="input-helper"></i>
                        </label>
                    </div>
                </div>

                <div className="form-group col-4">
                    <label htmlFor="num_intento">Intentos</label>
                    <TextInput
                        id="num_intento"
                        name="num_intento"
                        value={formData.num_intento || ''}
                        onChange={handleInputChange}
                        placeholder="Limite"
                        errors={errors}
                        className={`form-control ${errors.num_intento ? 'is-invalid' : ''}`}
                    />
                    {errors.num_intento && (
                        <div className="invalid-feedback" style={{ display: 'inline !important' }}>
                            {errors.num_intento.map((error, index) => (
                                <div key={index}>{error}</div>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            <div className="form-group">
                <div className="form-check form-check-flat form-check-primary">
                    <label htmlFor="ocultar_boletin" className="form-check-label">
                        <input 
                            type="checkbox" 
                            className="form-check-input" 
                            name="ocultar_boletin" 
                            id="ocultar_boletin" 
                            checked={formData.ocultar_boletin || false} 
                            onChange={handleInputChange} 
                        />
                        Ocultar Boletin (Estudiantes)
                        <i className="input-helper"></i>
                    </label>
                </div>
            </div>

            <div className="form-group">
                <div className="form-check form-check-flat form-check-primary">
                    <label htmlFor="ocultar_boletin_docente" className="form-check-label">
                        <input 
                            type="checkbox" 
                            className="form-check-input" 
                            name="ocultar_boletin_docente" 
                            id="ocultar_boletin_docente" 
                            checked={formData.ocultar_boletin_docente || false} 
                            onChange={handleInputChange} 
                        />
                        Ocultar Boletin (Docentes)
                        <i className="input-helper"></i>
                    </label>
                </div>
            </div>
        </div>
    );
};

export default FormContent;
