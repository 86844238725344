import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

const ToggleStatusButton = ({ id, endpoint, currentStatus, accessToken, onStatusChange }) => {
    const handleStatusToggle = async () => {
        try {
            const newStatus = !currentStatus; // Cambia entre true y false

            await axios.patch(`${endpoint}`, { status: newStatus }, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                },
            });

            Swal.fire({
                icon: 'success',
                title: 'Estado actualizado',
                text: 'El estado ha sido cambiado correctamente.',
            });

            // Llama la función de actualización del estado en el componente padre
            onStatusChange(newStatus);
        } catch (error) {
            console.error('Error al cambiar el estado', error.response ? error.response.data : error.message);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Hubo un problema al cambiar el estado. Por favor, intenta nuevamente.',
            });
        }
    };

    return (
        <button
            onClick={handleStatusToggle}
            className={`btn btn-sm ${currentStatus ? 'btn-success' : 'btn-danger'}`}
        >
            <i className={`fas fa-${currentStatus ? 'check' : 'times'}`}></i>
        </button>
    );
};

export default ToggleStatusButton;