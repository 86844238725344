import axios from 'axios'
import React, {useState, useEffect} from 'react'
import { useNavigate, useParams , Link } from 'react-router-dom'
import { useApiContext  } from '../layout/Template';
import Spinner from '../widget/Spinner';
import FormContent from './FormContent';

const Create = () => {
    const [loading, setLoading] = useState(true);
    const { apiUrl, accessToken } = useApiContext();
    const [formData, setFormData] = useState({
        cajero: '',
        punto_emision: '',
        nombre: '',
        num: ''
    });
    const [errors, setErrors] = useState({}); // Estado para errores de validación
    const navigate = useNavigate();
    const {cajaId} = useParams();
    useEffect(() => {
        const getCaja = async () => {
            try {
                const response = await axios.get(`${apiUrl}/cajas/${cajaId}`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Accept': 'application/json',
                    }
                });
                // Convertir los datos del backend al formato esperado
                const { caja, cajero, num, punto_emision, nombre } = response.data;
                setFormData({
                    cajero: cajero,
                    num: num,
                    punto_emision: punto_emision,
                    nombre: nombre
                });
                setLoading(false);
            } catch (error) {
                console.error('Error al obtener la caja:', error.response ? error.response.data : error.message);
                setLoading(false); // Desactivar el cargador en caso de error
            }
        };
        getCaja();
    }, [apiUrl, accessToken, cajaId]);
    
    const update = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.put(`${apiUrl}/cajas/${cajaId}`, formData, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            });
            navigate('/cajas');  // Redirigir a la lista de cajas
            window.showSuccessPopup(response.data.message);
        } catch (error) {
            if (error.response) {
                // Manejar errores de validación
                setErrors(error.response.data.errors);
            } else {
                console.error('Error al actualizar:', error.response ? error.response.data : error.message);
            }
        }
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className='py-3 px-3 content-wrapper'>
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2 className="">Actualizar Caja</h2>
                </div>
            </div>
            <div className="col-md-6 offset-md-3 grid-margin stretch-card mt-3">
                <div className="card">
                    <div className="card-body">
                        <form className="forms-sample" onSubmit={update}>
                            <FormContent
                                formData={formData}
                                setFormData={setFormData}
                                errors={errors}
                            />
                            <button type="submit" className="btn btn-primary me-2"><i className="fas fa-save"></i> Actualizar Caja</button>
                            <Link className="btn btn-warning" to='/cajas'>Volver</Link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Create;
