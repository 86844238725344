import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import { useApiContext  } from '../layout/Template';
import Spinner from '../widget/Spinner';
import Swal from 'sweetalert2';
import TextInput from '../../form/InputText';
import SelectInput from '../../form/SelectInput';
import SearchSelect from '../../form//SearchSelect';
import QuillEditor from '../../form/QuillEditor';
import FileInput from '../../form/FileInput';
import DateInput from '../../form/DateInput';
import DateTimeInput from '../../form/DateTimeInput';
import moment from 'moment';

const Index = () => {
    const [loading, setLoading] = useState(true);
    const { apiUrl, accessToken } = useApiContext();
    const [movimientos, setMovimientos] = useState([]);
    const [cuentas, setCuentas] = useState([]);
    const [cajas, setCajas] = useState([]);
    const [usuarios, setUsuarios] = useState([]);

    const [pagination, setPagination] = useState({
        current_page: 1,
        from: 1,
        to: 0,
        total: 0,
        links: [],
    });
    
    const [currentPage, setCurrentPage] = useState(1);
    const [formaPago, setFormaPago] = useState(''); // Filtro para forma de pago
    const [caja, setCaja] = useState('');
    const [usuario, setUsuario] = useState('');
    const [referenciaPago, setReferenciaPago] = useState('');
    const [movimiento, setMovimiento] = useState('');
    const [persona, setPersona] = useState('');
    const [fechainicio, setFechainicio] = useState('');
    const [fechafin, setFechafin] = useState('');
    const [debouncedPersona, setDebouncedPersona] = useState('');

    const handleClearFilters = () => {
        // Resetear todos los campos del formulario y los parámetros PDF
        setCaja('');
        setUsuario('');
        setReferenciaPago('');
        setMovimiento('');
        setPersona('');
        setFechainicio('');
        setFechafin('');
        setDebouncedPersona('');

        //setPdfParams({
        //    id_caja: '',
        //    usuario_crea: '',
        //    referencia_pago: '',
        //    id: '',
        //    persona: '',
        //    fechaInicio: '',
        //    fechaFinal: '',
        //});
    };

    useEffect(() => {
        // Debounce para el campo 'persona'
        const timer = setTimeout(() => {
            if (persona.length >= 3) {
                setDebouncedPersona(persona);
            } else {
                setDebouncedPersona(''); // Si no cumple con la longitud, se borra el filtro
            }
        }, 1500); // Tiempo de espera de 1.5 segundos

        // Limpiar el temporizador si el valor de 'persona' cambia antes de que el tiempo expire
        return () => clearTimeout(timer);
    }, [persona]);

    useEffect(() => {
        getData(currentPage);
    }, [currentPage, formaPago, caja, usuario, referenciaPago, movimiento, debouncedPersona, fechainicio, fechafin]);

    const getData = async (page) => {
        try {
            const response = await axios.get(`${apiUrl}/movimientos`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                },
                params: {
                    page: page,
                    forma_pago: formaPago,          // Agregar parámetro de filtro
                    id_caja: caja, 
                    usuario_crea: usuario, 
                    referencia_pago: referenciaPago, // Agregar parámetro de filtro
                    id: movimiento, // Agregar parámetro de filtro
                    persona: persona,
                    fechaInicio: fechainicio,
                    fechaFinal: fechafin,
                }
            });
            const pageData = {
                data: response.data.data,
                pagination: {
                    current_page: response.data.current_page,
                    from: response.data.from,
                    to: response.data.to,
                    total: response.data.total,
                    links: response.data.links,
                },
                timestamp: Date.now(),
            };
            setMovimientos(response.data.data);
            setPagination(pageData.pagination);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching dashboard data', error.response ? error.response.data : error.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(`${apiUrl}/movimientos/create`, { headers: { Authorization: `Bearer ${accessToken}` } });
            setUsuarios(response.data.usuarios);
            setCajas(response.data.cajas);
        };
        fetchData();
    }, [apiUrl, accessToken]);
    
    const handleDelete = async (id) => {
        // Mostrar el cuadro de confirmación
        const result = await Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás recuperar este registro después de eliminarlo!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
        });
        // Si el usuario confirma la eliminación
        if (result.isConfirmed) {
            try {
                await axios.delete(`${apiUrl}/movimientos/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Accept': 'application/json',
                    },
                });
                getData(currentPage);
                Swal.fire({
                    icon: 'success',
                    title: 'Eliminado',
                    text: 'El registro ha sido eliminado correctamente.',
                });
            } catch (error) {
                console.error('Error deleting registro:', error.response ? error.response.data : error.message);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'No se pudo eliminar el registro. Por favor, inténtelo de nuevo.',
                });
            }
        }
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className='py-3 px-3 content-wrapper'>

            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2 className="">Consultar Movimientos</h2>
                </div>
                <div>
                    <div className="btn-group" role="group" aria-label="Basic outlined example">
                        <Link to='create' className="btn btn-outline-primary"><i className="fas fa-plus-circle"></i></Link>
                    </div>
                </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="accordionExample">
                        <button 
                            className="accordion-button" 
                            type="button" 
                            data-bs-toggle="collapse" 
                            data-bs-target="#collapseOne" 
                            aria-expanded="true" 
                            aria-controls="collapseOne"
                            style={{ 
                                padding: '0.6rem 0.5rem',  /* Relleno reducido */
                                height: 'auto',  /* Ajuste automático de la altura */
                                lineHeight: '1.2',  /* Altura de línea más pequeña para reducir la altura del botón */
                            }}
                        >
                            <i className="fas fa-filter"></i>
                            Filtros
                        </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <form>
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-12">
                                        <TextInput
                                            name="persona"
                                            value={persona}
                                            onChange={(e) => setPersona(e.target.value)}
                                            placeholder="Persona"
                                        />
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-12">
                                        <TextInput
                                            name="referencia_pago"
                                            value={referenciaPago}
                                            onChange={(e) => setReferenciaPago(e.target.value)}
                                            placeholder="Referencia de Pago:"
                                            validacion={'alfanumerico'}
                                        />
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-12">
                                        <SelectInput
                                            id="id_caja"
                                            name="id_caja"
                                            value={caja}
                                            onChange={(e) => setCaja(e.target.value)}
                                            placeholder="Caja"
                                            options={[
                                                { value: '', label: 'Todos' },
                                                ...cajas.map(caja => ({
                                                    value: caja.id,
                                                    label: caja.num + ' - ' + caja.nombre
                                                }))
                                            ]}
                                        />
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-12">
                                        <SelectInput
                                            id="forma_pago"
                                            name="forma_pago"
                                            value={formaPago}
                                            onChange={(e) => setFormaPago(e.target.value)}
                                            placeholder="Forma de pago"
                                            options={[
                                                { value: '', label: 'Todos' },
                                                { value: 1, label: 'Efectivo' },
                                                { value: 2, label: 'Transferencia' },
                                                { value: 3, label: 'Cheque' },
                                            ]}
                                        />
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-12">
                                        <TextInput
                                            name="movimiento"
                                            value={movimiento}
                                            onChange={(e) => setMovimiento(e.target.value)}
                                            placeholder="# Mov:"
                                            validacion={'entero'}
                                        />
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-12">
                                        <SelectInput
                                            value={usuario}
                                            onChange={(e) => setUsuario(e.target.value)}
                                            placeholder="Cajero"
                                            options={[
                                                { value: '', label: 'Todos' },
                                                ...usuarios.map(usuario => ({
                                                    value: usuario.id,
                                                    label: usuario.name
                                                }))
                                            ]}
                                        />
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-12">
                                        <DateInput
                                            label="Fecha desde"
                                            name="fecha"
                                            value={fechainicio}
                                            onChange={(e) => setFechainicio(e.target.value)}
                                            placeholder="Fecha"
                                        />
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-12">
                                        <DateInput
                                            label="Fecha hasta"
                                            name="fecha"
                                            value={fechafin}
                                            onChange={(e) => setFechafin(e.target.value)}
                                            placeholder="Fecha"
                                        />
                                    </div>
                                </div>
                                <button type="button" onClick={handleClearFilters} className="btn btn-danger text-white mt-2">
                                    <i className="fas fa-window-close"></i> Limpiar Filtros
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card card-rounded">
                <div className="card-body">
                    <div className="table-responsive  mt-1">
                        <table className="table select-table">
                        <thead>
                                <tr>
                                    <th>Opc</th>
                                    <th width={"4%"}># Mov</th>
                                    <th>Persona</th>
                                    <th width={"8%"}>F. Mov</th>
                                    <th>Comprobante</th>
                                    <th>Monto</th>
                                    <th>Caja</th>
                                    <th>Cajero</th>
                                </tr>
                            </thead>
                            <tbody>
                                {movimientos.map(movimiento => (
                                    <tr style={{ fontSize: '20px' }} key={movimiento.id}>
                                        <td>
                                            <div className="dropdown">
                                                <button 
                                                    className="btn btn-sm btn-secondary" 
                                                    type="button" 
                                                    id={`dropdownMenuButton-${movimiento.id}`} 
                                                    data-bs-toggle="dropdown" 
                                                    aria-expanded="false"
                                                >
                                                    <i className="fas fa-cogs"></i>
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby={`dropdownMenuButton-${movimiento.id}`}>
                                                    <li>
                                                        <Link 
                                                            className="dropdown-item" 
                                                            to={`/movimientos/${movimiento.id}`} 
                                                        >
                                                            <i className="fas fa-edit"></i> Editar
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link 
                                                            className="dropdown-item"
                                                            to="#"
                                                            onClick={()=>handleDelete(movimiento.id)}
                                                        >
                                                            <i className="fas fa-trash-alt"></i> Eliminar
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                        <td>
                                            <Link to={`/movimientos/${movimiento.id}`} className="text-decoration-none">
                                                MOV #{movimiento.id}
                                            </Link>
                                        </td>
                                        <td>
                                            <Link to={`/movimientos/${movimiento.id}`} className="text-decoration-none">
                                                {movimiento.representante.nombres} - {movimiento.representante.identificacion}
                                            </Link>
                                        </td>
                                        <td>
                                            <Link to={`/movimientos/${movimiento.id}`} className="text-decoration-none">
                                                {moment(movimiento.created_at).format('DD-MM-YYYY')}
                                            </Link>
                                        </td>
                                        <td>
                                            <Link to={`/movimientos/${movimiento.id}`} className="text-decoration-none">
                                                {movimiento.forma_pago === 2 ? 'T - ' : movimiento.forma_pago === 3 ? 'C - ' : ''} {movimiento.referencia_pago}
                                            </Link>
                                        </td>
                                        <td>
                                            <Link to={`/movimientos/${movimiento.id}`} className="text-decoration-none">
                                                ${movimiento.monto}
                                            </Link>
                                        </td>
                                        <td>
                                            <Link to={`/movimientos/${movimiento.id}`} className="text-decoration-none">
                                                {movimiento.id_caja ? 'Caja #' + movimiento.caja.num : 'N/A' }
                                            </Link>
                                        </td>
                                        <td>
                                            <Link to={`/movimientos/${movimiento.id}`} className="text-decoration-none">
                                                {movimiento.usuario_crea ? movimiento.usuario.name : 'N/A' }
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* Paginación */}
                    <div>
                        <div className="row mt-2 pb-0 mb-0">
                            <div className="text-center">
                                <div>
                                    Mostrando {pagination.from} a {pagination.to} de {pagination.total} resultados
                                </div>
                            </div>
                        </div>
                        <div className="text-center">
                            <ul className="pagination">
                                {pagination.links.map((link, index) => (
                                    <li key={index} className={`paginate_button page-item ${link.active ? 'active' : ''}`}>
                                        <button
                                            onClick={() => link.url && setCurrentPage(new URL(link.url).searchParams.get('page'))}
                                            className="page-link"
                                            disabled={!link.url}
                                            dangerouslySetInnerHTML={{ __html: link.label }}
                                        />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;