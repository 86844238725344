import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useApiContext } from '../layout/Template';
import { useNavigate } from 'react-router-dom';
import { useParams, Link } from 'react-router-dom';
import Spinner from '../widget/Spinner';
import Parciales from './Parciales';
import MyBarChart from '../charts/MyBarChart';
import MyPieChart from '../charts/MyPieChart';

const Ciclo = () => {
    const { materiaId, cicloId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const { apiUrl, accessToken, user, subdominio } = useApiContext();
    const [isLoading, setIsLoading] = useState(false);
    
    const [estudiantes, setEstudiantes] = useState([]);
    const [titulo, setTitulo] = useState([]);
    const [inicial, setInicial] = useState(null);
    const [examen, setExamen] = useState(null);
    const [proyecto, setProyecto] = useState(null);
    const [promedioCursoEstudi, setPromedio] = useState(null);
    const [cualitativa, setCualitativa] = useState(null);
    const [ciclos, setCiclos] = useState([]);
    const [subciclos, setSubciclos] = useState([]);
    const [subciclosMat, setSubciclosMat] = useState([]);
    const [distribucionEscalas, setDistribucionEscalas] = useState([]);
    const [aprovechamientos, setAprovechamientos] = useState([]);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${apiUrl}/sabana/materia/ciclo/${materiaId}/${cicloId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json'
                }
            });
            const data = response.data;
            setEstudiantes(data.estudiantes);
            setTitulo(data.titulo);
            setInicial(data.inicial);
            setExamen(data.examen);
            setProyecto(data.proyecto);
            setPromedio(data.promedioCursoEstudi);
            setCualitativa(data.cualitativa);
            setCiclos(data.ciclos);
            setSubciclos(data.subciclos);
            setSubciclosMat(data.subciclosMat);
            setDistribucionEscalas(data.distribucionEscalas);
            setAprovechamientos(data.aprovechamientos);

            console.log(data)
        } catch (err) {
            if (err.response) {
                const errorMessage = err.response.data.error;
                const errorCode = err.response.data.code;
                if (errorCode === 403) {
                    // Mostrar el popup de error y redirigir al Dashboard
                    //navigate('/dashboard'); // Cambia '/dashboard' por la ruta real de tu componente Dashboard
                    window.showErrorPopup(errorMessage);
                    return;
                }
                window.showErrorPopup(errorMessage);
            } else if (err.request) {
                window.showErrorPopup('No se recibió respuesta del servidor.');
            } else {
                window.showErrorPopup('Error al configurar la solicitud: ' + err.message);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [materiaId, cicloId]); 

    const pdf = async () => {
        if (isLoading) return; // Evitar ejecutar si ya está cargando

        setIsLoading(true);
        console.log("procesando");
        try {
            const response = await axios.get(`${apiUrl}/sabana/materia/ciclo/pdf/${materiaId}/${cicloId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/pdf', // Asegúrate de que la respuesta sea un PDF
                },
                responseType: 'blob' // Importante para manejar la respuesta como archivo binario
            });
    
            // Crear un enlace de descarga
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
    
            // Forma el nombre del archivo con un formato seguro para nombres de archivo
            const nombreArchivo = `${titulo.descripcion.replace(/[\/\\?%*:|"<>]/g, '-')}.pdf`;
    
            link.setAttribute('download', nombreArchivo);
            document.body.appendChild(link);
            link.click();
    
            // Liberar el objeto URL y eliminar el enlace del DOM
            window.URL.revokeObjectURL(url);
            link.remove();
        } catch (err) {
            console.error('Error al descargar el PDF:', err);
            window.showErrorPopup('Error al descargar el archivo PDF.');
        } finally {
            setIsLoading(false);
        }
        console.log("fin");
    };

    /*
    if (loading) {
        return <Spinner />;
    }
    */

    return (
        <div className='py-3 px-3 content-wrapper'>
            <style>
                {`
                    div.vertical{
                    width: 45px;
                    transform: rotate(-90deg);
                    -webkit-transform: rotate(-90deg); /* Safari/Chrome */
                    -moz-transform: rotate(-90deg); /* Firefox */
                    -o-transform: rotate(-90deg); /* Opera */
                    -ms-transform: rotate(-90deg); /* IE 9 */
                    }

                    th.vertical{
                    height: 115px;
                    padding-bottom: 0;
                    margin-bottom: 0;
                    }

                    .tarea {
                        border-bottom: 10px solid #1F3BB3 !important;
                    }

                    .leccion {
                        border-bottom: 10px solid rgb(224, 129, 66) !important;
                    }

                    .individual {
                        border-bottom: 10px solid rgb(29, 209, 241) !important;
                    }

                    .grupal {
                        border-bottom: 10px solid rgb(138, 140, 255) !important;
                    }

                    .exposicion {
                        border-bottom: 10px solid rgb(57, 206, 149) !important;
                    }

                    .formativo {
                        border-bottom: 10px solid #0d6efd !important;
                    }

                    .proyecto {
                        border-bottom: 10px solid yellow !important;
                    }

                    .promedio {
                        border-bottom: 10px solid #198754 !important;
                    }
                `}
            </style>
            <div className="d-sm-flex justify-content-between align-items-start mb-2">
                <div>
                    <h2><strong>{ titulo.descripcion }</strong></h2>
                </div>
                <div>
                    <div class="btn-group mb-2" role="group" aria-label="Basic outlined example">
                        <Link to={`/Materias/Contenido/${materiaId}/${cicloId}`} class="btn btn-warning btn-sm"><i class="fas fa-chevron-circle-left"></i> <span className="d-none d-lg-inline"> Volver</span></Link>
                    </div>
                </div>
            </div>
            <div className='row'>
                <h2>Promedio: {promedioCursoEstudi} - Cualitativa Prom: {cualitativa}</h2>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                    <MyBarChart data={distribucionEscalas}/>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                    <MyBarChart data={aprovechamientos}/>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                    <MyPieChart data={aprovechamientos}/>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="d-sm-flex justify-content-between align-items-start mb-2">
                        <div>
                            <h2><strong>{ titulo.subtitulo }</strong></h2>
                            <Parciales ciclos={ciclos} subciclos={subciclos} materiaId={materiaId} />
                        </div>
                        <div>
                            <button class="btn btn-primary" onClick={pdf} disabled={isLoading}><i class="fas fa-file-pdf"></i> <span className="d-none d-lg-inline"> Generar PDF</span></button>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className='table table-bordered'>
                            <thead>
                                <tr>
                                    <th>
                                        Estudiantes
                                    </th>
                                    {subciclosMat.map((subciclo, index) => {
                                        // Determina la clase basada en id_tipoelemento

                                        return (
                                            <th
                                                key={index}
                                                style={{ width: "45px" }}
                                                className='formativo'
                                            >
                                                <div className="vertical">
                                                    <a href="#" className="text-decoration-none">
                                                        {subciclo.descripcion}
                                                    </a>
                                                </div>
                                            </th>
                                        );
                                    })}
                                    {examen && (
                                        <>
                                        <th style={{ width: "45px" }} className="vertical formativo"><div className="vertical">Formativos</div></th>
                                        <th style={{ width: "45px" }} className="vertical formativo"><div className="vertical">70%</div></th>
                                        <th style={{ width: "45px" }} className="vertical proyecto"><div className="vertical">Examen</div></th>
                                            {proyecto && (
                                                <>
                                                    <th style={{ width: "45px" }} className="vertical proyecto"><div className="vertical">{subdominio === 'hacialacumbre' ? 'Aporte': 'Proyecto'}Proyecto</div></th>
                                                </>
                                            )}
                                            <th style={{ width: "45px" }} className="vertical proyecto"><div className="vertical">30%</div></th>
                                        </>
                                    )}
                                    <th style={{ width: "45px" }} className="vertical promedio"><div className="vertical">Promedio</div></th>
                                    <th style={{ width: "45px" }} className="vertical promedio"><div className="vertical">Cualitativa</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                
                            {estudiantes.map((estudiante, estIndex) => {
                                return(
                                    <tr key={estIndex}>
                                        <td>{estudiante.nombres}</td>
                                        {subciclosMat.map((subciclo, subIndex) => {
                                            const promedioParcial = estudiante.promedios_por_parcial[subciclo.id];
                                            return (
                                                <td 
                                                    key={subIndex}
                                                    style={{ width: "45px" }}
                                                    className={parseFloat(promedioParcial) < 7 ? 'text-danger' : ''}
                                                >
                                                    {promedioParcial ? promedioParcial : 'N/A'}
                                                </td>
                                            );
                                        })}
                                        {examen && (
                                            <>
                                                <td 
                                                    style={{ width: "45px" }}
                                                    className={parseFloat(10) < 7 ? 'text-danger' : ''}
                                                >
                                                    {estudiante.notaFormativa}
                                                </td>
                                                <td 
                                                    style={{ width: "45px" }}
                                                    className={parseFloat(estudiante.porcFormativo) < 7 ? 'text-danger' : ''}
                                                >
                                                    {estudiante.porcFormativo}
                                                </td>
                                                <td 
                                                    style={{ width: "45px" }}
                                                    className={parseFloat(estudiante.examen) < 7 ? 'text-danger' : ''}
                                                >
                                                    {estudiante.examen}
                                                </td>
                                                {proyecto && (
                                                    <>
                                                        <td 
                                                            style={{ width: "45px" }}
                                                            className={parseFloat(estudiante.proyecto) < 7 ? 'text-danger' : ''}
                                                        >
                                                            {estudiante.proyecto}
                                                        </td>
                                                    </>
                                                )}
                                                <td 
                                                    style={{ width: "45px" }}
                                                    className={parseFloat(estudiante.porcSumativo) < 7 ? 'text-danger' : ''}
                                                >
                                                    {estudiante.porcSumativo}
                                                </td>
                                            </>
                                        )}
                                        <td 
                                            style={{ width: "45px" }}
                                            className={parseFloat(estudiante.promedio) < 7 ? 'text-danger' : ''}
                                        >
                                            {estudiante.promedio}
                                        </td>
                                        <td 
                                            style={{ width: "45px" }}
                                            className={parseFloat(estudiante.promedio) < 7 ? 'text-danger' : ''}
                                        >
                                            {estudiante.cualitativa}
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Ciclo;
