import React, { useEffect } from 'react';

const ClearCacheButton = () => {
  const handleClearCache = async () => {
    // Limpiar la caché del navegador
    if ('caches' in window) {
      const cacheNames = await caches.keys();
      await Promise.all(cacheNames.map(cacheName => caches.delete(cacheName)));
      console.log('Caché borrada');
    }

    // Limpiar localStorage
    localStorage.clear();
    console.log('localStorage borrado');

    // Limpiar sessionStorage
    sessionStorage.clear();
    console.log('sessionStorage borrado');

    // Desregistrar service workers
    if ('serviceWorker' in navigator) {
      const registrations = await navigator.serviceWorker.getRegistrations();
      for (let registration of registrations) {
        await registration.unregister();
        console.log('Service worker desregistrado');
      }
    }

    // Crear una cookie que indique cuándo se borraron los datos
    const date = new Date();
    const expirationDays = 7; // Expiración en 7 días
    date.setTime(date.getTime() + (expirationDays * 24 * 60 * 60 * 1000)); // Establecer fecha de expiración
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `datosBorrados=true; ${expires}; path=/`; // Crea la cookie
    console.log('Cookie creada: datosBorrados');

    // Recargar la página y no usar la caché
    window.location.reload(true);
  };

  const checkCookieAndClearCache = () => {
    // Verifica si la cookie "datosBorrados" existe
    const cookieExists = document.cookie.split(';').some(cookie => cookie.trim().startsWith('datosBorrados='));
    
    // Si no existe, borra la caché, los datos, y los service workers
    if (!cookieExists) {
      handleClearCache();
    } else {
      console.log('La cookie "datosBorrados" ya existe. No se hace nada.');
    }
  };

  // Ejecuta la verificación y borrado cuando el componente se monta
  useEffect(() => {
    checkCookieAndClearCache();
  }, []);

  // No retorna nada visible
  return null;
};

export default ClearCacheButton;
