// src/routes/MatriculaRoutes.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from '../componentes/auth/ProtectedRoute';
import Template from '../componentes/client/layout/Template';

// matricula
import Create from '../componentes/client/matricula/Create';
import Edit from '../componentes/client/matricula/Edit';
import Show from '../componentes/client/matricula/Show';

const MatriculaRoutes = () => {
    return (
        <Routes>
            <Route
                path="create/:cursoId"
                element={
                    <ProtectedRoute>
                        <Template>
                            <Create />
                        </Template>
                    </ProtectedRoute>
                }
            />
            <Route
                path="edit/:id"
                element={
                    <ProtectedRoute>
                        <Template>
                            <Edit />
                        </Template>
                    </ProtectedRoute>
                }
            />
            <Route
                path=":id"
                element={
                    <ProtectedRoute>
                        <Template>
                            <Show />
                        </Template>
                    </ProtectedRoute>
                }
            />
        </Routes>
    );
};

export default MatriculaRoutes;
