// src/routes/FacturacionV1Routes.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from '../componentes/auth/ProtectedRoute';
import Template from '../componentes/client/layout/Template';

// facturacionv1
import Index from '../componentes/client/facturacionv1/Index';
import Create from '../componentes/client/facturacionv1/Create';
import Show from '../componentes/client/facturacionv1/Show';
import Edit from '../componentes/client/facturacionv1/Edit';

const FacturacionV1Routes = () => {
    return (
        <Routes>
            <Route
                path=""
                element={
                    <ProtectedRoute>
                        <Template>
                            <Index />
                        </Template>
                    </ProtectedRoute>
                }
            />
            <Route
                path="create/:rubroId"
                element={
                    <ProtectedRoute>
                        <Template>
                            <Create />
                        </Template>
                    </ProtectedRoute>
                }
            />
            <Route
                path=":documentoId"
                element={
                    <ProtectedRoute>
                        <Template>
                            <Show />
                        </Template>
                    </ProtectedRoute>
                }
            />
            <Route
                path=":documentoId/edit"
                element={
                    <ProtectedRoute>
                        <Template>
                            <Edit />
                        </Template>
                    </ProtectedRoute>
                }
            />
        </Routes>
    );
};

export default FacturacionV1Routes;
