// src/componentes/client/config/ConfigNavTab.js
import React from 'react';
import { NavLink } from 'react-router-dom';

const ConfigNavTab = () => {
    return (
        <ul className="nav nav-pills mb-3">
            <li className="nav-item">
                <NavLink
                    to="/config"
                    end
                    className={({ isActive }) => 'nav-link' + (isActive ? ' active' : '')}
                >
                    <i className='fas fa-home'></i> General
                </NavLink>
            </li>
            <li className="nav-item">
                <NavLink
                    to="/config/usuarios"
                    className={({ isActive }) => 'nav-link' + (isActive ? ' active' : '')}
                >
                    <i className='fas fa-users'></i> Usuarios
                </NavLink>
            </li>
            <li className="nav-item">
                <NavLink
                    to="/config/roles"
                    className={({ isActive }) => 'nav-link' + (isActive ? ' active' : '')}
                >
                    <i className='fas fa-user-tag'></i> Roles
                </NavLink>
            </li>
        </ul>
    );
};

export default ConfigNavTab;
