import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import { useApiContext  } from '../layout/Template';
import Spinner from '../widget/Spinner';
import Swal from 'sweetalert2';
import TextInput from '../../form/InputText';
import SelectInput from '../../form/SelectInput';
import SearchSelect from '../../form//SearchSelect';
import QuillEditor from '../../form/QuillEditor';
import FileInput from '../../form/FileInput';
import DateInput from '../../form/DateInput';
import DateTimeInput from '../../form/DateTimeInput';
import moment from 'moment';

const Index = () => {
    const [loading, setLoading] = useState(true);
    const { apiUrl, accessToken } = useApiContext();
    const [documentos, setDocumentos] = useState([]);

    const [pagination, setPagination] = useState({
        current_page: 1,
        from: 1,
        to: 0,
        total: 0,
        links: [],
    });
    
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        getData(currentPage);
    }, [currentPage]);

    const getData = async (page) => {
        try {
            const response = await axios.get(`${apiUrl}/facturacionv1`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                },
                params: {
                    page: page,
                }
            });
            const pageData = {
                data: response.data.data,
                pagination: {
                    current_page: response.data.current_page,
                    from: response.data.from,
                    to: response.data.to,
                    total: response.data.total,
                    links: response.data.links,
                },
                timestamp: Date.now(),
            };
            setDocumentos(response.data.data);
            setPagination(pageData.pagination);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching dashboard data', error.response ? error.response.data : error.message);
            setLoading(false);
        }
    };
    
    const handleDelete = async (id) => {
        // Mostrar el cuadro de confirmación
        const result = await Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás recuperar este registro después de eliminarlo!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
        });
        // Si el usuario confirma la eliminación
        if (result.isConfirmed) {
            try {
                await axios.delete(`${apiUrl}/facturacionv1/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Accept': 'application/json',
                    },
                });
                getData(currentPage);
                Swal.fire({
                    icon: 'success',
                    title: 'Eliminado',
                    text: 'El registro ha sido eliminado correctamente.',
                });
            } catch (error) {
                console.error('Error deleting registro:', error.response ? error.response.data : error.message);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'No se pudo eliminar el registro. Por favor, inténtelo de nuevo.',
                });
            }
        }
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className='py-3 px-3 content-wrapper'>

            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2 className="">Consultar Documentos</h2>
                </div>
                <div>
                    <div className="btn-group" role="group" aria-label="Basic outlined example">
                        <Link to='create' className="btn btn-outline-primary"><i className="fas fa-plus-circle"></i></Link>
                    </div>
                </div>
            </div>

            <div className="card card-rounded">
                <div className="card-body">
                    <div className="table-responsive  mt-1">
                        <table className="table select-table">
                        <thead>
                                <tr>
                                    <th></th>
                                    <th>Persona</th>
                                    <th># Documento</th>
                                    <th>Fecha</th>
                                    <th>Rubro</th>
                                    <th>Estado SRI</th>
                                    <th>Valor</th>
                                    <th>Estado</th>
                                </tr>
                            </thead>
                            <tbody>
                                {documentos.map(documento => (
                                    <tr style={{ fontSize: '20px' }} key={documento.id}>
                                        <td>
                                            <div className="dropdown">
                                                <button 
                                                    className="btn btn-sm btn-secondary" 
                                                    type="button" 
                                                    id={`dropdownMenuButton-${documento.id}`} 
                                                    data-bs-toggle="dropdown" 
                                                    aria-expanded="false"
                                                >
                                                    <i className="fas fa-cogs"></i>
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby={`dropdownMenuButton-${documento.id}`}>
                                                    <li>
                                                        <Link 
                                                            className="dropdown-item" 
                                                            to={`/facturacionv1/${documento.id}`} 
                                                        >
                                                            <i className="fas fa-edit"></i> Editar
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link 
                                                            className="dropdown-item"
                                                            to="#"
                                                            onClick={()=>handleDelete(documento.id)}
                                                        >
                                                            <i className="fas fa-trash-alt"></i> Eliminar
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                        <td>
                                            <Link to={`/facturacionv1/${documento.id}`} className="text-decoration-none">
                                            </Link>
                                        </td>
                                        <td>
                                            <Link to={`/facturacionv1/${documento.id}`} className="text-decoration-none">
                                                {documento.tipo === 1 ? `DNA ${documento.secuencial}` : `FACT ${documento.estab} - ${documento.ptoEmi} - ${documento.secuencial}`}
                                            </Link>
                                        </td>
                                        <td>
                                            <Link to={`/facturacionv1/${documento.id}`} className="text-decoration-none">
                                                {moment(documento.fechaEmision).format('DD-MM-YYYY')}
                                            </Link>
                                        </td>
                                        <td>
                                            <Link to={`/facturacionv1/${documento.id}`} className="text-decoration-none">
                                                Rubro {documento.id_rubro}
                                            </Link>
                                        </td>
                                        <td>
                                            <Link to={`/facturacionv1/${documento.id}`} className="text-decoration-none">
                                            {documento.tipo === 1 ? `N/A` : `${documento.estadoSri}`}
                                            </Link>
                                        </td>
                                        <td>
                                            <Link to={`/facturacionv1/${documento.id}`} className="text-decoration-none">
                                                ${documento.total}
                                            </Link>
                                        </td>
                                        <td>
                                            <Link to={`/facturacionv1/${documento.id}`} className="text-decoration-none">
                                                {documento.estado === 1 ? 'Activo' : 'Anulado'}
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* Paginación */}
                    <div>
                        <div className="row mt-2 pb-0 mb-0">
                            <div className="text-center">
                                <div>
                                    Mostrando {pagination.from} a {pagination.to} de {pagination.total} resultados
                                </div>
                            </div>
                        </div>
                        <div className="text-center">
                            <ul className="pagination">
                                {pagination.links.map((link, index) => (
                                    <li key={index} className={`paginate_button page-item ${link.active ? 'active' : ''}`}>
                                        <button
                                            onClick={() => link.url && setCurrentPage(new URL(link.url).searchParams.get('page'))}
                                            className="page-link"
                                            disabled={!link.url}
                                            dangerouslySetInnerHTML={{ __html: link.label }}
                                        />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;