import axios from 'axios'
import React, {useState, useEffect} from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useApiContext  } from '../layout/Template';
import FormContent from './FormContent'; 

const Create = () => {
    const { apiUrl, accessToken } = useApiContext();
    const [formData, setFormData] = useState({
        cajero: '',
        punto_emision: '',
        nombre: '',
        num: ''
    });
    const [errors, setErrors] = useState({}); // Estado para errores de validación
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(`${apiUrl}/cajas`, formData, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            });
            console.log('Caja creada exitosamente:', response.data);
            navigate('/cajas');  // Redirigir a la lista de cajas
            window.showSuccessPopup(response.data.message);
        } catch (error) {
            if (error.response) {
                // Manejar errores de validación
                setErrors(error.response.data.errors);
            } else {
                console.error('Error al crear la caja:', error.response ? error.response.data : error.message);
            }
        }
    };
    return (
        <div className='py-3 px-3 content-wrapper'>
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2 className="">Crear Caja</h2>
                </div>
            </div>
            <div className="col-md-6 offset-md-3 grid-margin stretch-card mt-3">
                <div className="card">
                    <div className="card-body">
                        <form className="forms-sample" onSubmit={handleSubmit}>
                            <FormContent
                                formData={formData}
                                setFormData={setFormData}
                                errors={errors}
                            />
                            <button type="submit" className="btn btn-primary me-2"><i className="fas fa-save"></i> Crear Caja</button>
                            <Link className="btn btn-warning" to='/cajas'>Volver</Link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Create;
