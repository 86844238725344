import React, { useState } from 'react';
import axios from 'axios';
import { useApiContext  } from '../layout/Template';
import SearchSelect from '../../form//SearchSelect';
import TextInput from '../../form/InputText';
import CheckboxInput from '../../form/CheckboxInput';

const FormContent = ({ formData, setFormData, setMeses, meses = [], errors }) => {
    const { apiUrl, accessToken } = useApiContext();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCheckboxChange = (field) => (e) => {
        const checked = e.target.checked;
        setFormData(prevFormData => ({
            ...prevFormData,
            [field]: checked
        }));
    };
    
    const handleMatriculaChange = (e) => {
        const { name, value } = e.target;
    
        setFormData((prev) => {
            // Convertir el valor a un número, asignar 0 si está vacío
            const nuevoValor = name === "valor_descuento" ? (value === "" ? 0 : parseFloat(value)) : value;
    
            // Obtener el valor total, asegurando que sea un número
            const valorTotal = parseFloat(prev.matricula.valor_total) || 0;
    
            // Validar que el descuento no sea mayor que el valor total
            if (name === "valor_descuento" && nuevoValor > valorTotal) {
                return prev; // No actualiza el estado si el descuento es mayor que el valor total
            }
    
            // Calcular el nuevo total
            const nuevoTotal = valorTotal - nuevoValor;
    
            // Calcular el porcentaje de descuento
            const porcentajeDescuento = valorTotal > 0 ? ((nuevoValor / valorTotal) * 100).toFixed(2) : 0; // Evitar NaN
    
            return {
                ...prev,
                matricula: {
                    ...prev.matricula,
                    [name]: nuevoValor,
                    total: (name === "valor_descuento") ? nuevoTotal.toFixed(2) : prev.matricula.total,
                    porcentaje_descuento: porcentajeDescuento // Actualizar porcentaje de descuento
                }
            };
        });
    };

    const handleItemsChange = (index) => (event) => {
        const { name, value } = event.target;
    
        setMeses((prevMeses) => {
            const updatedMeses = [...prevMeses];
            const valorTotal = Number(updatedMeses[index].valor_total);
    
            // Actualiza el valor correspondiente
            updatedMeses[index] = {
                ...updatedMeses[index],
                [name]: value,
            };
    
            // Solo recalcula si el campo editado es 'descuento'
            if (name === 'descuento') {
                const nuevoDescuento = Number(value);
    
                // Validar que el descuento no sea mayor que el valor total
                if (nuevoDescuento > valorTotal) {
                    return prevMeses; // No actualiza el estado si el descuento es mayor que el valor total
                }
    
                // Calcular el porcentaje de descuento y el nuevo total
                const porcentajeDescuento = valorTotal > 0 ? ((nuevoDescuento / valorTotal) * 100).toFixed(2) : '0.00';
                const nuevoTotal = valorTotal - nuevoDescuento;
    
                // Actualiza el porcentaje y el total
                updatedMeses[index].porcentaje_descuento = porcentajeDescuento;
                updatedMeses[index].total = nuevoTotal.toFixed(2);
            }
    
            return updatedMeses;
        });
    };
    
    return (
        <>
            <SearchSelect 
                apiUrl={apiUrl}
                endpoint="busqueda/estudiantes"
                accessToken={accessToken}
                label="Estudiante"
                name="id_estudiante"
                formData={formData}
                setFormData={setFormData}
                errors={errors}
            />
            <CheckboxInput 
                id="becado"
                name="becado"
                checked={formData.becado}
                onChange={handleCheckboxChange('becado')}
                label="Becado"
            />
            <CheckboxInput 
                id="descuento"
                name="descuento"
                checked={formData.descuento}
                onChange={handleCheckboxChange('descuento')}
                label="Descuento"
            />
            <div className="table-responsive pt-3">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Valor</th>
                            <th>Descuento</th>
                            <th>% Descuento</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='celda'>
                                <TextInput
                                    placeholder="Matricula"
                                    name="valor_total"
                                    value={formData.matricula.valor_total}
                                    onChange={handleMatriculaChange}
                                    validacion="decimal"
                                    errors={errors}
                                    readonly={true}
                                    divClass={false}
                                />
                            </td>
                            <td>
                                <TextInput
                                    placeholder="Descuento ($)"
                                    name="valor_descuento"
                                    value={formData.matricula.valor_descuento ?? 0}
                                    onChange={handleMatriculaChange}
                                    validacion="decimal"
                                    errors={errors}
                                    divClass={false}
                                />
                            </td>
                            <td>
                                <TextInput
                                    placeholder="% Descuento"
                                    name="porcentaje_descuento"
                                    value={formData.matricula.porcentaje_descuento} // Usar el valor del porcentaje
                                    onChange={() => {}} // No se permite la edición de este campo
                                    validacion="porcentaje"
                                    errors={errors}
                                    readonly={true}
                                    divClass={false}
                                />
                            </td>
                            <td>
                                <TextInput
                                    placeholder="Valor Total"
                                    name="total"
                                    value={formData.matricula.total}
                                    onChange={handleMatriculaChange}
                                    validacion="decimal"
                                    errors={errors}
                                    readonly={true}
                                    divClass={false}
                                />
                            </td>
                        </tr>
                        {meses.map((mes, index) => (
                            <tr key={index}>
                                <td className='celda'>
                                    <TextInput
                                        placeholder={mes.nombre}
                                        name="valor_total"
                                        value={mes.valor_total}
                                        onChange={handleItemsChange(index)}
                                        validacion="decimal"
                                        errors={errors}
                                        readonly={true}
                                        divClass={false}
                                    />
                                </td>
                                <td>
                                    <TextInput
                                        placeholder="Descuento"
                                        name="descuento" 
                                        value={mes.descuento ?? 0} 
                                        onChange={handleItemsChange(index)}
                                        validacion="decimal"
                                        errors={errors}
                                        divClass={false}
                                    />
                                </td>
                                <td>
                                    <TextInput
                                        placeholder="% Descuento"
                                        name="porcentaje_descuento" 
                                        value={mes.porcentaje_descuento ?? 0} // Cambia aquí para usar el porcentaje calculado
                                        onChange={() => {}} // No se permite la edición de este campo
                                        validacion="porcentaje"
                                        errors={errors}
                                        divClass={false}
                                        readonly={true}
                                    />
                                </td>
                                <td>
                                    <TextInput
                                        placeholder="Valor Total"
                                        name="total"
                                        value={mes.total}
                                        onChange={() => {}} // Solo lectura
                                        validacion="decimal"
                                        errors={errors}
                                        readonly={true}
                                        divClass={false}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default FormContent;