import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import { useApiContext  } from '../layout/Template';
import Spinner from '../widget/Spinner';
import Swal from 'sweetalert2';

const cache = {};
const CACHE_DURATION = 10 * 1000; // 10 segundos en milisegundos

const Index = () => {
    const [loading, setLoading] = useState(true);
    const { apiUrl, accessToken } = useApiContext();
    const [comunicados, setComunicados] = useState([]);
    const [pagination, setPagination] = useState({
        current_page: 1,
        from: 1,
        to: 0,
        total: 0,
        links: [],
    });
    
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        getData(currentPage);
    }, [currentPage]);

    const getData = async (page) => {
        const now = Date.now();
        
        // Verifica si el caché existe y es válido
        if (cache[page] && (now - cache[page].timestamp < CACHE_DURATION)) {
            setComunicados(cache[page].data);
            setPagination(cache[page].pagination);
            setLoading(false);
            return;
        }

        try {
            const response = await axios.get(`${apiUrl}/comunicados?page=${page}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                },
            });
            console.log('Datos recibidos:', response.data);

            // Actualiza el caché
            cache[page] = {
                data: response.data.data,
                pagination: {
                    current_page: response.data.current_page,
                    from: response.data.from,
                    to: response.data.to,
                    total: response.data.total,
                    links: response.data.links,
                },
                timestamp: Date.now(), // Marca de tiempo para la expiración
            };

            // Actualiza el estado
            setComunicados(response.data.data);
            setPagination(cache[page].pagination);

            setLoading(false);
        } catch (error) {
            console.error('Error fetching dashboard data', error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey && event.key === 'r') {
                event.preventDefault(); // Evita la recarga de la página
                delete cache[currentPage];
                getData(currentPage);
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [currentPage]);

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className='py-3 px-3 content-wrapper'>
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2 className="">Consultar comunicados</h2>
                </div>
                <div>
                    <div className="btn-group" role="group" aria-label="Basic outlined example">
                        <Link to='create' className="btn btn-outline-primary"><i className="fas fa-plus-circle"></i></Link>
                    </div>
                </div>
            </div>
            <div className="offset-lg-2 col-lg-8 offset-md-1 col-md-10 col-sm-12 d-flex flex-column card">
                <div className="card-body">
                    <div className="tab-pane active show fade">
                        {comunicados.map(comunicado => (
                            <>
                            <div className="mt-3">
                                <h5 className="h6 d-inline font-weight-bold px-2">{comunicado.fecha}</h5>
                            </div>
                            <div className="list-group list-group-flush" key={comunicado.id}>
                                <div className="list-group-item flex-column pt-2 pb-0 border-0 px-2">
                                    <div className="d-sm-flex justify-content-between align-items-start">
                                        <div className="d-flex mr-auto pb-1 mw-100">
                                            <div className="align-self-top align-self-center mx-3  mb-1 mb-sm-0 text-nowrap">
                                                <i className="fas fa-bullhorn fs-4"></i>
                                            </div>
                                            <div className="event-name-container flex-grow-1 line-height-3 nowrap">
                                                <div className="d-flex">
                                                    <h6 className="mb-0 pb-1">
                                                        <Link to={`${comunicado.id}`}>
                                                            {comunicado.titulo}
                                                        </Link>
                                                    </h6>
                                                </div>
                                                <div>
                                                    <small className="mb-0">
                                                        {comunicado.titulo}
                                                    </small>
                                                </div>
                                                <small className="mb-0">
                                                    Enviado a: {comunicado.destinatario === 1 ? 'Todos' : comunicado.destinatario === 2 ? 'Estudiantes' : 'Docentes'} - { comunicado.fecha }
                                                </small>
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="me-2">
                                                <h6 className="">
                                                    <Link className="btn btn-primary btn-sm text-nowrap" to={`${comunicado.id}`}>
                                                        Ver Comunicado
                                                    </Link>
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pt-2 border-bottom"></div>
                                </div>
                            </div>
                            </>
                        ))}
                    </div>
                    {/* Paginación */}
                    <div className="row mt-2 pb-0 mb-0">
                        <div className="col-sm-12 col-md-5">
                            <div>
                                Mostrando {pagination.from} a {pagination.to} de {pagination.total} resultados
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-7">
                            <ul className="pagination">
                                {pagination.links.map((link, index) => (
                                    <li key={index} className={`paginate_button page-item ${link.active ? 'active' : ''}`}>
                                        <button
                                            onClick={() => link.url && setCurrentPage(new URL(link.url).searchParams.get('page'))}
                                            className="page-link"
                                            disabled={!link.url}
                                            dangerouslySetInnerHTML={{ __html: link.label }}
                                        />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
