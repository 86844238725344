import React, { useEffect, useState } from 'react';

const RubroRow = ({ rubro, valor, valDescuento, index, editIndex, handleEdit, handleSave, handleChange, handleCancel }) => {
    const [valorDescuento, setValorDescuento] = useState(valDescuento); // Estado local para el descuento en dólares
    const [fechaVencimiento, setFechaVencimiento] = useState(rubro.vencimiento); // Estado local para la fecha

    useEffect(() => {
        if (editIndex === index) {
            setValorDescuento(valDescuento); // Inicializa el estado local con el valor recibido en props al entrar en modo edición
        }
    }, [editIndex, index, valDescuento]); // Actualiza cuando el índice de edición o valDescuento cambian

    // Validación para permitir solo hasta 2 decimales y que no exceda el valor base
    const formatDescuentoValue = (value) => {
        let formattedValue = value.replace(/[^0-9.]/g, ''); // Permite solo números y puntos
        const parts = formattedValue.split('.');

        // Si hay más de un punto, eliminamos el exceso
        if (parts.length > 2) {
            formattedValue = parts[0] + '.' + parts[1];
        }

        // Limitar a 2 decimales
        if (parts[1] && parts[1].length > 2) {
            formattedValue = parts[0] + '.' + parts[1].substring(0, 2);
        }

        return formattedValue;
    };

    // Maneja el cambio del input de monto en dólares
    const handleDescuentoChange = (event) => {
        let nuevoValor = event.target.value;
        nuevoValor = formatDescuentoValue(nuevoValor); // Formatea el valor

        // No permitir que el descuento exceda el valor base
        if (parseFloat(nuevoValor) > valor) {
            nuevoValor = valor.toString(); // Limita el valor al máximo permitido
        }

        setValorDescuento(nuevoValor); // Actualiza el estado local

        // Calcular y actualizar el descuento en rubro en tiempo real
        const descuentoDolares = parseFloat(nuevoValor);

        if (!isNaN(descuentoDolares) && descuentoDolares >= 0) {
            const porcentajeDescuento = (descuentoDolares / valor) * 100; // Cálculo del porcentaje de descuento
            handleChange(index, porcentajeDescuento, fechaVencimiento); // Actualiza el descuento y la fecha en el padre
        } else {
            handleChange(index, 0, fechaVencimiento); // Si no es válido, establecer el descuento en 0
        }
    };

    // Maneja el cambio de la fecha
    const handleFechaChange = (event) => {
        const nuevaFecha = event.target.value;
        setFechaVencimiento(nuevaFecha); // Actualiza la fecha localmente
        handleChange(index, rubro.descuento, nuevaFecha); // Actualiza la fecha en el padre
    };

    // Guarda el descuento y la fecha cuando se guarda la edición
    const handleSaveDiscount = () => {
        handleSave(index); // Guarda la edición
        setValorDescuento(valDescuento); // Reinicia el campo al valor original después de guardar
    };

    // Calcular el monto total después del descuento
    const montoTotalAjustado = valor - parseFloat(valorDescuento || 0);

    return (
        <tr>
            {rubro.tipo === 2 && (
                <td>Pensión</td>
            )}
            <td colSpan={rubro.tipo !== 2 ? 2 : 1} className="text-center">{rubro.tipo === 1 ? 'Matricula' : rubro.tipo === 2 ? rubro.mes : rubro.concepto }</td>
            <td>
                {editIndex === index ? (
                    <input
                        type="date"
                        className="form-control"
                        value={fechaVencimiento} // Muestra la fecha editable
                        onChange={handleFechaChange} // Permite modificar la fecha en modo edición
                    />
                ) : (
                    <input
                        type="date"
                        className="form-control"
                        value={fechaVencimiento} // Muestra la fecha
                        readOnly={true} // Solo lectura fuera de modo edición
                    />
                )}
            </td>
            <td>{rubro.facturado ? 'Facturado' : 'Pendiente'}</td>
            <td>{valor}</td>
            <td>
                <div className="input-group input-group-sm mb-3" style={{ width: '150px' }}>
                    {editIndex === index ? (
                        <>
                            <div className="input-group-prepend">
                                <button onClick={handleSaveDiscount} className="btn-success btn-sm">
                                    <i className="fas fa-save"></i> {/* Ícono de guardar */}
                                </button>
                            </div>
                            <input
                                type="text" // Cambiado a text para mejor control
                                className="form-control"
                                value={valorDescuento} // Usamos el estado local
                                onChange={handleDescuentoChange} // Maneja el cambio del descuento
                                aria-label="Descuento"
                            />
                            <div className="input-group-append">
                                <button onClick={handleCancel} className="btn-danger btn-sm">
                                    <i className="fas fa-times"></i> {/* Ícono de cancelar */}
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="input-group-prepend">
                                <button onClick={() => handleEdit(index)} className="btn-primary btn-sm">
                                    <i className="fas fa-edit"></i> {/* Ícono de editar */}
                                </button>
                            </div>
                            <input
                                type="text"
                                className="form-control"
                                value={valorDescuento} // Muestra el valor del descuento
                                readOnly={true}
                                aria-label="Descuento Actual"
                            />
                        </>
                    )}
                </div>
            </td>
            <td>
                <input
                    type="text" // Cambiado a text para consistencia
                    className="form-control"
                    value={rubro.descuento} // Muestra el porcentaje de descuento calculado
                    readOnly={true}
                    style={{ width: '80px', marginRight: '10px' }} // Ajuste del tamaño y separación
                />
            </td>
            <td>{montoTotalAjustado.toFixed(2)}</td> {/* Muestra el monto total ajustado */}
            <td>{rubro.monto_pagado}</td>
        </tr>
    );
};

export default RubroRow;
