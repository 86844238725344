import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useApiContext } from '../layout/Template';
import Spinner from '../widget/Spinner';
import Form from './Form';
import { descargarPdf } from '../widget/DescargarPdf';

const Edit = () => {
    const { comprobanteId } = useParams();
    const [loading, setLoading] = useState(false);
    const { apiUrl, accessToken } = useApiContext();
    const [formData, setFormData] = useState({
        forma_pago: 1,
        id_caja: '',
        referencia_pago: '',
        id_banco: '',
        fecha: '',
        id_cuenta: '',
        id_representante: '',
        representante: '',
        descripcion: '',
        adjunto: null,
        rubros: [], // Cambiado a un array
        valor_pago: [] // Cambia a array vacío
    });
    const [comprobante, setComprobante] = useState(null);
    const [rubrosPrevios, setRubrosPrevios] = useState([]);
    const [errors, setErrors] = useState({}); // Estado para errores de validación
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    const fetchComprobante = async () => {
        try {
            const response = await axios.get(`${apiUrl}/movimientos/${comprobanteId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            setComprobante(response.data.comprobante);
            const { comprobante, forma_pago, id_caja, referencia_pago, id_banco, fecha, id_cuenta, id_representante, descripcion, adjunto, representante } = response.data.comprobante;
            setFormData({
                forma_pago: forma_pago,
                id_caja: id_caja,
                referencia_pago: referencia_pago,
                id_banco: id_banco,
                fecha: fecha,
                id_cuenta: id_cuenta,
                id_representante: id_representante,
                descripcion: descripcion,
                adjunto: adjunto,
                representante: representante.nombres + ' - ' + representante.identificacion,
            });
            console.table(rubrosPrevios);
            setRubrosPrevios(response.data.rubros);
        } catch (err) {
            window.showErrorPopup('No se recibió respuesta del servidor.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchComprobante();
    }, [comprobanteId, apiUrl, accessToken]);

    const handleSubmit = async (event) => {
        setIsSubmitting(true); 
        event.preventDefault();

        const formDataWithFile = new FormData();

        // Agregar campos al FormData
        Object.keys(formData).forEach((key) => {
            if (Array.isArray(formData[key])) {
                // Si es un array, agregar cada elemento como un campo separado
                formData[key].forEach((item) => {
                    formDataWithFile.append(`${key}[]`, item); // Agregar como rubros[]
                });
            } else {
                formDataWithFile.append(key, formData[key] || ''); // Si el valor es undefined, se agrega una cadena vacía
            }
        });

        // Manejo del adjunnto
        if (formData.adjunnto) {
            formDataWithFile.append('adjunnto', formData.adjunnto); // Añadir el adjunnto
        }

        try {
            const response = await axios.post(`${apiUrl}/movimientos/${comprobanteId}`, formDataWithFile, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                }
            });
            navigate(`/movimientos/${comprobanteId}`);
            window.showSuccessPopup(response.data.message);
        } catch (error) {
            const statusCode = error.response?.status;
            const errorMessage = error.response?.data?.error || 'Ocurrió un error inesperado';
            
            if (statusCode !== 422) {
                window.showErrorPopup('Error al procesar la solicitud: ' + errorMessage);
            }else{
                setErrors(error.response.data.errors);
                console.log(error.response.data)
                window.showErrorPopup(error.response.data.message);
            }
        }
        setIsSubmitting(false); 
    };


    if (loading) {
        return <Spinner />;
    }

    return (
        <div className='py-3 px-3 content-wrapper'>
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2 className="">Actualizar Movimiento</h2>
                </div>
                <div>
                    <div class="btn-group" role="group" aria-label="Basic outlined example">
                        <Link class="btn btn-outline-primary btn-sm" onClick={() => descargarPdf(`${apiUrl}/movimientos/pdf/${comprobanteId}`, null, 'comprobante.pdf', accessToken, null )}><i class="fas fa-receipt"></i> PDF</Link>
                    </div>
                </div>
            </div>
            
            <div className="grid-margin stretch-card mt-3">
                <div className="card">
                    <div className="card-body">
                        <form onSubmit={handleSubmit}>
                            <Form
                                formData={formData}
                                setFormData={setFormData}
                                errors={errors}
                                rubrosPrevios={rubrosPrevios}
                                isEdit={true}
                            />
                            <button 
                                type="submit" 
                                className="btn btn-primary me-2 boton-procesar" 
                                disabled={isSubmitting}
                            >
                                {isSubmitting && (
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                )}
                                {isSubmitting ? 'Guardando...' : <><i className="fas fa-save"></i> Guardar cambios</>}
                            </button>
                            <Link className="btn btn-warning" to='/movimientos'>Volver</Link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Edit;
