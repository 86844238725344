import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useApiContext } from '../layout/Template';
import Spinner from '../widget/Spinner';
import FormContent from './FormContent';
import FormFirma from './FormFirma';
import FormEstablecimientos from './FormEstablecimientos';

const Create = () => {
    const { apiUrl, accessToken } = useApiContext();
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        ambiente: '',
        razonSocial: '',
        nombreComercial: '',
        estab: '',
        ruc: '',
        dirMatriz: '',
        correo: '',
        telefono: '',
        obligadoContabilidad: '',
        ambiente: '',
        rimpe: '',
        logo: '',
        claveFirma: '',
        archivoFirma: '',
    });
    const [logoFile, setLogoFile] = useState(null);
    const [configuracion, setConfiguracion] =useState(null);
    const [puntos_emision, setPtosEmision] =useState([]);
    const [establecimientos, setEstablecimientos] =useState([]);
    const [errors, setErrors] = useState({});
    const [generalError, setGeneralError] = useState(''); // Estado para errores generales
    
    const fetchConfigData = async () => {
        setLoading(true);
        // Limpiar los errores antes de obtener los nuevos datos
        setErrors({}); 
        try {
            const response = await axios.get(`${apiUrl}/confFact`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                }
            });
            if (response.data.configuracion) { // Cambiado para revisar la respuesta correcta
                setConfiguracion(response.data.configuracion);
                setFormData(response.data.configuracion);
                setPtosEmision(response.data.puntos_emision);
                setEstablecimientos(response.data.establecimientos);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data', error);
            setGeneralError('Error fetching configuration data.');
        }
    };

    useEffect(() => {
        fetchConfigData();
    }, [apiUrl, accessToken]);

    const handleFileChange = (e) => {
        setLogoFile(e.target.files[0]); // Almacena el archivo seleccionado
    };

    const handleSubmit = async (e) => {
        window.showLoaderPopup();

        e.preventDefault();

        const formDataToSend = new FormData();
        Object.keys(formData).forEach(key => {
            formDataToSend.append(key, formData[key]);
        });
        if (logoFile) {
            formDataToSend.append('logo', logoFile); // Agrega el archivo de logo si se seleccionó
        }

        try {
            const response = await axios.post(`${apiUrl}/confFact`, formDataToSend, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'multipart/form-data',
                }
            });
            await fetchConfigData(); 
            setGeneralError('');
            window.showSuccessPopup(response.data.message);
        } catch (error) {
            console.error('Error submitting form', error);
            if (error.response && error.response.data) {
                setErrors(error.response.data.errors || {});
                setGeneralError('There was an error submitting the form.');
            } else {
                setGeneralError('An unexpected error occurred.');
            }
        }
        window.hideLoaderPopup();
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className='py-3 px-3 content-wrapper'>
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2 className="">Configuración de Facturación Electronica</h2>
                </div>
            </div>
            <div className='row'>
                {/* {generalError && <div className="alert alert-danger">{generalError}</div>} */}
                <div className="col-lg-10 col-md-12 offset-lg-1 offset-md-0 mb-3">
                    <div className="card">
                        <div className="card-body">
                            <form className="forms-sample" onSubmit={handleSubmit}>
                                <FormContent
                                    formData={formData}
                                    setFormData={setFormData}
                                    handleFileChange={handleFileChange} 
                                    errors={errors}
                                />
                                <button type="submit" className="btn btn-primary me-2">
                                    <i className="fas fa-save"></i> Guardar configuración
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
                {configuracion && (
                    <>
                        <div className="col-lg-5 col-md-12 offset-lg-1 offset-md-0">
                            {/* Formulario de Configuración de Establecimientos */}
                            <FormEstablecimientos
                                formData={formData}
                                setFormData={setFormData}
                                establecimientos={establecimientos}
                                puntos_emision={puntos_emision}
                                setErrors={setErrors}
                                errors={errors}
                                onSuccess={fetchConfigData}
                            />
                        </div>
                        <div className="col-lg-5 col-md-12 offset-md-0">
                            <FormFirma
                                formData={formData}
                                setFormData={setFormData}
                                onSuccess={fetchConfigData}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Create;
