// DatosGeneralesContext.js
import { useState, useEffect } from 'react';
import axios from 'axios';

const DatosGeneralesContext = (apiUrl) => {
    const [loading, setLoading] = useState(true);
    const [cantones, setCantones] = useState([]);
    const [provincias, setProvincias] = useState([]);
    const [paises, setPaises] = useState([]);
    const [bancos, setBancos] = useState([]);
    const [tiposEscuela, setTiposEscuelas] = useState([]);
    const [periodos, setPeriodos] = useState([]);
    const [tiposElementos, setTiposElementos] = useState([]);

    useEffect(() => {
        const getDatosGenerales = async () => {
            // Recupera los datos desde el localStorage
            const storedCantones = localStorage.getItem('cantones');
            const storedProvincias = localStorage.getItem('provincias');
            const storedPaises = localStorage.getItem('paises');
            const storeBancos = localStorage.getItem('bancos');
            const storeTiposEscuelas = localStorage.getItem('tiposEscuela');
            const storePeriodos = localStorage.getItem('periodos');
            const storeTiposElementos = localStorage.getItem('tiposElementos');

            if (storedCantones && storedProvincias && storedPaises && storeBancos && storeTiposEscuelas && storePeriodos && storeTiposElementos) {
                // Si los datos están en el localStorage, los parsea y los asigna a los estados
                setCantones(JSON.parse(storedCantones));
                setProvincias(JSON.parse(storedProvincias));
                setPaises(JSON.parse(storedPaises));
                setBancos(JSON.parse(storeBancos));
                setTiposEscuelas(JSON.parse(storeTiposEscuelas));
                setPeriodos(JSON.parse(storePeriodos));
                setTiposElementos(JSON.parse(storeTiposElementos));
                setLoading(false);
            } else {
                try {
                    // Si los datos no están en el localStorage, realiza la solicitud a la API
                    const response = await axios.get(`${apiUrl}/datosCiudades`);
                    console.log('Respuesta completa:', response.data);

                    // Almacena los datos en el localStorage
                    localStorage.setItem('cantones', JSON.stringify(response.data.cantones));
                    localStorage.setItem('provincias', JSON.stringify(response.data.provincias));
                    localStorage.setItem('paises', JSON.stringify(response.data.paises));
                    localStorage.setItem('bancos', JSON.stringify(response.data.bancos));
                    localStorage.setItem('tiposEscuela', JSON.stringify(response.data.tiposEscuela));
                    localStorage.setItem('periodos', JSON.stringify(response.data.periodos));
                    localStorage.setItem('tiposElementos', JSON.stringify(response.data.tiposElementos));

                    // Asigna los datos a los estados
                    setCantones(response.data.cantones);
                    setProvincias(response.data.provincias);
                    setPaises(response.data.paises);
                    setBancos(response.data.bancos);
                    setTiposEscuelas(response.data.tiposEscuela);
                    setPeriodos(response.data.periodos);
                    setTiposElementos(response.data.tiposElementos);
                    setLoading(false);
                } catch (error) {
                    console.error('Error al obtener los datos:', error.response ? error.response.data : error.message);
                }
            }
        };

        getDatosGenerales();
    }, [apiUrl]);

    return { cantones, provincias, paises, bancos, tiposEscuela, periodos, tiposElementos, loading };
};

export default DatosGeneralesContext;
