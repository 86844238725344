import React from 'react';
import { PieChart, Pie, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';

// Función para generar colores aleatorios
const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

const MyPieChart = ({ data }) => {
    // Genera un array de colores aleatorios con el mismo tamaño que los datos
    const colors = data.map(() => getRandomColor());

    return (
        <ResponsiveContainer width="100%" height={400}>
            <PieChart>
                <Pie
                    data={data}
                    dataKey="valor"   // Asegúrate de que "valor" sea la clave correcta para los datos
                    nameKey="cualitativa" // Asegúrate de que "cualitativa" sea la clave correcta para los nombres
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    fill="#8884d8"
                    label
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index]} />
                    ))}
                </Pie>
                <Tooltip />
                <Legend />
            </PieChart>
        </ResponsiveContainer>
    );
};

export default MyPieChart;
