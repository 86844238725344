import axios from 'axios';

export const descargarPdf = async (endpoint, payload = null, nombreArchivo, accessToken, setIsLoading) => {
    if (setIsLoading) setIsLoading(true); // Solo si pasas la función de loading

    try {
        const options = {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Accept': 'application/pdf',
            },
            responseType: 'blob',
        };

        let response;
        // Si se pasa un payload, hacer una solicitud POST
        if (payload) {
            response = await axios.post(endpoint, payload, options);
        } else {
            // Si no hay payload, se usa el método GET
            response = await axios.get(endpoint, options);
        }

        // Crear un enlace de descarga
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.href = url;

        // Formatear nombre del archivo de manera segura
        const archivoSeguro = nombreArchivo.replace(/[\/\\?%*:|"<>]/g, '-') + '.pdf';

        link.setAttribute('download', archivoSeguro);
        document.body.appendChild(link);
        link.click();

        // Liberar recursos y limpiar
        window.URL.revokeObjectURL(url);
        link.remove();
    } catch (err) {
        console.error('Error al descargar el PDF:', err);
        window.showErrorPopup('Error al descargar el archivo PDF.');
    } finally {
        if (setIsLoading) setIsLoading(false); // Finaliza el loading si existe
    }
};
