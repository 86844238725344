import axios from 'axios'
import React, { useState, useEffect, useRef } from 'react';
import { useApiContext  } from '../layout/Template';
import TextInput from '../../form/InputText';
import SelectInput from '../../form/SelectInput';
import DatosGeneralesContext from '../widget/DatosGeneralesContext';
import QuillEditor from '../../form/QuillEditor';
import FileInput from '../../form/FileInput';

const FormContent = ({ formData, setFormData, errors, setFile, isEdit = false }) => {
    const { apiUrl } = useApiContext();
    const { periodos } = DatosGeneralesContext(apiUrl);

    // Definir handleInputChange dentro del componente
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleFileChange = (fileItems, name) => {
        // Extraer el archivo de fileItems y establecer en el estado
        const file = fileItems.length > 0 ? fileItems[0].file : null;
        setFormData((prevState) => ({
            ...prevState,
            [name]: file, // Usa el nombre del input para actualizar el estado
        }));
    };

    return (
        <>
            <div className="mb-3">
                <TextInput
                    label="Titulo"
                    name="titulo"
                    value={formData.titulo}
                    onChange={handleInputChange}
                    placeholder="Titulo"
                    readonly={isEdit}
                    errors={errors}
                />
            </div>
            <QuillEditor
                name="descripcion"
                id="editor"
                value={formData.descripcion}
                onChange={handleInputChange}
                disabled={isEdit}
                errors={errors}
            />
            <div className="my-3">
                <TextInput
                    label="Url"
                    name="url"
                    value={formData.url}
                    onChange={handleInputChange}
                    placeholder="Url"
                    readonly={isEdit}
                    errors={errors}
                />
            </div>
            <div className='row'>
                <div className="col-6">
                    <SelectInput
                        id="destinatario"
                        name="destinatario"
                        value={formData.destinatario}
                        onChange={handleInputChange}
                        placeholder="Destinatario"
                        disabled={isEdit}
                        options={[
                            { value: '1', label: 'Todos' },
                            { value: '2', label: 'Estudiantes' },
                            { value: '3', label: 'Docentes' }
                        ]}
                        error={errors['destinatario'] && errors['destinatario'][0]}
                    />
                </div>
                <div className="col-6">
                    <SelectInput
                        id="id_periodo"
                        name="id_periodo"
                        value={formData.id_periodo}
                        onChange={handleInputChange}
                        placeholder="Periodo Lectivo"
                        disabled={isEdit}
                        options={periodos.map(periodoActual => ({
                            value: periodoActual.id,
                            label: periodoActual.descripcion
                        }))}
                        error={errors['id_periodo'] && errors['id_periodo'][0]}
                    />
                </div>
            </div>
            <div className='row'>
                <div className="col-lg-6 col-sm-12">
                    <FileInput
                        label="Archivo"
                        name="archivo"
                        onChange={(files) => handleFileChange(files, 'archivo')} // Pasar el nombre
                        errors={errors['archivo']}
                        maxFiles={1}
                        multiple={false}
                        instantUpload={false}
                        maxSize={5 * 1024 * 1024}
                        fileUrl={isEdit && formData.archivo ? formData.archivo : null}
                        isEdit={isEdit}
                        disabled={isEdit}
                    />
                </div>
                <div className="col-lg-6 col-sm-12">
                    <FileInput
                        label="Imagen"
                        name="image"
                        onChange={(files) => handleFileChange(files, 'image')} // Pasar el nombre
                        errors={errors['image']}
                        maxFiles={1}
                        multiple={false}
                        instantUpload={false}
                        maxSize={5 * 1024 * 1024}
                        fileUrl={isEdit && formData.image ? formData.image : null}
                        isEdit={isEdit}
                        disabled={isEdit}
                    />
                </div>
            </div>
        </>
    );
};

export default FormContent;