import React, { useState, useEffect } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';

const SearchSelect = ({ apiUrl, endpoint, accessToken, label, name, formData, setFormData, errors }) => {
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [disableSearch, setDisableSearch] = useState(false); // Variable para controlar la búsqueda
    const error = errors?.[name];

    // Función para realizar la búsqueda en el backend
    const searchItems = async (query) => {
        if (!query || disableSearch) return; // Evitar consultas vacías o cuando la búsqueda está deshabilitada
        setLoading(true);
        try {
            const response = await axios.get(`${apiUrl}/${endpoint}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                },
                params: { query }
            });

            setSearchResults(response.data);
            setShowDropdown(true);
        } catch (error) {
            console.error('Error buscando items:', error);
            setSearchResults([]); // Limpiar resultados en caso de error
            setShowDropdown(false);
        } finally {
            setLoading(false);
        }
    };

    // Usar debounce para retrasar las solicitudes mientras el usuario escribe
    const debouncedSearch = debounce((value) => {
        if (value.length > 2) {
            searchItems(value);
        } else {
            setSearchResults([]); // Limpiar resultados si el valor es menor a 3 caracteres
            setShowDropdown(false);
        }
    }, 300);

    // Manejar el cambio en el input de búsqueda
    const handleSearchChange = (e) => {
        const { value } = e.target;

        // Si el input oculto tiene un valor, limpiamos ambos inputs
        if (formData[name]) {
            setFormData(prevState => ({
                ...prevState,
                [name]: '',               // Limpiar el ID oculto
                [`info_${name}`]: ''      // Limpiar el campo visible también
            }));
            setDisableSearch(false); // Habilitar la búsqueda al modificar el campo visible
        } else {
            // Si no hay valor en el input oculto, solo actualizamos el campo visible
            setFormData(prevState => ({
                ...prevState,
                [`info_${name}`]: value   // Actualizar el campo visible sin modificar el ID oculto
            }));
        }

        debouncedSearch(value);
    };

    // Función para seleccionar un item del dropdown
    const handleSelectItem = (item) => {
        setFormData({
            ...formData,
            [name]: item.id,              // Almacenar el ID real en el campo oculto
            [`info_${name}`]: item.label, // Mostrar la información en el input visible
        });
        setShowDropdown(false);
        setDisableSearch(true); // Deshabilitar la búsqueda después de la selección
    };

    return (
        <>
            <div className="form-group position-relative">
                <label htmlFor={name}>{label}</label>
                <input
                    value={formData[`info_${name}`] || ''}
                    onChange={handleSearchChange}
                    type='text'
                    className={`form-control ${error ? 'is-invalid' : ''}`}
                    id={`info_${name}`}
                    name={`info_${name}`}
                    placeholder={`Buscar ${label}`}
                    onFocus={() => setShowDropdown(!disableSearch)} // Mostrar dropdown solo si la búsqueda no está deshabilitada
                />
                {error && <div className="invalid-feedback">{error[0]}</div>}
                {/* Input oculto para almacenar el ID */}
                <input type="hidden" name={name} value={formData[name] || ''} />

                {showDropdown && searchResults.length > 0 && (
                    <ul className="dropdown-menu show" style={{ position: 'absolute', top: '100%', width: '100%', zIndex: 1000 }}>
                        {loading && <li className="dropdown-item">Cargando...</li>}
                        {searchResults.map((item) => (
                            <li key={item.id} className="dropdown-item" onClick={() => handleSelectItem(item)}>
                                {item.label}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </>
    );
};

export default SearchSelect;
