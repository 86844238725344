import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useApiContext } from '../layout/Template';
import Spinner from '../widget/Spinner';
import RubroRow from './RubroRow';  // Importa el nuevo componente
import userImage from '../../../assets/img/user_profile.png'; 
import { descargarPdf } from '../widget/DescargarPdf'; // Importa la función
import ToggleStatusButton from '../widget/ToggleStatusButton';

const Show = () => {
    const [loading, setLoading] = useState(true);
    const { apiUrl, accessToken } = useApiContext();
    const [matricula, setMatricula] = useState(null);
    const [user, setUser] = useState(null);
    const [curso, setCurso] = useState(null);
    const [rubros, setRubros] = useState([]);
    const [meses, setMeses] = useState([]);
    const [editIndex, setEditIndex] = useState(null);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const { id } = useParams();

    const getDatos = async () => {
        try {
            const response = await axios.get(`${apiUrl}/matricula/${id}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                }
            });
            const data = response.data;

            setMatricula(data.matricula);
            setCurso(data.curso);
            setUser(data.user);
            setRubros(data.rubros);
            setMeses(data.meses);
            setLoading(false);
        } catch (error) {
            console.error('Error al obtener el curso:', error.response ? error.response.data : error.message);
            setErrors({ fetch: 'Error al cargar la matrícula. Intente de nuevo más tarde.' });
            setLoading(false);
        }
    };

    useEffect(() => {
        getDatos();
    }, [apiUrl, accessToken, id]);

    const handleChange = (index, nuevoDescuento, nuevaFecha) => {
        const newRubros = [...rubros];
        newRubros[index].descuento = nuevoDescuento; // Actualiza el descuento
        newRubros[index].vencimiento = nuevaFecha; // Actualiza la fecha de vencimiento
        setRubros(newRubros); // Actualiza el estado
    };

    const handleEdit = (index) => {
        setEditIndex(index);
    };

    const handleSave = async (index) => {
        console.log(rubros[index]);
        try {
            const response = await axios.put(`${apiUrl}/actualizarRubro/${rubros[index].id}`, { rubro: rubros[index] }, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                }
            });
            setEditIndex(null);
            // Opcional: Mensaje de éxito
            window.showSuccessPopup(response.data.message);
        } catch (error) {
            console.error('Error al guardar los datos:', error.response ? error.response.data : error.message);
            setErrors({ save: 'Error al guardar los datos. Intente de nuevo.' });
        }
    };

    const handleCancel = () => {
        setEditIndex(null); // Restablecer el índice de edición
        getDatos();
    };

    const generarRubro = async () => {
        try {
            const response = await axios.get(`${apiUrl}/generarRubros/${id}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                }
            });
            window.showSuccessPopup(response.data.message);
            getDatos();
        } catch (error) {
            console.error('Error al guardar los datos:', error.response ? error.response.data : error.message);
            setErrors({ save: 'Error al guardar los datos. Intente de nuevo.' });
        }
    }
    

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className='py-3 px-3 content-wrapper'>
            <div className="card">
                <div className="card-body">
                    <div className="d-sm-flex justify-content-between align-items-start">
                        <div>
                            <h2 className="">Matricula Del Estudiante {matricula.estudiante.nombres}</h2>
                        </div>
                        <div>
                            <div className="btn-group" role="group" aria-label="Basic outlined example">
                                <a className="btn btn-outline-primary btn-sm dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="far fa-folder-open"></i> Documentación</a>
                                <ul className="dropdown-menu">
                                    <li><a onClick={ () => descargarPdf(`${apiUrl}/certificado/matricula/${id}`, `Certificado matricula ${matricula.estudiante.nombres}`, accessToken)} className="dropdown-item" href="#">Certificado Matricula</a></li>
                                    <li><a onClick={ () => descargarPdf(`${apiUrl}/certificado/asistencia/${id}`, `Certificado asistencia ${matricula.estudiante.nombres}`, accessToken)} className="dropdown-item">Certificado Asistencia</a></li>
                                    <li><a onClick={ () => descargarPdf(`${apiUrl}/certificado/conducta/${id}`, `Certificado conducta ${matricula.estudiante.nombres}`, accessToken)} className="dropdown-item">Certificado Conducta</a></li>
                                    <li><a onClick={ () => descargarPdf(`${apiUrl}/certificado/deuda/${id}`, `Certificado no adeuda ${matricula.estudiante.nombres}`, accessToken)} className="dropdown-item">Certificado No Adeuda</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-ld-4 col-md-4'>
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex flex-column align-items-center text-center">
                                <img src={userImage} className="rounded-circle" width="150" />
                                <div className="mt-3">
                                    <h4>{matricula.estudiante.nombres}</h4>
                                    <p className="text-muted font-size-sm">{matricula.estudiante.identificacion}</p>
                                </div>
                                {user && (
                                    <>
                                        <button onClick={null} className="btn btn-info my-2 text-dark fs-6 btn-sm">Reestablecer Contraseña <i className="fas fa-key"></i></button>
                                        <button onClick={null} className="btn btn-danger my-2 text-dark fs-6 btn-sm">Inactivar Usuario <i className="fas fa-power-off"></i></button>
                                    </>
                                )}
                            </div>
                            <div className='row text-center'>
                                <div className='col-4'>
                                    <p>Estado:</p>
                                    {/* Usa ToggleStatusButton para manejar el estado */}
                                    <ToggleStatusButton
                                        endpoint={`${apiUrl}/matricula/status/${id}`}  // Endpoint API de la matrícula
                                        currentStatus={matricula.estado}  // Estado actual
                                        accessToken={accessToken}  // Token de acceso
                                        onStatusChange={(newStatus) => setMatricula({ ...matricula, estado: newStatus })}  // Actualización del estado
                                    />
                                </div>
                                <div className='col-4'>
                                    <p>Becado:</p>
                                    {/* Usa ToggleStatusButton para manejar el becado */}
                                    <ToggleStatusButton
                                        endpoint={`${apiUrl}/matricula/becado/${id}`}  // Endpoint API de la matrícula
                                        currentStatus={matricula.becado}  // becado actual
                                        accessToken={accessToken}  // Token de acceso
                                        onStatusChange={(newStatus) => setMatricula({ ...matricula, becado: newStatus })}  // Actualización del becado
                                    />
                                </div>
                                <div className='col-4'>
                                    <p>Descuento:</p>
                                    <ToggleStatusButton
                                        endpoint={`${apiUrl}/matricula/descuento/${id}`}
                                        currentStatus={matricula.descuento}
                                        accessToken={accessToken}
                                        onStatusChange={(newStatus) => setMatricula({ ...matricula, descuento: newStatus })}
                                    />
                                </div>
                            </div>

                            {/* Textarea para la descripción de la beca */}
                            <div className="mt-3">
                                <div className="mb-3 form-floating">
                                    <textarea
                                        className="form-control"
                                        id="descripcion_beca"
                                        name="descripcion_beca"
                                        maxLength="250"
                                        style={{ height: 'calc(6.5rem + 2px)' }} // Corregido
                                        value={matricula.descripcion_beca || ''}
                                        onChange={(e) => setMatricula({ ...matricula, descripcion_beca: e.target.value })}
                                    />
                                    <label htmlFor="descripcion_beca">Descripción Beca:</label>
                                </div>
                            </div>

                            {/* Botón para guardar la descripción de la beca */}
                            <div className="mt-2">
                                <button
                                    className="btn btn-primary"
                                    onClick={async () => {
                                        try {
                                            const response = await axios.put(
                                                `${apiUrl}/matricula/descripcion/${id}`,
                                                { descripcion_beca: matricula.descripcion_beca },
                                                {
                                                    headers: {
                                                        'Authorization': `Bearer ${accessToken}`,
                                                        'Accept': 'application/json',
                                                    },
                                                }
                                            );
                                            window.showSuccessPopup(response.data.message);
                                        } catch (error) {
                                            console.error('Error al actualizar la descripción de la beca:', error);
                                            setErrors({ save: 'Error al actualizar la descripción. Intente de nuevo.' });
                                        }
                                    }}
                                >
                                    Guardar Descripción
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-ld-8 col-md-8'>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-3">
                                    <h6 className="mb-0">Nombres</h6>
                                </div>
                                <div className="col-sm-9 text-secondary">
                                    {matricula.estudiante.nombres}
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-sm-3">
                                    <h6 className="mb-0">Identificación</h6>
                                </div>
                                <div className="col-sm-9 text-secondary">
                                    {matricula.estudiante.identificacion}
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-sm-3">
                                    <h6 className="mb-0">Representante</h6>
                                </div>
                                <div className="col-sm-9 text-secondary">
                                    {matricula.estudiante.representante.nombres}
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-sm-3">
                                    <h6 className="mb-0">Correo</h6>
                                </div>
                                <div className="col-sm-9 text-secondary">
                                    {matricula.estudiante.correo}
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-sm-3">
                                    <h6 className="mb-0">Celular</h6>
                                </div>
                                <div className="col-sm-9 text-secondary">
                                    {matricula.estudiante.celular}
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-sm-3">
                                    <h6 className="mb-0">Telefono</h6>
                                </div>
                                <div className="col-sm-9 text-secondary">
                                    {matricula.estudiante.telefono}
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-sm-3">
                                    <h6 className="mb-0">Direccion</h6>
                                </div>
                                <div className="col-sm-9 text-secondary">
                                    {matricula.estudiante.direccion}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='stretch-card mt-3'>
                <div className="card">
                    <div className="card-body table-responsive">
                        <h2><i className='fas fa-history'></i> Registro de Pagos</h2>
                        {errors.fetch && <div className="alert alert-danger">{errors.fetch}</div>}
                        {errors.save && <div className="alert alert-danger">{errors.save}</div>}
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Tipo</th>
                                    <th>Concepto</th>
                                    <th>Vencimiento</th>
                                    <th>Facturado</th>
                                    <th>Valor</th>
                                    <th>($) Descuento</th>
                                    <th>% Descuento</th>
                                    <th>Valor Total</th>
                                    <th>Monto Pagado</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* Verificar si existe al menos un rubro con tipo === 1 */}
                                {!rubros.some(rubro => rubro.tipo === 1) && (
                                    <tr>
                                        <td>Matricula</td>
                                        <td colSpan="7" className="text-center">
                                            No generado <Link onClick={generarRubro}><i className="fas fa-sign-in-alt"></i> Generar</Link>
                                        </td>
                                    </tr>
                                )}
                                {rubros.map((rubro, index) => {
                                    if (rubro.tipo === 1) {
                                        const valor_descuento = (rubro.descuento / 100) * curso.matricula;
                                        return (
                                            <RubroRow
                                                key={rubro.id}
                                                valor={curso.matricula}
                                                valDescuento={valor_descuento ?? 0} 
                                                rubro={rubro}
                                                index={index}
                                                editIndex={editIndex}
                                                handleEdit={handleEdit}
                                                handleSave={handleSave}
                                                handleChange={handleChange}
                                                handleCancel={handleCancel}
                                            />
                                        );
                                    }
                                    if (rubro.mes) {
                                        const rubroRelacionado = rubros.find(r => r.mes === rubro.mes);
                                        const valor_descuento = (rubro.descuento / 100) * curso.cuota;
                                        return rubroRelacionado ? (
                                            <RubroRow
                                                key={rubroRelacionado.id}
                                                valor={curso.cuota}
                                                valDescuento={valor_descuento ?? 0} 
                                                rubro={rubroRelacionado}
                                                index={index}
                                                editIndex={editIndex}
                                                handleEdit={handleEdit}
                                                handleSave={handleSave}
                                                handleChange={handleChange}
                                                handleCancel={handleCancel}
                                            />
                                        ) : (
                                            <tr key={`no-generado-${index}`}>
                                                <td>{rubro.mes}</td>
                                                <td colSpan="4">No generado</td>
                                            </tr>
                                        );
                                    }
                                    if (rubro.tipo === 3 && !rubro.mes) {
                                        const valor_descuento = (rubro.descuento / 100) * rubro.monto_total;
                                        return (
                                            <RubroRow
                                                key={rubro.id}
                                                valor={rubro.monto_total}
                                                rubro={rubro}
                                                valDescuento={valor_descuento ?? 0} 
                                                index={index}
                                                editIndex={editIndex}
                                                handleEdit={handleEdit}
                                                handleSave={handleSave}
                                                handleChange={handleChange}
                                                handleCancel={handleCancel}
                                            />
                                        );
                                    }
                                    return null;
                                })}
                                {meses.map((mes, indexMes) => {
                                    const rubroParaMes = rubros.find(r => r.mes === mes.nombre);
                                    if (!rubroParaMes) {
                                        return (
                                            <tr key={`mes-no-generado-${indexMes}`}>
                                                <td>{mes.nombre}</td>
                                                <td colSpan="7" className='text-center'>No generado <Link onClick={generarRubro}><i className='fas fa-sign-in-alt'></i> Genetar</Link></td>
                                            </tr>
                                        );
                                    }
                                    return null;
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Show;
