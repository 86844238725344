import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import './assets/recursos/fontawesome/css/all.min.css';
import './assets/css/template/style.css';
import 'moment/locale/es';
import './assets/js/template.js';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'react-toastify/dist/ReactToastify.css';

// Cambiar el título del documento
document.title = 'EdusnapEc';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// Registrar el Service Worker directamente
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register('/service-worker.js') // Asegúrate de usar la ruta correcta
        .then((registration) => {
          console.log('Service Worker registrado con éxito:', registration);
        })
        .catch((error) => {
          console.error('Error al registrar el Service Worker:', error);
        });
    });
  }

// Si quieres medir el rendimiento de tu aplicación
//reportWebVitals();