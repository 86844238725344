import axios from 'axios'
import React, {useState, useEffect} from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useApiContext  } from '../layout/Template';
import Spinner from '../widget/Spinner';
import DatosGeneralesContext from '../widget/DatosGeneralesContext';
import DatosRepresentante from './DatosRepresentante';
import DatosFacturacion from './DatosFacturacion';
import DatosTipo from './DatosTipo';


const Create = () => {
    const { apiUrl, accessToken } = useApiContext();
    const [formData, setFormData] = useState({
        isCliente: false,
        isProveedor: false,
        representante: {
            identificacion: '',
            extranjero: false,
            nombre: '',
            correo: '',
            celular: '',
            telefono: '',
            direccion: '',
            sexo: '',
        },
        facturacion: {
            consumidor_final: 0,
            tipo_persona: '01',
            tipo_identificacion: '',
            contribuyente_especial: false,
            extranjero: false,
            ruc_ci: '',
            razon_social: '',
            telefono_fact: '',
            correo_fact: '',
            direccion_comprador: '',
            id_provincia: '',
            id_canton: ''
        }
    });

    const { cantones, provincias, loading } = DatosGeneralesContext(apiUrl);

    const [errors, setErrors] = useState({}); // Estado para errores de validación
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        // Verificar que al menos uno de los campos esté seleccionado
        if (!formData.isCliente && !formData.isProveedor) {
            // Establecer un mensaje de error si ninguno está seleccionado
            window.showErrorPopup("Debe seleccionar al menos uno: Cliente o Proveedor.");
            return;
        }
        console.log('Datos enviados:', formData); // Agrega esta línea para depurar
        try {
            const response = await axios.post(`${apiUrl}/personas`, formData, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            });
            console.log('Persona creado exitosamente:', response.data);
            navigate('/personas');  // Redirigir a la lista de personas
            window.showSuccessPopup(response.data.message);
        } catch (error) {
            if (error.response) {
                // Manejar errores de validación
                setErrors(error.response.data.errors);
            } else {
                console.error('Error al crear el periodo:', error.response ? error.response.data : error.message);
            }
        }
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className='py-3 px-3 content-wrapper'>
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2 className="">Crear Persona</h2>
                </div>
            </div>
            
            <form onSubmit={handleSubmit}>
                <div className='row'>
                    <DatosTipo
                        formData={formData}
                        setFormData={setFormData}
                    />
                    <DatosRepresentante
                        formData={formData}
                        setFormData={setFormData}
                        errors={errors}
                    />
                    <DatosFacturacion
                        formData={formData}
                        setFormData={setFormData}
                        provincias={provincias}
                        cantones={cantones}
                        errors={errors}
                    />
                </div>
                <button type="submit" className="btn btn-primary me-2"><i className="fas fa-save"></i> Crear Persona</button>
                <Link className="btn btn-warning" to='/personas'>Volver</Link>
            </form>
        </div>
    );
};

export default Create;
