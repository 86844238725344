import React from 'react';

const Test = () => {
    return (
        <div className="container-scroller">
            Esta sección esta en desarrollo..
        </div>
    );
};

export default Test;
