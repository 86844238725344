// src/routes/AnalisisPensionesRoutes.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from '../componentes/auth/ProtectedRoute';
import Template from '../componentes/client/layout/Template';

// analisispensiones
import Index from '../componentes/client/analisispensiones/Index';

const AnalisisPensionesRoutes = () => {
    return (
        <Routes>
            <Route
                path=""
                element={
                    <ProtectedRoute>
                        <Template>
                            <Index />
                        </Template>
                    </ProtectedRoute>
                }
            />
        </Routes>
    );
};

export default AnalisisPensionesRoutes;
