import axios from 'axios'
import React, {useState, useEffect} from 'react'
import { useNavigate, useParams , Link } from 'react-router-dom'
import { useApiContext  } from '../layout/Template';
import Spinner from '../widget/Spinner';
import FormContent from './FormContent';

const Show = () => {
    const [loading, setLoading] = useState(true);
    const { apiUrl, accessToken, user } = useApiContext();
    const [formData, setFormData] = useState({
        titulo: '',
        descripcion: '',
        url: '',
        destinatario: '',
        id_periodo: '',
        archivo: '',
        image: '',
    });
    const [errors, setErrors] = useState({}); // Estado para errores de validación
    const navigate = useNavigate();
    const {id} = useParams();
    useEffect(() => {
        const getComunicado = async () => {
            try {
                const response = await axios.get(`${apiUrl}/comunicados/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Accept': 'application/json',
                    }
                });
                // Convertir los datos del backend al formato esperado
                const { comunicado, titulo, descripcion, url, destinatario, id_periodo, archivo, image } = response.data;
                setFormData({
                    titulo: titulo,
                    descripcion: descripcion,
                    url: url,
                    destinatario: destinatario,
                    id_periodo: id_periodo,
                    archivo: archivo,
                    image: image
                });
                console.log(response.data.image)
                setLoading(false);
            } catch (error) {
                console.error('Error al obtener el comunicaddo:', error.response ? error.response.data : error.message);
                setLoading(false); // Desactivar el cargador en caso de error
            }
        };
        getComunicado();
    }, [apiUrl, accessToken, id]);

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className='py-3 px-3 content-wrapper'>
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2 className="">Consultar Comunicado</h2>
                </div>
            </div>
            <div className="offset-lg-2 col-lg-8 offset-md-1 col-md-10 col-sm-12 grid-margin stretch-card mt-3">
                <div className="card">
                    <div className="card-body">
                        <form className="forms-sample">
                            <FormContent
                                formData={formData}
                                setFormData={setFormData}
                                errors={errors}
                                isEdit={true}
                            />
                            {user.id_rol && (
                                <Link className="btn btn-warning" to='/comunicados'>Volver</Link>
                            )}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Show;
