import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
//import { SpeedInsights } from "@vercel/speed-insights/react";
//import { Analytics } from "@vercel/analytics/react"
import Acceder from './componentes/auth/Acceder';
import Login from './componentes/auth/Login';
import Reset from './componentes/auth/Reset';
import CngPwd from './componentes/auth/CngPwd';
import ProtectedRoute from './componentes/auth/ProtectedRoute';

import SessionExist from './componentes/auth/SessionExist';
import Template from './componentes/client/layout/Template';

import ConfigRoutes from './routes/ConfigRoutes';

import Dashboard from './componentes/client/Dashboard';
import Test from './componentes/client/Test';

import BoletinesRoutes from './routes/BoletinesRoutes';
import ComportamientoRoutes from './routes/ComportamientoRoutes';
import NotaMateriaRoutes from './routes/NotaMateriaRoutes';
import AsistenciaCursoRoutes from './routes/AsistenciaCursoRoutes';
import AsistenciaMateriaRoutes from './routes/AsistenciaMateriaRoutes';

import CursosRoutes from './routes/CursosRoutes';
import MisMateriasRoutes from './routes/MisMateriasRoutes';
import ContenidoRoutes from './routes/ContenidoRoutes';
import ClasesRoutes from './routes/ClasesRoutes';
import ActividadesRoutes from './routes/ActividadesRoutes';

import PeriodosRoutes from './routes/PeriodosRoutes';
import NivelesRoutes from './routes/NivelesRoutes';
import ModalidadesRoutes from './routes/ModalidadesRoutes';
import EspecialidadesRoutes from './routes/EspecialidadesRoutes';
import JornadasRoutes from './routes/JornadasRoutes';
import SeccionesRoutes from './routes/SeccionesRoutes';
import TipoCursoRoutes from './routes/TipoCursoRoutes';
import CtaBancariaRoutes from './routes/CtaBancariaRoutes';
import CajasRoutes from './routes/CajasRoutes';
import AcademicoRoutes from './routes/AcademicoRoutes';
import ConfFactRoutes from './routes/ConfFactRoutes';
import FlujoCajaRoutes from './routes/FlujoCajaRoutes';
import RepMovimientosRoutes from './routes/RepMovimientosRoutes';
import AnalisisPensionesRoutes from './routes/AnalisisPensionesRoutes';
import ComunicadosRoutes from './routes/ComunicadosRoutes';

import MateriasRoutes from './routes/MateriasRoutes';
import MateriaCursosRoutes from './routes/MateriaCursosRoutes';
import MatriculaRoutes from './routes/MatriculaRoutes';

import PersonasRoutes from './routes/PersonasRoutes';
import EstudiantesRoutes from './routes/EstudiantesRoutes';
import DocentesRoutes from './routes/DocentesRoutes';

import MovimientosRoutes from './routes/MovimientosRoutes';
import FacturacionV1Routes from './routes/FacturacionV1Routes';

import axios from 'axios';

// Función para obtener el primer segmento del pathname
export const obtenerCliente = () => {
    const hostname = window.location.hostname;
    const subdominio = hostname.split('.')[0];
    return subdominio;
};

function App() {
    const subdominio = obtenerCliente();
    const [logoCli, setLogoCli] = useState(null); // Utilizamos useState para manejar el logo del cliente

    useEffect(() => {
        const obtenerInfoCliente = async () => {
            if (subdominio && subdominio !== 'admin') {
                try {
                    const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
                    const tenantEndpoint = `${apiBaseURL}/verifyTenant`;

                    const tenantResponse = await axios.post(tenantEndpoint, { subdominio });
                    if (tenantResponse.data.valido === false && subdominio !== 'admin') {
                        // Redirección a la página de acceso si el subdominio no es válido
                        const baseUrl = process.env.REACT_APP_BASE_URL;
                        window.location.href = `http://admin.${baseUrl}`;

                    }

                    const tenantUrl = process.env.REACT_APP_API_TENANT_BASE_URL.replace('tenant', subdominio);
                    const tenantInfoEndpoint = `${tenantUrl}/infoCliente`;

                    const infoClienteResponse = await axios.get(tenantInfoEndpoint);
                    console.log('Respuesta de infoCliente:', infoClienteResponse.data);

                    // Asignamos el logo del cliente al estado logoCli si existe
                    if (infoClienteResponse.data.escuela && infoClienteResponse.data.logoUrl) {
                        setLogoCli(infoClienteResponse.data.logoUrl);
                    }
                } catch (error) {
                    console.error('Error al obtener información del tenant:', error);
                }
            }
        };

        obtenerInfoCliente();
    }, [subdominio]); // Ejecutar useEffect nuevamente si subdominio cambia

    return (
        <BrowserRouter>
            <Routes>
                
                <Route path="/Test" element={<Template><Test /></Template>} />
                
                {/*<Route path="/:subdominio/Acceder" element={<Acceder subdominio={subdominio} />} />*/}
                <Route path="/" element={<Acceder />} />
                <Route path="/Login" element={<SessionExist element={Login} logoCli={logoCli} />} />
                <Route path="/Reset" element={<Reset logoCli={logoCli} />} />
                <Route path="/CngPwd" element={<CngPwd logoCli={logoCli} />} />
                
                <Route path="/config/*" element={<ConfigRoutes />} />

                {/* RUTAS PRIVADAS DASHBOARD */}
                <Route
                path="/Dashboard"
                element={
                    <ProtectedRoute>
                    <Template>
                        <Dashboard />
                    </Template>
                    </ProtectedRoute>
                }
                />

                <Route path="/Boletin/*" element={<BoletinesRoutes />} />
                <Route path="/MisCursos/Comportamiento/*" element={<ComportamientoRoutes />} />
                <Route path="/Materia/*" element={<NotaMateriaRoutes />} />
                <Route path="/MisCursos/Asistencia/*" element={<AsistenciaCursoRoutes />} />
                <Route path="/Materias/Asistencia/*" element={<AsistenciaMateriaRoutes />} />

                <Route path="/*" element={<CursosRoutes />} />
                <Route path="/MisMaterias/*" element={<MisMateriasRoutes />} />
                <Route path="/Materias/Contenido/*" element={<ContenidoRoutes />} />
                <Route path="/Materias/Contenido/Clase/*" element={<ClasesRoutes />} />
                <Route path="/Materias/Contenido/Actividades/*" element={<ActividadesRoutes />} />

                <Route path="/periodos/*" element={<PeriodosRoutes />} />
                <Route path="/niveles/*" element={<NivelesRoutes />} />
                <Route path="/modalidades/*" element={<ModalidadesRoutes />} />
                <Route path="/especialidades/*" element={<EspecialidadesRoutes />} />
                <Route path="/jornadas/*" element={<JornadasRoutes />} />
                <Route path="/secciones/*" element={<SeccionesRoutes />} />
                <Route path="/tipocurso/*" element={<TipoCursoRoutes />} />
                <Route path="/ctaBancaria/*" element={<CtaBancariaRoutes />} />
                <Route path="/cajas/*" element={<CajasRoutes />} />
                <Route path="/academico/*" element={<AcademicoRoutes />} />
                <Route path="/confFact/*" element={<ConfFactRoutes />} />
                <Route path="/flujocaja/*" element={<FlujoCajaRoutes />} />
                <Route path="/repmovimientos/*" element={<RepMovimientosRoutes />} />
                <Route path="/analisispensiones/*" element={<AnalisisPensionesRoutes />} />
                <Route path="/comunicados/*" element={<ComunicadosRoutes />} />
                
                <Route path="/materias/*" element={<MateriasRoutes />} />
                <Route path="/MisCursos/Materias/*" element={<MateriaCursosRoutes />} />
                <Route path="/MisCursos/Matricula/*" element={<MatriculaRoutes />} />

                <Route path="/personas/*" element={<PersonasRoutes />} />
                <Route path="/estudiantes/*" element={<EstudiantesRoutes />} />
                <Route path="/docentes/*" element={<DocentesRoutes />} />

                {/*
                <Route path="/Dashboard" element={<ProtectedRoute element={Dashboard} />} />
                <Route path="/Dashboard" element={<Template><Dashboard /></Template>} />
                */}

                <Route path="/movimientos/*" element={<MovimientosRoutes />} />
                <Route path="/facturacionv1/*" element={<FacturacionV1Routes />} />
            </Routes>
            {/*
            <SpeedInsights />
            <Analytics/>
            */}
        </BrowserRouter>
    );
}

export default App;
