import React from 'react';

const SimpleFileUpload = ({ label, divClass = true, name, accept, handleFileChange, fileUrl, buttonText = "Ver Archivo" }) => {
    return (
        <div className={divClass ? "form-group" : ""}>
            <label htmlFor={name}>{label}</label>
            <div className="input-group mb-3">
                <input
                    type="file"
                    name={name}
                    accept={accept}
                    onChange={handleFileChange} // Se espera que esto maneje el cambio correctamente
                    className="form-control"
                />
                {fileUrl && (
                    <div className="input-group-prepend">
                        <a
                            href="#!"
                            onClick={() => window.open(fileUrl, '_blank')}
                            className="input-group-text btn btn-primary text-decoration-none text-light"
                            id="basic-addon1"
                        >
                            {buttonText}
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SimpleFileUpload;
