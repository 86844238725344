import React from 'react';
import TextInput from '../../form/InputText';

const FormContent = ({ formData, setFormData, errors }) => {
    // Definir handleInputChange dentro del componente
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <>
            <div className="mb-3">
                <TextInput
                    label="Nombre"
                    name="nombre"
                    value={formData.nombre}
                    onChange={handleInputChange}
                    placeholder="Nombre"
                    errors={errors}
                />
            </div>
            <div className="mb-3">
                <TextInput
                    label="Codigo"
                    name="codigo"
                    value={formData.codigo}
                    onChange={handleInputChange}
                    placeholder="Codigo"
                    errors={errors}
                    validacion={'alfanumerico'}
                />
            </div>
        </>
    );
};

export default FormContent;