import axios from 'axios'
import React, {useEffect, useState} from 'react'
import TextInput from '../../form/InputText';
import QuillEditor from '../../form/QuillEditor';
import FileInput from '../../form/FileInput';
import DateInput from '../../form/DateInput';
import DateTimeInput from '../../form/DateTimeInput';

const FormContent = ({ formData, setFormData, isEdit = false, errors }) => {
    
    // Definir handleInputChange dentro del componente
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleFileChange = (fileItems, name) => {
        // Extraer el archivo de fileItems y establecer en el estado
        const file = fileItems.length > 0 ? fileItems[0].file : null;
        setFormData((prevState) => ({
            ...prevState,
            [name]: file, // Usa el nombre del input para actualizar el estado
        }));
    };

    return (
        <>
            <TextInput
                name="titulo"
                value={formData.titulo}
                onChange={handleInputChange}
                placeholder="Titulo"
                errors={errors}
            />
            <QuillEditor
                name="descripcion"
                id="editor"
                value={formData.descripcion}
                onChange={handleInputChange}
                errors={errors}
            />
            <div className='row'>
                <div className="col-lg-8 col-sm-12 mb-3">
                    <TextInput
                        type="url"
                        name="url"
                        value={formData.url}
                        onChange={handleInputChange}
                        placeholder="URL"
                        errors={errors}
                    />
                </div>
                <div className="col-lg-4 col-sm-12 mb-3">
                    <DateInput
                        label="Fecha"
                        name="fecha"
                        value={formData.fecha}
                        onChange={handleInputChange}
                        placeholder="Fecha"
                        isDateTime={true}
                        errors={errors}
                    />
                </div>
            </div>
            <FileInput
                label="Archivo"
                name="archivo"
                onChange={(files) => handleFileChange(files, 'archivo')} // Pasar el nombre
                errors={errors['archivo']}
                maxFiles={1}
                multiple={false}
                instantUpload={false}
                maxSize={5 * 1024 * 1024}
                fileUrl={isEdit && formData.archivo ? formData.archivo : null}
                isEdit={isEdit}
            />
        </>
    );
};

export default FormContent;