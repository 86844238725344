import React, { useEffect, useState } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginPdfPreview from 'filepond-plugin-pdf-preview';
import FilePondPluginGetFile from 'filepond-plugin-get-file';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css';

// Registrar los plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginPdfPreview, FilePondPluginGetFile);

const FileInput = ({
  label,
  name,
  onChange,
  errors,
  maxFiles = 1,
  multiple = false,
  instantUpload = false,
  maxSize = null,
  fileUrl = null,
  disabled = false,
  readOnly = false, 
}) => {
  const [files, setFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  
    // Función para obtener el archivo desde la URL
  const fetchFileFromUrl = async (signedUrl, unsignedUrl) => {
    // Verificar si ambas URLs son cadenas y no están vacías
    if ((typeof signedUrl !== 'string' || signedUrl.trim() === '') && (typeof unsignedUrl !== 'string' || unsignedUrl.trim() === '')) {
      throw new TypeError('Se deben proporcionar cadenas válidas para ambas URLs.');
    }

    try {
      let urlToFetch = signedUrl; // Intentar primero con la URL firmada
      // Intentar obtener el archivo desde la URL firmada
      let response = await fetch(urlToFetch);
      // Si la respuesta no es exitosa, intentar con la URL sin firmar
      if (!response.ok) {
        console.warn(`Error al obtener el archivo desde la URL firmada: ${response.status}. Intentando con la URL sin firmar...`);
        urlToFetch = unsignedUrl; // Cambiar a la URL sin firmar
        response = await fetch(urlToFetch);
        
        // Verificar nuevamente si la respuesta es exitosa
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      }
      const blob = await response.blob();
      const fileName = urlToFetch.split('/').pop().split('?')[0]; // Extraer nombre del archivo
      const file = new File([blob], fileName, { type: blob.type }); // Crear un archivo válido
      return file;
    } catch (error) {
      console.error('Error al obtener el archivo:', error);
      throw error; // Relanza el error para manejarlo en el useEffect
    }
  };

  useEffect(() => {
    if (fileUrl) {
      // Verificar si fileUrl es una cadena o un objeto File
      if (typeof fileUrl === 'string') {
        console.log('URL del archivo:', fileUrl); // Verificar la URL
        fetchFileFromUrl(fileUrl)
          .then(file => {
            const fileItem = [{
              source: URL.createObjectURL(file), // Crear una URL temporal para la vista previa
              options: {
                type: 'local',
                file: file, // Archivo válido
              }
            }];
            setFiles(fileItem); // Setear los archivos en el estado
          })
          .catch(error => {
            console.error('Error al obtener el archivo:', error.message);
            setErrorMessage(`No se pudo obtener el archivo: ${error.message}`);
          });
      } else if (fileUrl instanceof File) {
        // Si fileUrl es un objeto File, crear una URL temporal
        const fileItem = [{
          source: URL.createObjectURL(fileUrl), // Crear una URL temporal para la vista previa
          options: {
            type: 'local',
            file: fileUrl, // Archivo válido
          }
        }];
        setFiles(fileItem); // Setear los archivos en el estado
      } else {
        console.error('fileUrl no es una cadena válida:', fileUrl); // Mensaje de error
      }
    }
  
    return () => {
      files.forEach(file => {
        URL.revokeObjectURL(file.source); // Revocar la URL temporal
      });
    };
  }, [fileUrl]);
  
  

  const handleUpdateFiles = (fileItems) => {
    let validFiles = [];
    let exceededFiles = [];

    fileItems.forEach(fileItem => {
      if (fileItem.fileSize <= maxSize) {
        validFiles.push(fileItem);
      } else {
        exceededFiles.push(fileItem);
      }
    });

    if (exceededFiles.length > 0) {
      const maxSizeMB = maxSize / 1024 / 1024; // Convertir a MB
      window.showErrorPopup(`Los siguientes archivos superan el tamaño máximo de ${maxSizeMB} MB: ${exceededFiles.map(file => file.file.name).join(', ')}`);
      setErrorMessage(`Los siguientes archivos superan el tamaño máximo de ${maxSizeMB} MB: ${exceededFiles.map(file => file.file.name).join(', ')}`);
      setTimeout(() => {
        setErrorMessage(''); // Limpiar el mensaje después de un tiempo
      }, 800);
    }

    setFiles(validFiles);
    onChange(validFiles); // Llama al callback con los archivos válidos
  };

  const handleActivateFile = (file) => {
    const fileURL = URL.createObjectURL(file.file);
    window.open(fileURL, '_blank');
  
    // Obtener la extensión a partir del nombre del archivo
    const fileExtension = file.file.name.split('.').pop().toLowerCase(); 
    console.log('Extensión del archivo:', fileExtension);
  };

  return (
    <div className="mb-3">
      <label htmlFor={name}>{label}</label>
      <FilePond
        name={name}
        files={files}
        onupdatefiles={readOnly ? () => {} : handleUpdateFiles}
        allowMultiple={multiple}
        maxFiles={maxFiles}
        instantUpload={instantUpload}
        maxFileSize={maxSize}
        labelIdle='Arrastra y suelta tus archivos o <span class="filepond--label-action">explora en tus directorios</span>'
        allowRemove={!disabled}
        onactivatefile={handleActivateFile} // Manejar el clic en el archivo
      />
      {errors && <div className="text-danger">{errors[0]}</div>}
      {errorMessage && <div className="text-danger">{errorMessage}</div>}
      
      {/* Cambiar el color del texto donde se muestran las propiedades del archivo */}
      <style jsx>{`
        .filepond--download-icon {
          height: 25px !important;
          width: 30px !important;
          mask-size: 102%;
          background-color: green;
        }
      `}</style>
    </div>
  );
};

export default FileInput;
