import React from 'react';
import TextInput from '../../form/InputText';
import SelectInput from '../../form//SelectInput';
import { useApiContext } from '../layout/Template';

const FormContent = ({ formData, setFormData, handleFileChange, errors }) => {
    const { user } = useApiContext();

    // Definir handleInputChange dentro del componente
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <>
        <div className='row'>
            {user && user.superuser === 1 && (
                <div className='col-lg-4'>
                    <SelectInput
                        id="ambiente"
                        name="ambiente"
                        value={formData.ambiente}
                        onChange={handleInputChange}
                        placeholder="Ambiente"
                        options={[
                            { value: '1', label: 'Pruebas' },
                            { value: '2', label: 'Producción' }
                        ]}
                        error={errors['ambiente'] && errors['ambiente'][0]}
                    />
                </div>
            )}
            <div className='col-lg-4'>
                <SelectInput
                    id="obligadoContabilidad"
                    name="obligadoContabilidad"
                    value={formData.obligadoContabilidad}
                    onChange={handleInputChange}
                    placeholder="Obligaddo a llevar contabiliadd"
                    options={[
                        { value: 'SI', label: 'SI' },
                        { value: 'NO', label: 'NO' }
                    ]}
                    error={errors['obligadoContabilidad'] && errors['obligadoContabilidad'][0]}
                />
            </div>
            <div className='col-lg-4'>
                <SelectInput
                    id="rimpe"
                    name="rimpe"
                    value={formData.rimpe}
                    onChange={handleInputChange}
                    placeholder="Regimen Rimpe"
                    options={[
                        { value: '1', label: 'SI' },
                        { value: '0', label: 'NO' },
                    ]}
                    error={errors['rimpe'] && errors['rimpe'][0]}
                />
            </div>
        </div>
            <TextInput
                label="Razón Social"
                name="razonSocial"
                value={formData.razonSocial}
                onChange={handleInputChange}
                placeholder="Razón Social"
                errors={errors}
            />
            <TextInput
                label="Nombre Comercial"
                name="nombreComercial"
                value={formData.nombreComercial}
                onChange={handleInputChange}
                placeholder="Nombre Comercial"
                errors={errors}
            />
            <div className='row'>
                <div className='col-lg-6'>
                    <TextInput
                        name="ruc"
                        value={formData.ruc}
                        validacion={'entero'}
                        onChange={handleInputChange}
                        placeholder="RUC"
                        errors={errors}
                    />
                </div>
                <div className='col-lg-6'>
                    <TextInput
                        name="estab"
                        value={formData.estab}
                        onChange={handleInputChange}
                        placeholder="Establecimientos: (Formato válido: 001,002,003 etc.)"
                        errors={errors}
                    />
                </div>
            </div>
            <TextInput
                name="dirMatriz"
                value={formData.dirMatriz}
                onChange={handleInputChange}
                placeholder="Dirección Matriz"
                errors={errors}
            />
            <div className='row'>
                <div className='col-lg-6'>
                    <TextInput
                        name="correo"
                        value={formData.correo}
                        onChange={handleInputChange}
                        placeholder="Correo"
                        errors={errors}
                    />
                </div>
                <div className='col-lg-6'>
                    <TextInput
                        name="telefono"
                        value={formData.telefono}
                        onChange={handleInputChange}
                        placeholder="Telefono"
                        errors={errors}
                    />
                </div>
            </div>
            {/* Input para la carga de archivo del logo */}
            <div className='col-lg-12'>
                <div className="input-group mb-3">
                    <input
                        type="file"
                        name="logo"
                        accept="image/png, image/jpeg"
                        onChange={handleFileChange}
                        className="form-control"
                    />
                    {formData.logo && (
                        <div className="input-group-prepend">
                            <a href="#!" onClick={() => window.open(formData.logo, '_blank')} className="input-group-text btn btn-primary text-decoration-none text-light" id="basic-addon1">Ver Logo</a>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default FormContent;
