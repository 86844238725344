// src/componentes/client/config/Index.js
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useApiContext } from '../layout/Template';
import Spinner from '../widget/Spinner';
import DatosGeneralesContext from '../widget/DatosGeneralesContext';
import ConfigNavTab from './ConfigNavTab';
import FormContent from './FormContent';

const Index = ({ isActive }) => {
    const [loading, setLoading] = useState(true);
    const { apiUrl, accessToken } = useApiContext();
    const [formData, setFormData] = useState({
        nombre: '',
        direccion: '',
        sucursal: '',
        correo: '',
        telefono: '',
        codigo: '',
        id_tipo: '',
        logo: '',
        rector: '',
        rector_identificacion: '',
        cargo_rector: '',
        firma_rector: '',
        vicerector: '',
        vicerector_identificacion: '',
        secretario: '',
        secretario_identificacion: '',
        resoreria: '',
        resoreria_identificacion: '',
        gerente: '',
        gerente_identificacion: '',
        gerente_cargo: '',
        id_canton: '',
        parroquia: '',
        nivel: '',
    });

    const [logoFile, setLogoFile] = useState(null);
    const [firmaFile, setFirmaFile] = useState(null);
    const { cantones, tiposEscuela } = DatosGeneralesContext(apiUrl);
    const [errors, setErrors] = useState({});
    const [generalError, setGeneralError] = useState(''); // Estado para errores generales
    
    const fetchConfigData = async () => {
        try {
            const response = await axios.get(`${apiUrl}/config`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                }
            });
            if (response.data) {
                setFormData(response.data);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data', error);
            setGeneralError('Error fetching configuration data.');
        }
    };

    useEffect(() => {
        fetchConfigData();
    }, [apiUrl, accessToken]);
    
    const handleFileChange = (name) => (e) => {
        if (name === 'logo') {
            setLogoFile(e.target.files[0]);
        } else if (name === 'firma_rector') {
            setFirmaFile(e.target.files[0]);
        }
    };

    const handleSubmit = async (e) => {
        window.showLoaderPopup();

        e.preventDefault();

        const formDataToSend = new FormData();
    
        // Agregar campos de formulario
        Object.keys(formData).forEach(key => {
            formDataToSend.append(key, formData[key]); // Añadir todos los campos de texto
        });
    
        // Agregar archivos si existen
        if (logoFile) {
            formDataToSend.append('logo', logoFile);
        }
        
        if (firmaFile) {
            formDataToSend.append('firma_rector', firmaFile);
        }

        try {
            const response = await axios.post(`${apiUrl}/config`, formDataToSend, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'multipart/form-data',
                }
            });
            await fetchConfigData(); 
            setGeneralError('');
            window.showSuccessPopup(response.data.message);
        } catch (error) {
            console.error('Error submitting form', error);
            if (error.response && error.response.data) {
                setErrors(error.response.data.errors || {});
                setGeneralError('There was an error submitting the form.');
            } else {
                setGeneralError('An unexpected error occurred.');
            }
        }
        window.hideLoaderPopup();
    };
    
    if (loading) {
        return <Spinner />;
    }

    return (
        <div className='py-3 px-3 content-wrapper'>
            <ConfigNavTab isActive={isActive} />
            <div className="col-12 grid-margin stretch-card">
                <div className="card card-rounded">
                    <div className="card-body">
                        <div className="d-sm-flex justify-content-between align-items-start">
                            <div>
                                <h2>Configuración General</h2>
                            </div>
                        </div>
                        {/* contenido */}
                        <div>
                            <form className="forms-sample" onSubmit={handleSubmit}>
                                <FormContent
                                    formData={formData}
                                    setFormData={setFormData}
                                    cantones={cantones}
                                    tiposEscuela={tiposEscuela}
                                    handleFileChange={handleFileChange}
                                    errors={errors}
                                />
                                <button type="submit" className="btn btn-primary me-2">
                                    <i className="fas fa-save"></i> Guardar configuración
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
