import axios from 'axios'
import React, {useState, useEffect} from 'react'
import TextInput from '../../form/InputText';
import { useApiContext  } from '../layout/Template';
import SelectInput from '../../form//SelectInput';
import PermisosAccordion from './PermisosAccordion';

const FormContent = ({ formData, setFormData, errors, isEdit }) => {
    
    const { apiUrl, accessToken } = useApiContext();
    const [roles, setRoles] = useState([]);
    const [grupos, setGrupos] = useState([]);
    const [modulos, setModulos] = useState([]);

    const getCreate = async () => {
        try {
            const response = await axios.get(`${apiUrl}/users/create`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json'
                }
            });
            setRoles(response.data.roles);
            setGrupos(response.data.grupos);
            setModulos(response.data.modulos);
        } catch (error) {
            console.error('Error al obtener los datos de creación:', error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        getCreate(); // Llamada al cargar el componente
    }, []);

    // Definir handleInputChange dentro del componente
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <>
            <div className="row">
                <div className="col-6">
                    <TextInput
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        placeholder="Nombre"
                        errors={errors}
                    />
                </div>
                <div className="col-6">
                    <TextInput
                        type='email'
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        placeholder="Correo"
                        errors={errors}
                    />
                </div>
                {!isEdit && (
                    <>
                    <div className="col-md-3 col-sm-6">
                        <TextInput
                            type='password'
                            name="password"
                            value={formData.password}
                            onChange={handleInputChange}
                            placeholder="Contraseña"
                            errors={errors}
                        />
                    </div>
                    <div className="col-md-3 col-sm-6">
                        <TextInput
                            type='password'
                            name="password_confirmation"
                            value={formData.password_confirmation}
                            onChange={handleInputChange}
                            placeholder="Confirmar Contraseña"
                            errors={errors}
                        />
                    </div>
                    </>
                )}
                <div className="col-6">
                    <SelectInput
                        id="id_rol"
                        name="id_rol"
                        value={formData.id_rol}
                        onChange={handleInputChange}
                        placeholder="Rol"
                        options={roles.map(rol => ({
                            value: rol.id,
                            label: rol.descripcion
                        }))}
                        error={errors['id_rol'] && errors['id_rol'][0]}
                    />
                </div>
            </div>
            <PermisosAccordion 
                grupos={grupos} 
                modulos={modulos} 
                formData={formData} 
                setFormData={setFormData} 
            />
        </>
    );
};

export default FormContent;