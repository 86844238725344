import React from 'react'

const openInNewTab = (url) => {
    var a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.rel = 'noopener noreferrer'; // Seguridad adicional
    a.style.display = 'none'; // Asegurarse de que el enlace no sea visible en el DOM
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}
const handleLinkClick = () => {
    openInNewTab('https://drive.google.com/drive/folders/1XlJFPOpmkSZVjydRxZULEp7F8CHxLp7z?usp=drive_link');
};

const SettingsButton = () => {

    return (
        <div className="theme-setting-wrapper">
            <div id="settings-trigger" onClick={handleLinkClick}>
                <i className="fab fa-youtube"></i>
            </div>
        </div>
    );
};

export default SettingsButton;
