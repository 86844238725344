import axios from 'axios';
import React, { useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Spinner from '../widget/Spinner';
import { useApiContext } from '../layout/Template';
import moment from 'moment';
//componnentes
import Form  from './Form';

const Create = () => {
    const { materiaId, parcialId } = useParams();
    const { apiUrl, accessToken } = useApiContext(); // Ajusta según tu contexto API
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    //variables
    const [formData, setFormData] = useState({
        titulo: '',
        descripcion: '',
        url: '',
        fecha: moment().format('YYYY-MM-DD'),
        archivo: null,
        materiaId: materiaId,
        parcialId: parcialId,
    });

    const store = async (e) => {
        setIsSubmitting(true); 
        e.preventDefault();
        const formDataWithFile = new FormData();
        Object.keys(formData).forEach((key) => {
            formDataWithFile.append(key, formData[key]);
        });

        // Si hay un archivo, también añadirlo
        if (formData.archivo) {
            formDataWithFile.append('archivo', formData.archivo);
            console.log(formData.archivo)
        }else{
            formDataWithFile.append('archivo', '');
        }
        try {
            const response = await axios.post(`${apiUrl}/clase`, formDataWithFile, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                }
            });
            navigate(`/Materias/Contenido/Clase/${response.data.id}`);
            window.showSuccessPopup(response.data.message);
            // Maneja la respuesta de éxito aquí
        } catch (err) {
            if (err.response && err.response.status === 422) {
                setErrors(err.response.data.errors);
            } else if (err.response) {
                const errorMessage = err.response.data.error;
                const errorCode = err.response.data.code;
                if (errorCode === 403) {
                    navigate('/dashboard');
                    window.showErrorPopup(errorMessage);
                    return;
                }
                window.showErrorPopup(errorMessage);
            } else if (err.request) {
                window.showErrorPopup('No se recibió respuesta del servidor.');
            } else {
                window.showErrorPopup('Error al configurar la solicitud: ' + err.message);
            }
        }
        setIsSubmitting(false); 
    };
    
    return (
        <div className="py-3 px-3 content-wrapper">
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2 className="">Crear Clase</h2>
                </div>
            </div>
            
            <div className="col-lg-6 offset-lg-3 grid-margin stretch-card mt-3">
                <div className="card">
                    <div className="card-body">
                        <form className="forms-sample" onSubmit={store}>
                            <Form
                                formData={formData}
                                setFormData={setFormData}
                                errors={errors}
                                setFile={(file) => setFormData(prev => ({ ...prev, archivo: file }))}
                            />
                            <button 
                                type="submit" 
                                className="btn btn-primary me-2 boton-procesar" 
                                disabled={isSubmitting}
                            >
                                {isSubmitting && (
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                )}
                                {isSubmitting ? 'Guardando...' : <><i className="fas fa-save"></i> Crear Clase</>}
                            </button>
                            <Link className="btn btn-warning" to={`/Materias/Contenido/${materiaId}/${parcialId}`}>Volver</Link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Create;
