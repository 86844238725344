// src/routes/ConfigRoutes.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from '../componentes/auth/ProtectedRoute';
import Template from '../componentes/client/layout/Template';

// config general
import Index from '../componentes/client/config/Index';
//config roles
import Roles from '../componentes/client/roles/Index';
//config usuarios
import Usuarios from '../componentes/client/users/Index';
import Create from '../componentes/client/users/Create';
import Edit from '../componentes/client/users/Edit';

const ConfigRoutes = () => {
    return (
        <Routes>
            <Route
                path=""
                element={
                    <ProtectedRoute>
                        <Template>
                            <Index isActive="config" />
                        </Template>
                    </ProtectedRoute>
                }
            />
            <Route
                path="/usuarios"
                element={
                    <ProtectedRoute>
                        <Template>
                            <Usuarios isActive="usuarios" />
                        </Template>
                    </ProtectedRoute>
                }
            />
            <Route
                path="/usuarios/create"
                element={
                    <ProtectedRoute>
                        <Template>
                            <Create isActive="usuarios" />
                        </Template>
                    </ProtectedRoute>
                }
            />
            <Route
                path="/usuarios/:id/edit"
                element={
                    <ProtectedRoute>
                        <Template>
                            <Edit />
                        </Template>
                    </ProtectedRoute>
                }
            />
            <Route
                path="/roles"
                element={
                    <ProtectedRoute>
                        <Template>
                            <Roles isActive="roles" />
                        </Template>
                    </ProtectedRoute>
                }
            />
        </Routes>
    );
};

export default ConfigRoutes;
