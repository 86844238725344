import axios from 'axios';
import React, {useState, useEffect} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useApiContext  } from '../layout/Template';
import Spinner from '../widget/Spinner';
import Form from './Form';
import moment from 'moment';


const Create = () => {
    const [loading, setLoading] = useState(false);
    const { apiUrl, accessToken } = useApiContext();
    const [formData, setFormData] = useState({
        forma_pago: 1,
        id_caja: '',
        referencia_pago: '',
        id_banco: '',
        fecha: moment().format('YYYY-MM-DD'),
        id_cuenta: '',
        id_representante: '',
        descripcion: '',
        adjunnto: '',
        rubros: [], // Cambiado a un array
        valor_pago: [] // Cambia a array vacío
    });

    const [errors, setErrors] = useState({}); // Estado para errores de validación
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        setIsSubmitting(true); 
        event.preventDefault();// Previene el comportamiento por defecto del formulario

        const formDataWithFile = new FormData();

        // Agregar campos al FormData
        Object.keys(formData).forEach((key) => {
            if (Array.isArray(formData[key])) {
                // Si es un array, agregar cada elemento como un campo separado
                formData[key].forEach((item) => {
                    formDataWithFile.append(`${key}[]`, item); // Agregar como rubros[]
                });
            } else {
                formDataWithFile.append(key, formData[key] || ''); // Si el valor es undefined, se agrega una cadena vacía
            }
        });

        // Manejo del adjunnto
        if (formData.adjunnto) {
            formDataWithFile.append('adjunnto', formData.adjunnto); // Añadir el adjunnto
        }
        try {
            const response = await axios.post(`${apiUrl}/movimientos`, formDataWithFile, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                }
            });
            navigate(`/movimientos/${response.data.id}`);
            window.showSuccessPopup(response.data.message);
        } catch (error) {
            const statusCode = error.response?.status;
            const errorMessage = error.response?.data?.error || 'Ocurrió un error inesperado';
            
            if (statusCode !== 422) {
                window.showErrorPopup('Error al procesar la solicitud: ' + errorMessage);
            }else{
                setErrors(error.response.data.errors);
                console.log(error.response.data)
                window.showErrorPopup(error.response.data.message);
            }
        }
        setIsSubmitting(false); 
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className='py-3 px-3 content-wrapper'>
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2 className="">Generar comprobante</h2>
                </div>
            </div>
            
            <div className="grid-margin stretch-card mt-3">
                <div className="card">
                    <div className="card-body">
                        <form onSubmit={handleSubmit}>
                            <Form
                                formData={formData}
                                setFormData={setFormData}
                                errors={errors}
                            />
                            <button 
                                type="submit" 
                                className="btn btn-primary me-2 boton-procesar" 
                                disabled={isSubmitting}
                            >
                                {isSubmitting && (
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                )}
                                {isSubmitting ? 'Guardando...' : <><i className="fas fa-save"></i> Crear Comprobante</>}
                            </button>
                            <button type="button" className="btn btn-warning" onClick={() => navigate('/movimientos')}>
                                Volver
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Create;
