import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useApiContext } from '../layout/Template';
import ConfigNavTab from '../config/ConfigNavTab';
import Spinner from '../widget/Spinner';
import FormContent from './FormContent';

const Edit = ({ isActive }) => {
    const [loading, setLoading] = useState(true);
    const { apiUrl, accessToken } = useApiContext();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        id_rol: '',
        permisos: {
            new_user: {}, // Aquí se guardarán los permisos
        },
    });
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        const getUser = async () => {
            try {
                const response = await axios.get(`${apiUrl}/users/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Accept': 'application/json',
                    }
                });
                const { user, permisos } = response.data;
                console.log(permisos); // Verifica la estructura de los permisos
    
                // Transformar el arreglo de permisos a un objeto estructurado
                const permisosEstructurados = {};
                permisos.forEach((permiso) => {
                    permisosEstructurados[permiso.id_modulo] = {
                        consultar: permiso.consultar,
                        crear: permiso.crear,
                        editar: permiso.editar,
                        eliminar: permiso.eliminar,
                    };
                });
    
                setFormData({
                    name: user.name,
                    email: user.email,
                    id_rol: user.id_rol,
                    permisos: { new_user: permisosEstructurados }, // Aquí guardamos los permisos estructurados
                });
                setLoading(false);
            } catch (error) {
                console.error('Error al obtener el usuario:', error.response ? error.response.data : error.message);
                setLoading(false);
            }
        };
        getUser();
    }, [apiUrl, accessToken, id]);

    const update = async (event) => {
        event.preventDefault();
        console.log('Datos a enviar:', formData);
        try {
            const response = await axios.put(`${apiUrl}/users/${id}`, formData, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            });
            navigate('/config/usuarios');
            window.showSuccessPopup(response.data.message);
        } catch (error) {
            if (error.response) {
                setErrors(error.response.data.errors);
            } else {
                console.error('Error al actualizar:', error.response ? error.response.data : error.message);
            }
        }
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className='py-3 px-3 content-wrapper'>
            <ConfigNavTab isActive={isActive} />
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2 className="">Actualizar Usuario</h2>
                </div>
            </div>
            <div className="grid-margin stretch-card mt-3">
                <div className="card">
                    <div className="card-body">
                        <form className="forms-sample" onSubmit={update}>
                            <FormContent
                                formData={formData}
                                setFormData={setFormData}
                                errors={errors}
                                isEdit={true}
                            />
                            <button type="submit" className="btn btn-primary me-2">
                                <i className="fas fa-save"></i> Actualizar Usuario
                            </button>
                            <Link className="btn btn-warning" to='/config/usuarios'>Volver</Link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Edit;
