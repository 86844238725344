import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useApiContext } from '../layout/Template';
import FormContent from './FormContent';

const Create = () => {
    const { apiUrl, accessToken } = useApiContext();
    const [formData, setFormData] = useState({
        upd_asistencias: false,
        upd_notas: false,
        intento_tarea: false,
        num_intento: '',
        ocultar_boletin: false,
        ocultar_boletin_docente: false,
    });
    const [errors, setErrors] = useState({});
    const [generalError, setGeneralError] = useState(''); // Estado para errores generales
    
    const fetchConfigData = async () => {
        try {
            const response = await axios.get(`${apiUrl}/academico`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                }
            });
            if (response.data) {
                setFormData(response.data);
            }
        } catch (error) {
            console.error('Error fetching data', error);
            setGeneralError('Error fetching configuration data.');
        }
    };

    useEffect(() => {
        fetchConfigData();
    }, [apiUrl, accessToken]);

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Establecer valores predeterminados para checkboxes que no estén marcados
        const finalFormData = {
            ...formData,
            ocultar_boletin: formData.ocultar_boletin || false,
            ocultar_boletin_docente: formData.ocultar_boletin_docente || false,
            upd_asistencias: formData.upd_asistencias || false,
            upd_notas: formData.upd_notas || false,
            intento_tarea: formData.intento_tarea || false,
        };
    
        console.log(finalFormData); // Para verificar que los valores son correctos
    
        try {
            const response = await axios.post(`${apiUrl}/academico`, finalFormData, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                }
            });
            await fetchConfigData(); // Llamar a la función para actualizar los datos
            setGeneralError(''); // Limpiar errores generales al guardar correctamente
            window.showSuccessPopup(response.data.message);
        } catch (error) {
            console.error('Error submitting form', error);
            if (error.response && error.response.data) {
                setErrors(error.response.data.errors || {});
                setGeneralError('There was an error submitting the form.'); // Mensaje de error general
            } else {
                setGeneralError('An unexpected error occurred.');
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    return (
        <div className='py-3 px-3 content-wrapper'>
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2 className="">Configuración Académica</h2>
                </div>
            </div>
            {generalError && <div className="alert alert-danger">{generalError}</div>} {/* Mostrar error general */}
            <div className="col-md-6 offset-md-3 grid-margin stretch-card mt-3">
                <div className="card">
                    <div className="card-body">
                        <form className="forms-sample" onSubmit={handleSubmit}>
                            <FormContent
                                formData={formData}
                                setFormData={setFormData}
                                errors={errors}
                                handleInputChange={handleInputChange}
                            />
                            <button type="submit" className="btn btn-primary me-2">
                                <i className="fas fa-save"></i> Guardar configuración
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Create;
