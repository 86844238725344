import axios from 'axios'
import React, {useState, useEffect} from 'react'
import { useNavigate, useParams , Link } from 'react-router-dom'
import { useApiContext  } from '../layout/Template';
import Spinner from '../widget/Spinner';
import FormContent from './FormContent';

const Create = () => {
    const [loading, setLoading] = useState(true);
    const { apiUrl, accessToken } = useApiContext();
    const [formData, setFormData] = useState({
        id_estudiante: '',
        meses: [],
        becado: false,
        descuento: false,
        porcentaje_descuento: 0,
        matricula: {
            valor_total: 0,
            valor_descuento: 0,
            porcentaje_descuento: 0,
            total: 0,
        },
    });
    const [meses, setMeses] = useState([]);
    const [curso, setCurso] = useState(null);
    const [errors, setErrors] = useState({}); // Estado para errores de validación
    const navigate = useNavigate();
    const {cursoId} = useParams();
    
    useEffect(() => {
        const getDatos = async () => {
            try {
                const response = await axios.get(`${apiUrl}/matricula/create/${cursoId}`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Accept': 'application/json',
                    }
                });
                // Aquí actualizamos el estado con los datos recibidos
                setCurso(response.data.curso);
                const mesesWithValues = response.data.meses.map(mes => {
                    const valorTotal = mes.personalizado === 1 ? Number(mes.valor) : Number(response.data.curso.cuota);
                    return {
                        ...mes,
                        valor_total: valorTotal, // Asigna según la condición
                        descuento: '0', // Inicializa descuento como '0'
                        total: valorTotal.toFixed(2), // Inicializa total como el valor total formateado
                    };
                });
                setFormData(prev => ({
                    ...prev,
                    meses: mesesWithValues,
                    matricula: {
                        valor_total: response.data.curso.matricula || 0,
                        descuento: '0',
                        porcentaje_descuento: 0,
                        total: response.data.curso.matricula || 0,
                    },
                }));
                setMeses(mesesWithValues);
                setFormData(prevData => ({
                    ...prevData,
                    meses: mesesWithValues, // Asigna los meses al formData
                }));
                setLoading(false);
            } catch (error) {
                console.error('Error al obtener el curso:', error.response ? error.response.data : error.message);
                setLoading(false); // Desactivar el cargador en caso de error
            }
        };
        getDatos();
    }, [apiUrl, accessToken, cursoId]);
    
    const store = async (event) => {
        event.preventDefault();
        const dataToSubmit = {
            ...formData,
            meses: meses.map(mes => ({
                ...mes,
                // Asegúrate de incluir el descuento y porcentaje de descuento
                descuento: mes.descuento,
                porcentaje_descuento: mes.porcentaje_descuento ?? 0,
            })),
        };
        console.log(dataToSubmit)
        try {
            const response = await axios.post(`${apiUrl}/matricula/${cursoId}`, dataToSubmit, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            });
            setErrors({});
            window.showSuccessPopup(response.data.message);
            const id = response.data.id;
            navigate(`/MisCursos/Matricula/${id}`); 
        } catch (error) {
            setErrors(error.response.data.errors);
            // Aquí capturamos el error
            if (error.response && error.response.status === 500) {
                // Mostrar el mensaje de error usando el método window.showErrorPopup
                window.showErrorPopup(error.response.data.message);
            } else {
                // Otras respuestas de error (no 500) o errores de conexión
                console.error('Error al asignar la materia:', error.message);
            }
        }
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className='py-3 px-3 content-wrapper'>
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2 className="">Inscribir Estudiante</h2>
                </div>
            </div>
            <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 grid-margin stretch-card mt-3">
                <div className="card">
                    <div className="card-body">
                        <form className="forms-sample" onSubmit={store}>
                            <FormContent
                                formData={formData}
                                setFormData={setFormData}
                                meses={meses}
                                setMeses={setMeses}
                                isEdit={false}
                                errors={errors}
                            />
                            <button type="submit" className="btn btn-primary me-2 mt-2"><i className="fas fa-save"></i> Inscribir</button>
                            <Link className="btn btn-warning" to={`/MisCursos/Info/${cursoId}`}>Volver</Link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Create;
