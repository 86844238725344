import React, { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import 'moment/locale/es'; // Importar locale en español
import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es'; // Importar locale de date-fns para react-datepicker

// Registrar el locale español en react-datepicker
registerLocale('es', es);

const DateTimeInput = ({
  label,             // Etiqueta opcional
  name,              // Nombre del campo
  value,             // Valor actual
  onChange,         // Función para manejar el cambio
  placeholder,       // Texto del placeholder
  errors,            // Manejo de errores
  disabled = false,  // Si el campo está deshabilitado
  divClass = true    // Clase de estilo opcional
}) => {
  const error = errors?.[name];  // Obtenemos el error si existe

  // Configura moment para usar el idioma español
  useEffect(() => {
    moment.locale('es'); // Establece el locale a español
  }, []);

  // Maneja el cambio de fecha y hora
  const handleChange = (date) => {
    // Verifica si la fecha es válida antes de formatear
    if (date) {
      const formattedDate = moment(date).format('YYYY-MM-DDTHH:mm'); // Formato deseado
      onChange({ target: { name, value: formattedDate } });
    } else {
      onChange({ target: { name, value: '' } }); // Maneja el caso de que no se seleccione ninguna fecha
    }
  };

  return (
    <div className={divClass ? 'form-group' : ''}>
      {label && <label htmlFor={name}>{label}</label>}
      <DatePicker
        id={name}
        name={name}
        selected={value ? moment(value).toDate() : null} // Convierte el valor a Date
        onChange={handleChange}
        showTimeSelect
        timeFormat="HH:mm"
        dateFormat="yyyy/MM/dd HH:mm"
        placeholderText={placeholder || 'Selecciona una fecha y hora'}
        disabled={disabled}
        className={`form-control ${error ? 'is-invalid' : ''}`} // Aplicamos la clase is-invalid si hay un error
        locale="es" // Establecer el locale en español
        // Aquí puedes personalizar el texto del tiempo si es necesario
        timeCaption="Hora" // Cambia el título del selector de hora
      />
      {error && <div className="text-danger">{error[0]}</div>}
    </div>
  );
};

export default DateTimeInput;
