import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import { useApiContext  } from '../layout/Template';
import ConfigNavTab from '../config/ConfigNavTab';
import Spinner from '../widget/Spinner';
import Swal from 'sweetalert2';

const cache = {};
const CACHE_DURATION = 10 * 1000; // 10 segundos en milisegundos

const Index = ({ isActive }) => {
    const [loading, setLoading] = useState(true);
    const { apiUrl, accessToken } = useApiContext();
    const [usuarios, setUsuarios] = useState([]);
    const [pagination, setPagination] = useState({
        current_page: 1,
        from: 1,
        to: 0,
        total: 0,
        links: [],
    });

    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        getData(currentPage);
    }, [currentPage]);
    

    const getData = async (page) => {
        const now = Date.now();
        
        // Verifica si el caché existe y es válido
        if (cache[page] && (now - cache[page].timestamp < CACHE_DURATION)) {
            setUsuarios(cache[page].data);
            setPagination(cache[page].pagination);
            setLoading(false);
            return;
        }

        try {
            const response = await axios.get(`${apiUrl}/users?page=${page}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                },
            });
            console.log('Datos recibidos:', response.data);

            // Actualiza el caché
            cache[page] = {
                data: response.data.data,
                pagination: {
                    current_page: response.data.current_page,
                    from: response.data.from,
                    to: response.data.to,
                    total: response.data.total,
                    links: response.data.links,
                },
                timestamp: Date.now(), // Marca de tiempo para la expiración
            };

            // Actualiza el estado
            setUsuarios(response.data.data);
            setPagination(cache[page].pagination);

            setLoading(false);
        } catch (error) {
            console.error('Error fetching dashboard data', error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey && event.key === 'r') {
                event.preventDefault(); // Evita la recarga de la página
                delete cache[currentPage];
                getData(currentPage);
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [currentPage]);

    const toggleStatus = async (id, currentStatus) => {
        try {
            const newStatus = currentStatus === 1 ? 0 : 1;
            await axios.patch(`${apiUrl}/users/${id}/status`, { status: newStatus }, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                },
            });
            // Actualizar el estado local
            setUsuarios(usuarios.map(usuario => 
                usuario.id === id ? { ...usuario, estado: newStatus } : usuario
            ));

            // Invalida el caché para la página actual
            cache[currentPage] = null;
        } catch (error) {
            console.error('Error updating status', error.response ? error.response.data : error.message);
        }
    };
    
    const handleDelete = async (id) => {
        // Mostrar el cuadro de confirmación
        const result = await Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás recuperar este usuario después de eliminarlo!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
        });
    
        // Si el usuario confirma la eliminación
        if (result.isConfirmed) {
            try {
                await axios.delete(`${apiUrl}/users/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Accept': 'application/json',
                    },
                });
                setUsuarios(usuarios.filter(usuario => usuario.id !== id));
                cache[currentPage] = null;
                Swal.fire({
                    icon: 'success',
                    title: 'Eliminado',
                    text: 'El usuario ha sido eliminado correctamente.',
                });
            } catch (error) {
                console.error('Error deleting usuario:', error.response ? error.response.data : error.message);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'No se pudo eliminar el usuario. Por favor, inténtelo de nuevo.',
                });
            }
        }
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className='py-3 px-3 content-wrapper'>
            <ConfigNavTab isActive={isActive} />
            <div className="d-sm-flex justify-content-between align-items-start mb-3">
                <div>
                    <h2 className="">Consultar Usuarios</h2>
                </div>
                <div>
                    <div className="btn-group" role="group" aria-label="Basic outlined example">
                        <Link to='create' className="btn btn-outline-primary"><i className="fas fa-plus-circle"></i></Link>
                    </div>
                </div>
            </div>
            <div className="col-12 grid-margin stretch-card">
                <div className="card card-rounded">
                    <div className="card-body">
                        <div className="table-responsive  mt-1">
                            <table className="table select-table">
                                <thead>
                                    <tr>
                                        <th>Nombre</th>
                                        <th>Rol</th>
                                        <th>Estado</th>
                                        <th>Creado</th>
                                        <th>opc</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {usuarios.map(usuario => (
                                        <tr style={{ fontSize: '20px' }} key={usuario.id}>
                                            <td>
                                                <Link to={`/config/usuarios/${usuario.id}/edit`} className='text-decoration-none'>
                                                    {usuario.name}
                                                </Link>
                                            </td>
                                            <td>{usuario.id_rol ? usuario.rol.descripcion : 'N/A'}</td>
                                            <td>
                                                <button
                                                    onClick={() => toggleStatus(usuario.id, usuario.estado)}
                                                    className={`btn btn-sm ${usuario.estado === 1 ? 'btn-success' : 'btn-danger'}`}
                                                >
                                                    <i className={`fas fa-${usuario.estado === 1 ? 'check' : 'times'}`}></i>
                                                </button>
                                            </td>
                                            <td>{usuario.created_at}</td>
                                            <td>
                                                <div className="dropdown">
                                                    <a className="btn btn-secondary" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className="fas fa-cogs"></i>
                                                    </a>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                        <li>
                                                            <Link className="dropdown-item" to={`/config/usuarios/${usuario.id}/edit`}>Editar</Link>
                                                        </li>
                                                        <li>
                                                            <Link className="dropdown-item" to="#" onClick={()=>handleDelete(usuario.id)}>Eliminar</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {/* Paginación */}
                        <div className="row mt-2 pb-0 mb-0">
                            <div className="col-sm-12 col-md-5">
                                <div>
                                    Mostrando {pagination.from} a {pagination.to} de {pagination.total} resultados
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-7">
                                <ul className="pagination">
                                    {pagination.links.map((link, index) => (
                                        <li key={index} className={`paginate_button page-item ${link.active ? 'active' : ''}`}>
                                            <button
                                                onClick={() => link.url && setCurrentPage(new URL(link.url).searchParams.get('page'))}
                                                className="page-link"
                                                disabled={!link.url}
                                                dangerouslySetInnerHTML={{ __html: link.label }}
                                            />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Index;
