const PermisosAccordion = ({ grupos, modulos, formData, setFormData }) => {
    const permisosNewUser = formData.permisos.new_user || {};
    const handlePermissionChange = (grupoId, moduloId, permisoType) => {
        setFormData((prevFormData) => {
            const newPermisos = { ...prevFormData.permisos.new_user };

            if (!newPermisos[moduloId]) {
                newPermisos[moduloId] = {};
            }

            newPermisos[moduloId][permisoType] = !newPermisos[moduloId][permisoType];

            return {
                ...prevFormData,
                permisos: {
                    ...prevFormData.permisos,
                    new_user: newPermisos
                }
            };
        });
    };

    return (
        <div className="row">
            {grupos.map((grupo) => (
                <div key={grupo.id} className="col-lg-6 col-md-12 col-sm-12 accordion mb-3">
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button className="accordion-button">
                                {grupo.nombre}
                            </button>
                        </h2>
                        <div className="accordion-collapse collapse show">
                            <div className="accordion-body">
                                <div className="table-responsive">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Todos</th>
                                                <th>Ver</th>
                                                <th>Agregar</th>
                                                <th>Modificar</th>
                                                <th>Eliminar</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {modulos
                                                .filter((modulo) => modulo.id_grupo === grupo.id)
                                                .map((modulo) => (
                                                    <tr key={modulo.id}>
                                                        <td>{modulo.nombre}</td>
                                                        {['consultar', 'crear', 'editar', 'eliminar'].map((permisoType) => (
                                                            <td key={permisoType} style={{ textAlign: 'center' }}>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={!!formData.permisos.new_user?.[modulo.id]?.[permisoType]}
                                                                    onChange={() => handlePermissionChange(grupo.id, modulo.id, permisoType)}
                                                                />
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default PermisosAccordion;
