import axios from 'axios'
import React, {useEffect, useState} from 'react'
import { Link, useNavigate  } from 'react-router-dom'
import { useApiContext  } from '../layout/Template';
import TextInput from '../../form/InputText';
import CheckboxInput from '../../form/CheckboxInput';
import SelectInput from '../../form/SelectInput';
import DateInput from '../../form/DateInput';

const FormContent = ({ formData, setFormData, isEdit = false, isShow = false, rubro, errors }) => {
    const navigate = useNavigate();
    
    // Definir handleInputChange dentro del componente
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCheckboxChange = (field, subField = null) => (e) => {
        const checked = e.target.checked;
        setFormData(prevFormData => {
            if (subField) {
                // Caso para subcampos
                return {
                    ...prevFormData,
                    [field]: {
                        ...prevFormData[field],
                        [subField]: checked
                    }
                };
            } else {
                // Caso para campos en el nivel superior
                return {
                    ...prevFormData,
                    [field]: checked
                };
            }
        });
    };

    return (
        <>
            <div className='row'>
                <div className='col-12 col-md-3 col-lg-3'>
                    <SelectInput
                        id="tipo"
                        name="tipo"
                        value={formData.tipo}
                        onChange={handleInputChange}
                        placeholder="Tipo Documento"
                        options={[
                            { value: 1, label: 'DNA - Documento No Autorizado' },
                            { value: 2, label: 'Factura Electronica' },
                        ]}
                        disabled={isShow}
                        error={errors['tipo'] && errors['tipo'][0]}
                    />
                </div>
                <div className='col-12 col-md-3 col-lg-3'>
                    <DateInput
                        label="Fecha Emisión"
                        name="fechaEmision"
                        value={formData.fechaEmision}
                        onChange={handleInputChange}
                        placeholder="Fecha Emisión"
                        isDateTime={true}
                        errors={errors}
                        disabled={isShow}
                    />
                </div>
            </div>
            { (isShow === true || isEdit === true) &&(
                <>
                <div className='col-12 col-md-3 col-lg-3'>
                    <label>Secuencia</label>
                    <input 
                        disabled
                        className='form-control'
                        value={rubro.estudiante_curso.estudiante.representante.nombres} 
                    />
                </div>
                { formData.tipo === 2 && (
                    <>
                        <div className='col-12 col-md-6 col-lg-6'>
                            <label>Clave de Acceso</label>
                            <input 
                                disabled
                                className='form-control'
                                value={formData.claveAcceso}
                            />
                        </div>
                        <div className=''>
                            <CheckboxInput 
                                disabled={isShow}
                                id="estado"
                                name="estado"
                                checked={formData.estado}
                                onChange={handleCheckboxChange('estado')}
                                label="Activo/Anulado"
                            />
                        </div>
                    </>
                )}
                </>
            )}
            <div>
                <div className='col-12 col-md-3 col-lg-3'>
                    <label>Persona</label>
                    <input 
                        disabled
                        className='form-control'
                        value={rubro.estudiante_curso.estudiante.representante.nombres} 
                    />
                </div>
                <div className='col-12 col-md-3 col-lg-3'>
                    <label>Identificación</label>
                    <input 
                        disabled
                        className='form-control'
                        value={rubro.estudiante_curso.estudiante.representante.identificacion} 
                    />
                </div>
            </div>
            
            <hr/>
            <div className="table-responsive">
                <table className="table table-bordered" style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th>Cant</th>
                            <th>Detalle</th>
                            <th style={{ width: '80px' }}>Precio</th>
                            <th style={{ width: '80px' }}>IVA</th>
                            <th style={{ width: '80px' }}>Subtotal</th>
                            <th style={{ width: '80px' }}>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>
                                {rubro.descripcion}
                                <br/>
                                Estudiante: {rubro.estudiante_curso.estudiante.nombres}
                            </td>
                            <td>
                                <input style={{ width: '80px' }} className='form-control' disabled value={rubro.monto_total} />
                            </td>
                            <td>
                                0%
                            </td>
                            <td>
                                <input style={{ width: '80px' }} className='form-control' disabled value={rubro.monto_total} />
                            </td>
                            <td>
                                <input style={{ width: '80px' }} className='form-control' disabled value={rubro.monto_total} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
            <div className='row'>
                <div className='col-lg-9 col-md-6 col-sm-12'>
                    <div className="form-floating">
                        <textarea
                            disabled={isShow}
                            className="form-control"
                            id="campoAdicional"
                            name="campoAdicional"
                            maxLength="250"
                            style={{ height: 'calc(6.5rem + 2px)' }} // Corregido
                            value={formData.campoAdicional}
                            onChange={handleInputChange}
                        />
                        <label htmlFor="campoAdicional">Descripción:</label>
                    </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                    <div class="form-group mb-1">
                        <label>Subtotal:</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="fas fa-dollar-sign"></i>
                                </span>
                            </div>
                            <input type="text" className="form-control align-right" value={rubro.monto_total} disabled />
                        </div>
                    </div>
                    <div class="form-group mb-1">
                        <label>IVA:</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="fas fa-dollar-sign"></i>
                                </span>
                            </div>
                            <input type="text" className="form-control align-right" value={0.00} disabled />
                        </div>
                    </div>
                    <div class="form-group mb-1">
                        <label>Subtotal 0%:</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="fas fa-dollar-sign"></i>
                                </span>
                            </div>
                            <input type="text" className="form-control align-right" value={rubro.monto_total} disabled />
                        </div>
                    </div>
                    <div class="form-group mb-1">
                        <label>Total:</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <i class="fas fa-dollar-sign"></i>
                                </span>
                            </div>
                            <input type="text" className="form-control align-right" value={rubro.monto_total} disabled />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FormContent;