import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useApiContext } from '../layout/Template';
import { useNavigate } from 'react-router-dom';
import { useParams, Link } from 'react-router-dom';
import Spinner from '../widget/Spinner';
//import MyAreaChart from '../charts/MyAreaChart';
import MyBarChart from '../charts/MyBarChart';
import MyPieChart from '../charts/MyPieChart';
import Parciales from './Parciales';

const Subciclo = () => {
    const { materiaId, subcicloId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const { apiUrl, accessToken, user } = useApiContext();
    const [isLoading, setIsLoading] = useState(false);
    
    const [estudiantes, setEstudiantes] = useState([]);
    const [titulo, setTitulo] = useState([]);
    const [parcial, setParcial] = useState(null);
    const [materia, setMateria] = useState(null);
    const [curso, setCurso] = useState(null);
    const [elementos, setElementos] = useState([]);
    const [evaluacion, setEvaluacion] = useState(null);
    const [ciclos, setCiclos] = useState([]);
    const [subciclos, setSubciclos] = useState([]);
    const [promedioCursoEstudi, setPromedioCursoEstudi] = useState(null);
    const [cualitativa, setCualitativa] = useState(null);
    const [distribucionEscalas, setDistribucionEscalas] = useState([]);
    const [aprovechamientos, setAprovechamientos] = useState([]);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${apiUrl}/sabana/materia/subciclo/${materiaId}/${subcicloId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json'
                }
            });
            const data = response.data;
            setEstudiantes(data.estudiantes);
            setTitulo(data.titulo);
            setParcial(data.parcial);
            setMateria(data.materia);
            setCurso(data.curso);
            setElementos(data.elementos);
            setEvaluacion(data.evaluacion);
            setCiclos(data.ciclos);
            setSubciclos(data.subciclos);
            setPromedioCursoEstudi(data.promedioCursoEstudi);
            setCualitativa(data.cualitativa);
            setDistribucionEscalas(data.distribucionEscalas);
            setAprovechamientos(data.aprovechamientos);
            console.log(data)
        } catch (err) {
            if (err.response) {
                const errorMessage = err.response.data.error;
                const errorCode = err.response.data.code;
                if (errorCode === 403) {
                    // Mostrar el popup de error y redirigir al Dashboard
                    navigate('/dashboard'); // Cambia '/dashboard' por la ruta real de tu componente Dashboard
                    window.showErrorPopup(errorMessage);
                    return;
                }
                window.showErrorPopup(errorMessage);
            } else if (err.request) {
                window.showErrorPopup('No se recibió respuesta del servidor.');
            } else {
                window.showErrorPopup('Error al configurar la solicitud: ' + err.message);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [materiaId, subcicloId]); 

    const pdf = async () => {
        if (isLoading) return; // Evitar ejecutar si ya está cargando

        setIsLoading(true);
        console.log("procesando");
        try {
            const response = await axios.get(`${apiUrl}/sabana/materia/subciclo/pdf/${materiaId}/${subcicloId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/pdf',
                },
                responseType: 'blob'
            });
    
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
    
            const nombreArchivo = `${titulo.descripcion.replace(/[\/\\?%*:|"<>]/g, '-')}.pdf`;
    
            link.setAttribute('download', nombreArchivo);
            document.body.appendChild(link);
            link.click();
    
            window.URL.revokeObjectURL(url);
            link.remove();
        } catch (err) {
            console.error('Error al descargar el PDF:', err);
            window.showErrorPopup('Error al descargar el archivo PDF.');
        } finally {
            setIsLoading(false);
        }
        console.log("fin");
    };
    
    const tipoElementoClase = {
        1: 'tarea',
        2: 'leccion',
        3: 'individual',
        4: 'grupal',
        5: 'exposicion'
    };

    /*
    if (loading) {
        return <Spinner />;
    }
    */

    return (
        <div className='py-3 px-3 content-wrapper'>
            <style>
                {`
                    div.vertical{
                    width: 45px;
                    transform: rotate(-90deg);
                    -webkit-transform: rotate(-90deg); /* Safari/Chrome */
                    -moz-transform: rotate(-90deg); /* Firefox */
                    -o-transform: rotate(-90deg); /* Opera */
                    -ms-transform: rotate(-90deg); /* IE 9 */
                    }

                    th.vertical{
                    height: 115px;
                    padding-bottom: 0;
                    margin-bottom: 0;
                    }

                    .tarea {
                        border-bottom: 10px solid #1F3BB3 !important;
                    }

                    .leccion {
                        border-bottom: 10px solid rgb(224, 129, 66) !important;
                    }

                    .individual {
                        border-bottom: 10px solid rgb(29, 209, 241) !important;
                    }

                    .grupal {
                        border-bottom: 10px solid rgb(138, 140, 255) !important;
                    }

                    .exposicion {
                        border-bottom: 10px solid rgb(57, 206, 149) !important;
                    }

                    .formativo {
                        border-bottom: 10px solid #0d6efd !important;
                    }

                    .proyecto {
                        border-bottom: 10px solid yellow !important;
                    }

                    .promedio {
                        border-bottom: 10px solid #198754 !important;
                    }
                `}
            </style>
            <div className="d-sm-flex justify-content-between align-items-start mb-2">
                <div>
                    <h2><strong>{ titulo.descripcion }</strong></h2>
                </div>
                <div>
                    <div className="btn-group mb-2" role="group" aria-label="Basic outlined example">
                        <Link to={`/Materias/Contenido/${materiaId}/${subcicloId}`} className="btn btn-warning btn-sm"><i className="fas fa-chevron-circle-left"></i> <span className="d-none d-lg-inline"> Volver</span></Link>
                    </div>
                </div>
            </div>
            <div className='row'>
                <h2>Promedio: {promedioCursoEstudi} - Cualitativa Prom: {cualitativa}</h2>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                    <MyBarChart data={distribucionEscalas}/>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                    <MyBarChart data={aprovechamientos}/>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                    <MyPieChart data={aprovechamientos}/>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="d-sm-flex justify-content-between align-items-start mb-2">
                        <div>
                            <h2><strong>{ titulo.subtitulo }</strong></h2>
                            <Parciales ciclos={ciclos} subciclos={subciclos} materiaId={materiaId} />
                        </div>
                        <div>
                            <button className="btn btn-primary" onClick={pdf} disabled={isLoading}><i className="fas fa-file-pdf"></i> <span className="d-none d-lg-inline"> Generar PDF</span></button>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className='table table-bordered'>
                            <thead>
                                <tr>
                                    <th>
                                        Estudiantes
                                    </th>
                                    {elementos.map((elemento, index) => {
                                        // Determina la clase basada en id_tipoelemento
                                        const clase = tipoElementoClase[elemento.id_tipoelemento] || '';

                                        return (
                                            <th
                                                key={index}
                                                className={`${clase}`}  // Aplica la clase condicional
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                title={elemento.titulo}
                                                style={{ width: "45px" }}
                                            >
                                                <div className="vertical">
                                                    <a href="#" className="text-decoration-none">
                                                        { elemento.id_tipoelemento ? elemento.tipoelemento.alias : '' }
                                                    </a>
                                                </div>
                                            </th>
                                        );
                                    })}
                                    {evaluacion && (
                                        <>
                                        <th style={{ width: "45px" }} className="vertical formativo"><div className="vertical">Formativos</div></th>
                                        <th style={{ width: "45px" }} className="vertical formativo"><div className="vertical">70%</div></th>
                                        <th style={{ width: "45px" }} className="vertical proyecto"><div className="vertical">Sumativa</div></th>
                                        <th style={{ width: "45px" }} className="vertical proyecto"><div className="vertical">30%</div></th>
                                        </>
                                    )}
                                    <th style={{ width: "45px" }} className="vertical promedio"><div className="vertical">Promedio</div></th>
                                    <th style={{ width: "45px" }} className="vertical promedio"><div className="vertical">Cualitativa</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                {estudiantes.map((estudiante, index) => {
                                    // Obtenemos las notas de los elementos parciales para este estudiante
                                    const notasElemento = estudiante.notas_elementos || [];
                                    
                                    return (
                                        <tr key={index}>
                                            {/* Nombre del estudiante */}
                                            <td>{estudiante.nombres}</td>
                                            
                                            {/* Notas de cada elemento */}
                                            {elementos.map((elemento, elemIndex) => {
                                                const notaElemento = notasElemento.find(nota => nota.id_elemento === elemento.id);
                                                return (
                                                    <td key={elemIndex} style={{ width: "45px" }}>
                                                        {notaElemento ? parseFloat(notaElemento.calificacion).toFixed(2) : '0.00'}
                                                    </td>
                                                );
                                            })}

                                            {/* Si hay evaluación, mostrar los campos de evaluación */}
                                            {evaluacion && (
                                                <>
                                                    <td style={{ width: "45px" }}>
                                                        {parseFloat(estudiante.promActiv).toFixed(2)}
                                                    </td>
                                                    <td style={{ width: "45px" }}>
                                                        {parseFloat(estudiante.porcActiv).toFixed(2)}
                                                    </td>
                                                    <td style={{ width: "45px" }}>
                                                        {parseFloat(estudiante.notaEva).toFixed(2)}
                                                    </td>
                                                    <td style={{ width: "45px" }}>
                                                        {parseFloat(estudiante.porcEva).toFixed(2)}
                                                    </td>
                                                </>
                                            )}

                                            {/* Promedio final */}
                                            <td style={{ width: "45px" }}>
                                                {parseFloat(estudiante.promedio_final).toFixed(2)}
                                            </td>

                                            {/* Nota cuantitativa */}
                                            <td style={{ width: "45px" }}>
                                                {estudiante.cuantitativa}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Subciclo;
