import React from 'react';
import TextInput from '../../form/InputText';
import SelectInput from '../../form//SelectInput';

const FormContent = ({ formData, setFormData, bancos, errors }) => {
    // Definir handleInputChange dentro del componente
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <>
            <div className="col-6">
                <SelectInput
                    id="id_banco"
                    name="id_banco"
                    value={formData.id_banco}
                    onChange={handleInputChange}
                    placeholder="Banco"
                    options={bancos.map(banco => ({
                        value: banco.id,
                        label: banco.descripcion
                    }))}
                    error={errors['id_banco'] && errors['id_banco'][0]}
                />
            </div>
            <div className="mb-3">
                <TextInput
                    label="Cuenta"
                    name="cuenta"
                    value={formData.cuenta}
                    onChange={handleInputChange}
                    placeholder="Cuenta"
                    validacion={'entero'}
                    errors={errors}
                />
            </div>
            <div className="mb-3">
                <TextInput
                    label="Titular"
                    name="titular"
                    value={formData.titular}
                    onChange={handleInputChange}
                    placeholder="Titular"
                    errors={errors}
                    validacion={'alfanumerico'}
                />
            </div>
            <div className="col-4">
                <SelectInput
                    id="tipo"
                    name="tipo"
                    value={formData.tipo}
                    onChange={handleInputChange}
                    placeholder="Tipo de cuenta"
                    options={[
                        { value: '1', label: 'Ahorro' },
                        { value: '2', label: 'Corriente' }
                    ]}
                    error={errors['tipo'] && errors['tipo'][0]}
                />
            </div>
        </>
    );
};

export default FormContent;