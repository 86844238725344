import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Spinner from '../widget/Spinner';
import { useApiContext } from '../layout/Template';
import moment from 'moment';
//componnentes
import Form  from './Form';

const Test = () => {
    const { claseId } = useParams();
    const { apiUrl, accessToken } = useApiContext();
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [clase, setClase] = useState(null);
    const endpoint = `${apiUrl}/clase/${claseId}`;
    //variables
    const [formData, setFormData] = useState({
    });

    const fetchClase = async () => {
        try {
            const response = await axios.get(`${endpoint}/edit`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}` // Agrega el token de acceso si es necesario
                }
            });
            setClase(response.data.clase);
            const { clase, titulo, descripcion, url, fecha, archivo } = response.data.clase;
            setFormData({
                titulo: titulo,
                descripcion: descripcion,
                url: url || '',
                fecha: fecha,
                archivo: archivo,
            });
        } catch (err) {
            if (err.response && err.response.status === 422) {
                setErrors(err.response.data.errors);
            } else if (err.response) {
                const errorMessage = err.response.data.error;
                const errorCode = err.response.data.code;
                if (errorCode === 403) {
                    navigate('/dashboard');
                    window.showErrorPopup(errorMessage);
                    return;
                }
                window.showErrorPopup(errorMessage);
            } else if (err.request) {
                window.showErrorPopup('No se recibió respuesta del servidor.');
            } else {
                window.showErrorPopup('Error al configurar la solicitud: ' + err.message);
            }
        } finally {
            setLoading(false); // Finaliza el estado de carga
        }
    };

    useEffect(() => {
        fetchClase();
    }, [claseId, apiUrl, accessToken]);

    const update = async (e) => {
        e.preventDefault();
        const formDataWithFile = new FormData();
        Object.keys(formData).forEach((key) => {
            formDataWithFile.append(key, formData[key]);
        });

        // Si hay un archivo, también añadirlo
        if (formData.archivo) {
            formDataWithFile.append('archivo', formData.archivo);
            console.log(formData.archivo)
        }else{
            formDataWithFile.append('archivo', '');
        }
        try {
            const response = await axios.post(endpoint, formDataWithFile, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                }
            });
            navigate(`/Materias/Contenido/Clase/${response.data.id}`);
            window.showSuccessPopup(response.data.message);
            // Maneja la respuesta de éxito aquí
        } catch (err) {
            if (err.response && err.response.status === 422) {
                setErrors(err.response.data.errors);
            } else if (err.response) {
                const errorMessage = err.response.data.error;
                const errorCode = err.response.data.code;
                if (errorCode === 403) {
                    navigate('/dashboard');
                    window.showErrorPopup(errorMessage);
                    return;
                }
                window.showErrorPopup(errorMessage);
            } else if (err.request) {
                window.showErrorPopup('No se recibió respuesta del servidor.');
            } else {
                window.showErrorPopup('Error al configurar la solicitud: ' + err.message);
            }
        }
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className="py-3 px-3 content-wrapper">
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2 className="">Actualizar Clase</h2>
                </div>
            </div>

            <div className="col-md-6 offset-md-3 grid-margin stretch-card mt-3">
                <div className="card">
                    <div className="card-body">
                        <form className="forms-sample" onSubmit={update}>
                            <Form
                                formData={formData}
                                setFormData={setFormData}
                                errors={errors}
                                isEdit={true}
                                setFile={(file) => setFormData(prev => ({ ...prev, archivo: file }))}
                            />
                            <button type="submit" className="btn btn-primary me-2 mt-2 boton-procesar">
                                <i className="fas fa-save"></i> Actualizar Clase
                            </button>
                            <Link className="btn btn-warning" to={`/Materias/Contenido/${clase.id_materiacurso}/${clase.id_subciclo}`}>Volver</Link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Test;
