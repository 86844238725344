// Impersonate.js
import React, { useState } from 'react';
import axios from 'axios';

const Impersonate = ({ apiUrl, accessToken }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState([]);

    const endpoint = 'users/search';
    const impersonateEndpoint = 'users/impersonate';

    const handleSearchChange = async (event) => {
        const query = event.target.value;
        setSearchTerm(query);

        if (query) {
            try {
                const response = await axios.post(`${apiUrl}/${endpoint}`, 
                    { query },
                    {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Accept': 'application/json',
                        },
                    }
                );

                setResults(response.data);
            } catch (error) {
                console.error('Error fetching search results:', error);
            }
        } else {
            setResults([]); // Limpia los resultados si no hay búsqueda
        }
    };

    const handleResultClick = async (result) => {
        console.log('Resultado seleccionado:', result);
        setSearchTerm(result.name); // Establece el valor del input al resultado seleccionado
        setResults([]); // Limpia los resultados después de seleccionar
        try {
            const response = await axios.post(`${apiUrl}/${impersonateEndpoint}`, {
                user_id: result.id // Enviamos el ID del usuario seleccionado
            }, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                },
            });
            const newAccessToken = response.data.access_token; // El nuevo token de acceso
            const user = response.data.user; // La información del usuario impersonado

            // Aquí puedes hacer algo con el nuevo token y el usuario, como guardar en el estado
            console.log('Nuevo token de acceso:', newAccessToken);
            console.log('Usuario impersonado:', user);
            
            // Guardar los valores antiguos si ya existen
            const oldAccessToken = localStorage.getItem('accessToken');
            const oldUser = localStorage.getItem('user');

            if (oldAccessToken) {
                localStorage.setItem('accessToken_old', oldAccessToken);
            }
            if (oldUser) {
                localStorage.setItem('user_old', oldUser);
            }

            // Guardar los nuevos valores en localStorage
            localStorage.setItem('accessToken', newAccessToken); // Actualiza el token de acceso
            localStorage.setItem('user', JSON.stringify(user));
        
            // Recargar la página para reflejar los cambios
            window.location.reload();

        } catch (error) {
            console.error('Error impersonating user:', error);
        }
    };

    const handleLogout = async () => {
        try {
            const response = await axios.post(`${apiUrl}/logout`, {}, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });

            // Obtener los tokens antiguos
            const oldAccessToken = localStorage.getItem('accessToken_old');
            const oldUser = localStorage.getItem('user_old');
    
            // Volver a establecer los valores actuales
            if (oldAccessToken) {
                localStorage.setItem('accessToken', oldAccessToken); // Establecer el token de acceso antiguo
            }
            if (oldUser) {
                localStorage.setItem('user', oldUser); // Establecer el usuario antiguo
            }
    
            // Eliminar los valores _old
            localStorage.removeItem('accessToken_old');
            localStorage.removeItem('user_old');
    
            // Recargar la página para reflejar los cambios
            window.location.reload();
        } catch (error) {
            console.error('Error closing session:', error);
        }
    };

    return (
        <li className="nav-item dropdown">
        {localStorage.getItem('accessToken_old') ? (
            <button onClick={handleLogout} className="btn btn-danger">
                Terminar sesión
            </button>
        ) : (
            <form className="search-form" action="#">
                <i className="fas fa-search"></i>
                <input
                    type="search"
                    className="form-control"
                    placeholder="Buscar"
                    title="Buscar"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    aria-expanded={results.length > 0}
                />
            </form>
        )}

        {/* Mostrar el dropdown de resultados si hay resultados */}
        {results.length > 0 && (
            <ul className="dropdown-menu dropdown-menu-right navbar-dropdown" style={{ display: results.length > 0 ? 'block' : 'none' }}>
                {results.map((result) => (
                    <li key={result.id} onClick={() => handleResultClick(result)} className="dropdown-item">
                        {result.name}
                        <br/>
                        {result.username}
                    </li>
                ))}
            </ul>
        )}
        {results.length === 0 && searchTerm && (
            <ul className="dropdown-menu dropdown-menu-right navbar-dropdown" style={{ display: 'block' }}>
                <li className="dropdown-item text-muted">Sin resultados</li>
            </ul>
        )}
        </li>
    );
};

export default Impersonate;
