import React from 'react';

const InputText = ({ label, name, value, onChange, onInput, placeholder, errors, validacion, type = 'text', readonly = false, divClass = true, }) => {
    const error = errors?.[name]; // Verifica si 'errors' existe y luego accede a la propiedad

    // Maneja el evento de entrada basado en la validación
    const handleInput = (event) => {
        const { value } = event.target;
        let valorFiltrado;

        switch (validacion) {
            case 'entero':
                // Solo números enteros positivos
                valorFiltrado = value.replace(/[^0-9]/g, '');
                break;
            case 'decimal':
                // Números decimales
                valorFiltrado = value.replace(/[^0-9.]/g, '');
                // Asegura que solo haya un punto decimal
                if ((valorFiltrado.match(/\./g) || []).length > 1) {
                    valorFiltrado = valorFiltrado.replace(/\.(?!.*\.)/g, '');
                }
                break;
            case 'alfabetico':
                // Solo caracteres alfabéticos
                valorFiltrado = value.replace(/[^a-zA-Z]/g, '');
                break;
            case 'alfanumerico':
                // Solo caracteres alfanuméricos (sin espacios)
                valorFiltrado = value.replace(/[^a-zA-Z0-9]/g, '');
                break;
            case 'porcentaje':
                // Filtrar solo números y el punto
                valorFiltrado = value.replace(/[^0-9.]/g, '');
                // Asegura que solo haya un punto decimal
                if ((valorFiltrado.match(/\./g) || []).length > 1) {
                    valorFiltrado = valorFiltrado.replace(/\.(?!.*\.)/g, '');
                }
                // Validar máximo 100
                if (parseFloat(valorFiltrado) > 100) {
                    valorFiltrado = '100';
                }
                // Validar dos decimales
                const partes = valorFiltrado.split('.');
                if (partes.length > 1 && partes[1].length > 2) {
                    valorFiltrado = `${partes[0]}.${partes[1].slice(0, 2)}`;
                }
                break;
            default:
                valorFiltrado = value;
        }

        event.target.value = valorFiltrado;
        if (onInput) {
            onInput(event);
        }
    };

    return (
        <div className={divClass ? "form-group" : ""}>
            <label htmlFor={name}>{placeholder}</label>
            <input
                value={value}
                onChange={onChange}
                onInput={handleInput}
                type={type}
                className={`form-control ${error ? 'is-invalid' : ''}`}
                id={name}
                name={name}
                placeholder={placeholder}
                readOnly={readonly}
            />
            {error && <div className="invalid-feedback">{error[0]}</div>}
        </div>
    );
};

export default InputText;
