// src/routes/ConfFactRoutes.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from '../componentes/auth/ProtectedRoute';
import Template from '../componentes/client/layout/Template';

// confFact
import Create from '../componentes/client/confFact/Create';

const ConfFactRoutes = () => {
    return (
        <Routes>
            <Route
                path=""
                element={
                    <ProtectedRoute>
                        <Template>
                            <Create />
                        </Template>
                    </ProtectedRoute>
                }
            />
        </Routes>
    );
};

export default ConfFactRoutes;
