import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Spinner from '../widget/Spinner';
import { useApiContext } from '../layout/Template';
import Form  from './Form';

const Test = () => {
    const { actividadId } = useParams();
    const { apiUrl, accessToken } = useApiContext();
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [actividad, setActividad] = useState(null);
    //variables
    const [formData, setFormData] = useState({
    });

    const fetchActividad = async () => {
        try {
            const response = await axios.get(`${apiUrl}/actividad/${actividadId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            setActividad(response.data.actividad);
            const { actividad, titulo, resumen, descripcion, url, id_tipoelemento, fecha, fecha_inicio, fecha_fin, entregas, foro, archivo } = response.data.actividad;
            setFormData({
                titulo: titulo,
                resumen: resumen,
                descripcion: descripcion,
                url: url || '',
                id_tipoelemento: id_tipoelemento,
                fecha: fecha,
                fecha_inicio: fecha_inicio,
                fecha_fin: fecha_fin,
                entregas: entregas === true || entregas === 1,
                foro: foro === true || foro === 1,
                archivo: archivo,
            });
        } catch (err) {
            if (err.response && err.response.status === 422) {
                setErrors(err.response.data.errors);
            } else if (err.response) {
                const errorMessage = err.response.data.error;
                const errorCode = err.response.data.code;
                if (errorCode === 403) {
                    navigate('/dashboard');
                    window.showErrorPopup(errorMessage);
                    return;
                }
                window.showErrorPopup(errorMessage);
            } else if (err.request) {
                window.showErrorPopup('No se recibió respuesta del servidor.');
            } else {
                window.showErrorPopup('Error al configurar la solicitud: ' + err.message);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchActividad();
    }, [actividadId, apiUrl, accessToken]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formDataWithFile = new FormData();
        Object.keys(formData).forEach((key) => {
            formDataWithFile.append(key, formData[key]);
        });

        // Si hay un archivo, también añadirlo
        if (formData.archivo) {
            formDataWithFile.append('archivo', formData.archivo);
            console.log(formData.archivo)
        }else{
            formDataWithFile.append('archivo', '');
        }

        console.log('Datos enviados:', formDataWithFile);
        try {
            const response = await axios.post(`${apiUrl}/actividad/${actividadId}`, formDataWithFile, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                }
            });
            navigate(`/Materias/Contenido/Actividades/${response.data.id}`);
            window.showSuccessPopup(response.data.message);
        } catch (error) {
            setErrors(error.response.data.errors || {}); // Asegúrate de que existe
        }
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className="py-3 px-3 content-wrapper">
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2 className="">Actualizar Actividad Curricular</h2>
                </div>
            </div>
            <div className="col-lg-6 offset-lg-3 col-md-12 offset-md-0 grid-margin stretch-card mt-3">
                <div className="card">
                    <div className="card-body">
                        <form className="forms-sample" onSubmit={handleSubmit}>
                            <Form
                                formData={formData}
                                setFormData={setFormData}
                                errors={errors}
                                isEdit={true}
                                setFile={(file) => setFormData(prev => ({ ...prev, archivo: file }))}
                            />
                            <button type="submit" className="btn btn-primary me-2 boton-procesar">
                                <i className="fas fa-save"></i> Actualizar Actividad
                            </button>
                            <Link className="btn btn-warning" to={`/Materias/Contenido/${actividad.id_materiacurso}/${actividad.id_subciclo}`}>Volver</Link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Test;
