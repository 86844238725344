import axios from 'axios';
import React, {useState, useEffect} from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useApiContext  } from '../layout/Template';
import Spinner from '../widget/Spinner';
import Form from './Form';
import moment from 'moment';


const Create = () => {
    const { rubroId } = useParams();
    const [loading, setLoading] = useState(true);
    const { apiUrl, accessToken } = useApiContext();
    const [errors, setErrors] = useState({}); // Estado para errores de validación
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();
    const [rubro, setRubro] = useState(null);

    const [formData, setFormData] = useState({
        tipo: '',
        fechaEmision: moment().format('YYYY-MM-DD'),
        campoAdicional: 'Documento',
    });

    
    const fetchData = async () => {
        const rubroResponse = await axios.get(`${apiUrl}/facturacionv1/create/${rubroId}`, { headers: { Authorization: `Bearer ${accessToken}` } });
        setRubro(rubroResponse.data.rubro);
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleSubmit = async (event) => {
        setIsSubmitting(true); 
        event.preventDefault();// Previene el comportamiento por defecto del formulario

        try {
            const response = await axios.post(`${apiUrl}/facturacionv1/${rubroId}`, formData, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                }
            });
            navigate(`/facturacionv1/${response.data.id}`);
            window.showSuccessPopup(response.data.message);
        } catch (error) {
            const statusCode = error.response?.status;
            const errorMessage = error.response?.data?.error || 'Ocurrió un error inesperado';
            
            if (statusCode !== 422) {
                window.showErrorPopup('Error al procesar la solicitud: ' + errorMessage);
            }else{
                setErrors(error.response.data.errors);
                console.log(error.response.data)
                window.showErrorPopup(error.response.data.message);
            }
        }
        setIsSubmitting(false); 
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className='py-3 px-3 content-wrapper'>
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2 className="">Generar documento</h2>
                </div>
            </div>
            
            <div className="grid-margin stretch-card mt-3">
                <div className="card">
                    <div className="card-body">
                        <form onSubmit={handleSubmit}>
                            <Form
                                rubro={rubro}
                                formData={formData}
                                setFormData={setFormData}
                                errors={errors}
                            />
                            <button 
                                type="submit" 
                                className="btn btn-primary me-2 boton-procesar" 
                                disabled={isSubmitting}
                            >
                                {isSubmitting && (
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                )}
                                {isSubmitting ? 'Guardando...' : <><i className="fas fa-save"></i> Crear Documento</>}
                            </button>
                            <button type="button" className="btn btn-warning" onClick={() => navigate('/movimientos')}>
                                Volver
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Create;
