import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useApiContext } from '../layout/Template';
import { descargarPdf } from '../widget/DescargarPdf'; // Importa la función

const Index = () => {
    const { apiUrl, accessToken } = useApiContext();
    
    // Estados para los campos del formulario
    const [caja, setCaja] = useState('');
    const [user, setUser] = useState('');
    const [formaPago, setFormaPago] = useState('');
    const [fecha, setFecha] = useState('');
    const [horaIni, setHoraIni] = useState('');
    const [horaFin, setHoraFin] = useState('');
    const [cajas, setCajas] = useState([]);
    const [users, setUsers] = useState([]);
    // Estado para almacenar los parámetros de descarga, inicializándolos a "none"
    const [pdfParams, setPdfParams] = useState({
        caja: 'none',
        user: 'none',
        formaPago: 'none',
        fecha: 'none',
        horaIni: 'none',
        horaFin: 'none'
    });

    // Obtener cajas y usuarios al montar el componente
    useEffect(() => {
        const fetchData = async () => {
            const cajasResponse = await axios.get(`${apiUrl}/cajasAdm`, { headers: { Authorization: `Bearer ${accessToken}` } });
            const usersResponse = await axios.get(`${apiUrl}/usersAdm`, { headers: { Authorization: `Bearer ${accessToken}` } });
            setCajas(cajasResponse.data);
            setUsers(usersResponse.data);
        };
        fetchData();
    }, [apiUrl, accessToken]);

    // Función para manejar el envío del formulario
    const handleSubmit = (e) => {
        e.preventDefault();
        // Establecer los parámetros para los PDFs
        setPdfParams({
            caja: caja || 'none',
            user: user || 'none',
            formaPago: formaPago || 'none',
            fecha: fecha || 'none',
            horaIni: horaIni || 'none',
            horaFin: horaFin || 'none'
        });
    };

    const handleClearFilters = () => {
        // Resetear todos los campos del formulario y los parámetros PDF
        setCaja('');
        setUser('');
        setFormaPago('');
        setFecha('');
        setHoraIni('');
        setHoraFin('');
        // Restablecer los parámetros PDF a 'none'
        setPdfParams({
            caja: 'none',
            user: 'none',
            formaPago: 'none',
            fecha: 'none',
            horaIni: 'none',
            horaFin: 'none'
        });
    };
    
    const payload = {
        caja: caja || 'none',
        user: user || 'none',
        formaPago: formaPago || 'none',
        fecha: fecha || 'none',
        horaIni: horaIni || 'none',
        horaFin: horaFin || 'none'
    };

    return (
        <div className='py-3 px-3 content-wrapper'>
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2>Reporte De Flujo De Caja</h2>
                </div>
                <div>
                    <div className="btn-group" role="group" aria-label="Basic outlined example">
                        <div className="btn-group" role="group">
                            <button type="button" className="btn btn-outline-primary btn-sm dropdown-toggle" id="pdfDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="far fa-file-pdf"></i>
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="pdfDropdown">
                                <li>
                                    <button className="dropdown-item" onClick={() => descargarPdf(`${apiUrl}/FlujoCaja/pdf`, payload, 'reporte_flujo_de_caja.pdf', accessToken, null )}>
                                        Exportar
                                    </button>
                                    <button className="dropdown-item" onClick={() => descargarPdf(`${apiUrl}/FlujoCaja/pdf/detallado`, payload, 'reporte_flujo_detallado.pdf', accessToken, null)}>
                                        Detallado
                                    </button>
                                    <button className="dropdown-item" onClick={() => descargarPdf(`${apiUrl}/FlujoCaja/pdf/agrupado`, payload, 'reporte_flujo_agrupado.pdf', accessToken, null)}>
                                        Agrupado
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="accordion py-3" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="accordionExample">
                        <button 
                            className="accordion-button" 
                            type="button" 
                            data-bs-toggle="collapse" 
                            data-bs-target="#collapseOne" 
                            aria-expanded="true" 
                            aria-controls="collapseOne"
                            style={{ 
                                padding: '0.6rem 0.5rem',  /* Relleno reducido */
                                height: 'auto',  /* Ajuste automático de la altura */
                                lineHeight: '1.2',  /* Altura de línea más pequeña para reducir la altura del botón */
                            }}
                        >
                            <i className="fas fa-filter"></i>
                            Filtros
                        </button>
                    </h2>

                    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <form onSubmit={handleSubmit}>
                                <div className="row g-3">
                                    <div className="col-lg-4 col-md-4 col-12 form-group">
                                        <label>Caja</label>
                                        <select className="w-100 form-select" name="caja" value={caja} onChange={(e) => setCaja(e.target.value)}>
                                            <option value="">Todos</option>
                                            {cajas.map(cajaCobro => (
                                                <option key={cajaCobro.id} value={cajaCobro.id}>
                                                    {cajaCobro.num} - {cajaCobro.nombre}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-12 form-group">
                                        <label>Usuario</label>
                                        <select className="w-100 form-select" name="user" value={user} onChange={(e) => setUser(e.target.value)}>
                                            <option value="">Todos</option>
                                            {users.map(usuario => (
                                                <option key={usuario.id} value={usuario.id}>
                                                    {usuario.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-12 form-group">
                                        <label htmlFor="forma_pago" className="form-label">Forma de Pago</label>
                                        <select id="forma_pago" className="form-select" name="forma_pago" value={formaPago} onChange={(e) => setFormaPago(e.target.value)}>
                                            <option value="">Todos</option>
                                            <option value="1">Efectivo</option>
                                            <option value="2">Transferencia</option>
                                            <option value="3">Cheque</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row g-3">
                                    <div className="col-lg-4 col-md-6 col-6">
                                        <label htmlFor="fecha">Fecha</label>
                                        <input type="date" className="form-control" id="fecha" value={fecha} onChange={(e) => setFecha(e.target.value)} />
                                    </div>
                                    <div className="col-lg-4 col-md-3 col-3">
                                        <label htmlFor="horaIni">Desde</label>
                                        <input type="time" className="form-control" id="horaIni" value={horaIni} onChange={(e) => setHoraIni(e.target.value)} />
                                    </div>
                                    <div className="col-lg-4 col-md-3 col-3">
                                        <label htmlFor="horaFin">Hasta</label>
                                        <input type="time" className="form-control" id="horaFin" value={horaFin} onChange={(e) => setHoraFin(e.target.value)} />
                                    </div>
                                </div>
                                <button type="button" onClick={handleClearFilters} className="btn btn-danger text-white mt-2">
                                    <i className="fas fa-window-close"></i> Limpiar Filtros
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
