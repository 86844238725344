import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useApiContext } from '../layout/Template';
import Spinner from '../widget/Spinner';
import Form from './Form';
import { descargarPdf } from '../widget/DescargarPdf';

const Edit = () => {
    const { documentoId } = useParams();
    const [loading, setLoading] = useState(true);
    const { apiUrl, accessToken } = useApiContext();
    const [formData, setFormData] = useState({
        tipo: '',
        fechaEmision: '',
        campoAdicional: '',
        estado: '',
    });
    const [documento, setDocumento] = useState(null);
    const [rubro, setRubro] = useState(null);
    const [errors, setErrors] = useState({}); // Estado para errores de validación
    const navigate = useNavigate();

    const fetchComprobante = async () => {
        try {

            const response = await axios.get(`${apiUrl}/facturacionv1/${documentoId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            setDocumento(response.data.documento);
            const { documento, tipo, claveAcceso, fechaEmision, campoAdicional, estado, } = response.data.documento;
            setFormData({
                tipo: tipo,
                claveAcceso: claveAcceso,
                fechaEmision: fechaEmision,
                campoAdicional: campoAdicional,
                estado: estado,
            });
            setRubro(response.data.rubro);
        } catch (err) {
            window.showErrorPopup('No se recibió respuesta del servidor.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchComprobante();
    }, [documentoId, apiUrl, accessToken]);

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className='py-3 px-3 content-wrapper'>
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2 className="">Consultar Documento</h2>
                </div>
                <div>
                    <div className="btn-group" role="group" aria-label="Basic outlined example">
                        <Link className="btn btn-outline-primary btn-sm" to={documento.pdf} target='_blank'><i className="fas fa-receipt"></i> PDF</Link>
                        {documento.tipo === 2 && (
                            <>
                                <Link className="btn btn-outline-primary btn-sm" to={documento.xml} target='_blank'><i className="fas fa-file-code"></i> XML</Link>
                            </>
                        )}
                        <Link className="btn btn-outline-primary btn-sm text-center" >
                            <i className="fas fa-envelope-open-text"></i> Reenviar Cliente
                        </Link>
                        <Link className="btn btn-outline-primary btn-sm" to={`/facturacionv1/${documentoId}/edit`}><i className="fas fa-edit"></i></Link>
                    </div>
                </div>
            </div>
            
            <div className="grid-margin stretch-card mt-3">
                <div className="card">
                    <div className="card-body">
                        <form>
                            <Form
                                rubro={rubro}
                                formData={formData}
                                setFormData={setFormData}
                                isShow={true}
                                errors={errors}
                            />
                            <Link className="btn btn-primary me-2" to={`/facturacionv1/${documentoId}/edit`}><i className="fas fa-edit"></i> Editar Documento</Link>
                            <Link className="btn btn-warning" to='/facturacionv1'>Volver</Link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Edit;
