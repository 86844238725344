import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import Spinner from './widget/Spinner';
//import useAlert from './widget/useAlert';
import { useApiContext  } from './layout/Template';
import publicidad from '../../assets/publicidad/publicidad_1.jpg'; 

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const { apiUrl, accessToken, user, subdominio } = useApiContext();
  const [info, setInfo] = useState({
                            entregasProximas: [],
                            actividadesRecientes: [],
                          });

  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect( ()=> {
    getDashboard()
    if (subdominio !== 'monteeverest' && subdominio !== 'iberoamericano' && subdominio !== 'capacitaciones') {
      setModalIsOpen(true);
    }
  }, [])

  const getDashboard = async () => {
      try {
          const response = await axios.get(`${apiUrl}/dashboard`, {
              headers: {
                  'Authorization': `Bearer ${accessToken}`,
                  'Accept': 'application/json'
              }
          });
          console.log('Datos recibidos:', response.data); // Imprime los datos recibidos en la consola
          setInfo(response.data);
          setLoading(false); 
      } catch (error) {
          console.error('Error fetching dashboard data', error.response ? error.response.data : error.message);
      }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  // Si aún se están cargando los datos, muestra el spinner
  if (loading) {
      return <Spinner />;
  }

  return (
    <div className="py-3 px-3 content-wrapper">

      <div className={`modal fade ${modalIsOpen ? 'show' : ''}`} style={{ display: modalIsOpen ? 'block' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.5)' }} tabIndex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden={!modalIsOpen}>
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalLabel">¡Mira nuestra oferta!</h5>
              <button type="button" className="btn-close" onClick={closeModal} aria-label="Cerrar"></button>
            </div>
            <div className="modal-body p-0">
              <a href="https://www.facebook.com/share/p/6edCd2mdHxkbrsDW/" target="_blank" rel="noopener noreferrer">
                <img src={publicidad} alt="Publicidad" style={{ width: '100%', height: 'auto' }} />
              </a>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={closeModal}>Cerrar</button>
            </div>
          </div>
        </div>
      </div>
      
      <div className='row'>
        
        {user ? (
            <>
              {/* Mostrar menú si user.estudiante es 1 */}
              {user.estudiante === 1 && (
                <div className="col-md-6 col-lg-6 grid-margin stretch-card">
                  <div className="card card-rounded">
                    <div className="card-body card-rounded">
                      <h4 className="card-title  card-title-dash">Entregas Proximas</h4>
                      <div style={{ maxHeight: '350px', overflowY: 'auto' }}>
                        {info.entregasProximas.map(entrega => (
                          <div key={entrega.id} className="list align-items-center border-bottom py-2">
                            <div className="wrapper w-100">
                              <p className="mb-0 font-weight-medium">
                                  {entrega.titulo ? entrega.titulo.substr(0, 40) : ''}
                              </p>
                              <p className="mb-2 font-weight-medium">
                                {entrega.materia_curso && entrega.materia_curso.materia ? entrega.materia_curso.materia.nombre : 'Materia no disponible'}
                              </p>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                  <i className="fas fa-calendar-alt text-muted me-1"></i>
                                  <p className="mb-0 text-small fs-6">
                                    {entrega.fecha_fin}
                                  </p>
                                </div>
                                <div className="d-flex align-items-center">
                                  {/* Ícono condicional */}
                                  <i className={`fas ${entrega.entregado === 1 ? 'fa-check text-success' : 'fa-eye text-muted'} me-1`}></i>
                                  
                                  <Link 
                                    to={`/Materias/Contenido/Actividades/${entrega.id}`} 
                                    className={`mb-0 text-small fs-6 text-decoration-none ${entrega.entregado === 1 ? 'text-success' : ''}`}
                                  >
                                    Ver Actividad
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
        ) : (
            <p>Cargando..</p>
        )}
        {user ? (
          <>
            {/* Mostrar menú si user.estudiante es 1 */}
            {user.estudiante === 1 && (
              <div className="col-md-6 col-lg-6 grid-margin stretch-card">
                <div className="card card-rounded">
                  <div className="card-body card-rounded">
                    <h4 className="card-title  card-title-dash">Actividades recientes</h4>
                    <div style={{ maxHeight: '350px', overflowY: 'auto' }}>
                      {info.actividadesRecientes.map(actividad => (
                        <div key={actividad.id} className="list align-items-center border-bottom py-2">
                          <div className="wrapper w-100">
                            <p className="mb-0 font-weight-medium">
                                {actividad.titulo ? actividad.titulo.substr(0, 40) : ''}
                            </p>
                            <p className="mb-2 font-weight-medium">
                              {actividad.materia_curso && actividad.materia_curso.materia ? actividad.materia_curso.materia.nombre : 'Materia no disponible'}
                            </p>
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="d-flex align-items-center">
                                <i className="fas fa-calendar-alt text-muted me-1"></i>
                                <p className="mb-0 text-small fs-6">
                                  {actividad.fecha}
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <i className="fas fa-eye text-muted me-1"></i>
                                <Link to={`/Materias/Contenido/Actividades/${actividad.id}`} className="mb-0 text-small fs-6 text-decoration-none">Ver Actividad</Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <p>Cargando..</p>
        )}
      </div>
    </div>
  );
};

const calculateDaysDifference = (fechaFin) => {
  const fechaFinParsed = new Date(fechaFin);
  const hoy = new Date();
  const diferenciaDias = Math.floor((fechaFinParsed - hoy) / (1000 * 60 * 60 * 24));
  return diferenciaDias;
};

export default Dashboard;