import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useApiContext } from '../layout/Template';
import TextInput from '../../form/InputText';

const FormFirma = ({ formData, setFormData, onSuccess  }) => {
    const { apiUrl, accessToken } = useApiContext();
    const [firmaData, setFirmaData] = useState({
        claveFirma: '',
        confirmarClave: '',
        firma: null, // Cambiar a 'firma'
    });

    const [isEditing, setIsEditing] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [errors, setErrors] = useState({}); // Cambiar a un objeto para errores específicos

    useEffect(() => {
        if (formData.claveFirma) {
            setFirmaData((prev) => ({
                ...prev,
                claveFirma: formData.claveFirma,
            }));
        }
    }, [formData.claveFirma]);

    const handleFirmaChange = (e) => {
        const { name, value, files } = e.target;
        setFirmaData((prevState) => ({
            ...prevState,
            [name]: files ? files[0] : value,
        }));
    };

    const handleFirmaSubmit = async (e) => {
        e.preventDefault();
        
        // Limpiar errores previos
        setErrors({});
    
        // Validación: Verificar si las claves coinciden
        if (firmaData.claveFirma !== firmaData.confirmarClave) {
            setErrors({ ...errors, confirmarClave: 'Las claves no coinciden' });
            return;
        }
    
        // Preparar los datos para enviar al backend
        const formDataToSend = new FormData();
        formDataToSend.append('clave', firmaData.claveFirma);
        formDataToSend.append('confirmar_clave', firmaData.confirmarClave);
    
        if (firmaData.firma) {
            formDataToSend.append('firma', firmaData.firma);
        }
    
        try {
            window.showLoaderPopup();
            
            const response = await axios.post(`${apiUrl}/confFact/firma`, formDataToSend, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            console.log('Clave actualizada:', response.data);
            alert('Clave actualizada correctamente');
            setIsEditing(false);
            setFormData((prevState) => ({ ...prevState, claveFirma: firmaData.claveFirma }));
            if (onSuccess) {
                onSuccess();
            }
        } catch (error) {
            console.error('Error al actualizar la clave:', error);
    
            // Verificar si hay errores de validación
            if (error.response && error.response.status === 422) {
                const backendErrors = error.response.data.errors || {};
                setErrors(backendErrors); // Establecer los errores en el estado
            } else {
                setErrors({ general: 'Ocurrió un error al actualizar la clave.' });
            }
        }
    };
    

    const enableEditing = () => {
        setIsEditing(true);
    };

    const togglePasswordVisibility = () => {
        setIsPasswordVisible((prevVisibility) => !prevVisibility);
    };

    const cancelEditing = () => {
        setFirmaData({
            claveFirma: formData.claveFirma || '',
            confirmarClave: '',
            firma: null,
        });
        setIsEditing(false);
    };

    return (
        <div className="card mb-3">
            <div className="card-body">
                <h5>Configuración de Firma</h5>
                <form onSubmit={handleFirmaSubmit}>
                    {/* Input de clave con botón de editar, ver y cancelar */}
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <a
                                href="#!"
                                onClick={togglePasswordVisibility}
                                className="input-group-text btn btn-info text-decoration-none text-light"
                                id="toggle-visibility"
                            >
                                {isPasswordVisible ? "Ocultar" : "Ver"}
                            </a>
                        </div>
                        <input
                            type={isPasswordVisible ? "text" : "password"}
                            name="claveFirma"
                            value={firmaData.claveFirma}
                            onChange={handleFirmaChange}
                            className={`form-control ${errors.clave ? 'is-invalid' : ''}`} // Clave de error
                            disabled={!isEditing}
                        />
                        {errors.clave && (
                            <div className="invalid-feedback">{errors.clave}</div> // Mostrar error específico
                        )}
                        {!isEditing ? (
                            <div className="input-group-prepend">
                                <a
                                    href="#!"
                                    onClick={enableEditing}
                                    className="input-group-text btn btn-primary text-decoration-none text-light"
                                    id="basic-addon1"
                                >
                                    Editar
                                </a>
                            </div>
                        ) : (
                            <div className="input-group-prepend">
                                <a
                                    href="#!"
                                    onClick={cancelEditing}
                                    className="input-group-text btn btn-danger text-decoration-none text-light"
                                >
                                    Cancelar
                                </a>
                            </div>
                        )}
                    </div>

                    {isEditing && (
                        <TextInput
                            name="confirmarClave"
                            value={firmaData.confirmarClave}
                            onChange={handleFirmaChange}
                            placeholder="Confirmar Clave"
                            type={isPasswordVisible ? "text" : "password"}
                            className={`form-control ${errors.confirmar_clave ? 'is-invalid' : ''}`} // Clave de error
                        />
                    )}
                    {errors.confirmar_clave && (
                        <div className="invalid-feedback">{errors.confirmar_clave}</div> // Mostrar error específico
                    )}

                    {/* Input para el archivo de la firma */}
                    <div className="input-group mb-3">
                        <input
                            type="file"
                            name="firma"
                            accept=".p12"
                            onChange={handleFirmaChange}
                            className={`form-control ${errors.firma ? 'is-invalid' : ''}`} // Añadir clase de error
                        />
                        {errors.firma && (
                            <div className="invalid-feedback">{errors.firma}</div> // Mostrar error específico
                        )}
                        {formData.archivoFirma && (
                            <div className="input-group-prepend">
                                <a
                                    href="#!"
                                    onClick={() => window.open(formData.archivoFirma, '_blank')}
                                    className="input-group-text btn btn-primary text-decoration-none text-light"
                                    id="basic-addon1"
                                >
                                    Descargar Archivo
                                </a>
                            </div>
                        )}
                    </div>

                    {/* Mostrar mensaje de error general si existe */}
                    {errors.general && <div className="text-danger">{errors.general}</div>}
                    
                    <button type="submit" className="btn btn-primary">Guardar Clave</button>
                </form>
            </div>
        </div>
    );
};

export default FormFirma;
