import axios from 'axios'
import React, {useState, useEffect} from 'react'
import TextInput from '../../form/InputText';
import { useApiContext  } from '../layout/Template';
import SelectInput from '../../form//SelectInput';

const FormContent = ({ formData, setFormData, errors }) => {
    
    const { apiUrl, accessToken } = useApiContext();
    const [usuarios, setUsuarios] = useState([]);
    const [ptosEmi, setPtosEmi] = useState([]);

    // Función para obtener datos del endpoint /cajas/create
    const getCreate = async () => {
        try {
            const response = await axios.get(`${apiUrl}/cajas/create`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json'
                }
            });
            setUsuarios(response.data.usuarios);
            setPtosEmi(response.data.ptosEmi);
        } catch (error) {
            console.error('Error al obtener los datos de creación:', error.response ? error.response.data : error.message);
        }
    };

    useEffect(() => {
        getCreate(); // Llamada al cargar el componente
    }, []);

    // Definir handleInputChange dentro del componente
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <>
            <div className="col-6">
                <SelectInput
                    id="cajero"
                    name="cajero"
                    value={formData.cajero}
                    onChange={handleInputChange}
                    placeholder="Cajero"
                    options={usuarios.map(usuario => ({
                        value: usuario.id,
                        label: usuario.name
                    }))}
                    error={errors['cajero'] && errors['cajero'][0]}
                />
            </div>
            <div className="mb-3">
                <TextInput
                    label="Nombre"
                    name="nombre"
                    value={formData.nombre}
                    onChange={handleInputChange}
                    placeholder="Nombre"
                    errors={errors}
                />
            </div>
            <div className="mb-3">
                <TextInput
                    label="Numero"
                    name="num"
                    value={formData.num}
                    onChange={handleInputChange}
                    placeholder="Numero"
                    errors={errors}
                />
            </div>
            <div className="col-6">
                <SelectInput
                    id="punto_emision"
                    name="punto_emision"
                    value={formData.punto_emision}
                    onChange={handleInputChange}
                    placeholder="Punto Emision DOCS"
                    options={ptosEmi.map(ptoEmi => ({
                        value: ptoEmi.id,
                        label: `FACT - ${ptoEmi.establecimiento} - ${ptoEmi.pto_emision}`
                    }))}
                    error={errors['punto_emision'] && errors['punto_emision'][0]}
                />
            </div>
        </>
    );
};

export default FormContent;