import React from 'react';
import TextInput from '../../form/InputText';
import SelectInput from '../../form/SelectInput';
import CheckboxInput from '../../form/CheckboxInput';

const FormContent = ({ formData, setFormData, docentes, materias, isEdit, errors }) => {
    // Definir handleInputChange dentro del componente
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCheckboxChange = (field, subField = null) => (e) => {
        const checked = e.target.checked;
        setFormData(prevFormData => {
            if (subField) {
                // Caso para subcampos
                return {
                    ...prevFormData,
                    [field]: {
                        ...prevFormData[field],
                        [subField]: checked
                    }
                };
            } else {
                // Caso para campos en el nivel superior
                return {
                    ...prevFormData,
                    [field]: checked
                };
            }
        });
    };

    return (
        <>
            <SelectInput
                id="id_docente"
                name="id_docente"
                value={formData.id_docente}
                onChange={handleInputChange}
                placeholder="Docente Titular"
                options={docentes.map(docente => ({
                    value: docente.id,
                    label: docente.nombres
                }))}
                error={errors['id_docente'] && errors['id_docente'][0]}
            />
            <SelectInput
                id="id_materia"
                name="id_materia"
                value={formData.id_materia}
                onChange={handleInputChange}
                placeholder="Materia"
                options={materias.map(materia => ({
                    value: materia.id,
                    label: materia.nombre + ' - ' + materia.codigo
                }))}
                error={errors['id_materia'] && errors['id_materia'][0]}
            />
            <TextInput
                label="Orden"
                name="orden"
                value={formData.orden}
                onChange={handleInputChange}
                placeholder="Orden"
                errors={errors}
            />
            <div className="form-check col-4">
                <CheckboxInput 
                    id="cuantitativa"
                    name="cuantitativa"
                    checked={formData.cuantitativa}
                    onChange={handleCheckboxChange('cuantitativa')}
                    label="Cualitativa"
                />
            </div>
            {isEdit && (
                <CheckboxInput 
                    id="estado"
                    name="estado"
                    checked={formData.estado}
                    onChange={handleCheckboxChange('estado')}
                    label="Activo"
                />
            )}
        </>
    );
};

export default FormContent;