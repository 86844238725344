import React, { useState, useEffect, createContext, useContext } from 'react';
//import AppSidebar from './AppSidebar';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import SettingsButton from './SettingsButton';
import NotificationComponent from '../widget/NotificationComponent'; 
import useAxiosInterceptor  from '../widget/useAxiosInterceptor';
import { ToastContainer } from 'react-toastify';
//import '../../../assets/js/template.js';
import $ from 'jquery';
import Swal from 'sweetalert2';

// Crear el contexto con apiUrl y accessToken
const ApiContext = createContext();

export const useApiContext = () => {
    return useContext(ApiContext);
}

const Template = ({ children }) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    
    const hostname = window.location.hostname;
    const subdominio = hostname.split('.')[0];
    const tenantUrl = process.env.REACT_APP_API_TENANT_BASE_URL.replace('tenant', subdominio);

    const apiUrl = tenantUrl;

    useAxiosInterceptor();
    
    const accessToken = localStorage.getItem('accessToken');

    const verifyTokenOnMount = async () => {
        if (!accessToken) {
            navigate('/Login');
            return;
        }

        try {
            // Realizar una solicitud a la ruta de verificación del token
            const response = await axios.get(`${apiUrl}/check-token`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.data.valid) {
                throw new Error('Token no válido');
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                // Token inválido o sesión expirada
                console.error('El token es inválido o ha expirado.');
                localStorage.removeItem('accessToken');
                localStorage.removeItem('user');
                navigate('/Login');
            } else {
                console.error('Error al verificar el token:', error.response ? error.response.data : error.message);
            }
        }
    };

    useEffect(() => {
        const userData = localStorage.getItem('user');
        if (userData) {
            setUser(JSON.parse(userData));
        }
        verifyTokenOnMount();
    }, []);

    const handleSidebarToggle = () => {
        console.log("test")
        setSidebarOpen(!sidebarOpen);
    };

    const closeSidebar = () => {
        setSidebarOpen(false);
    };

    // Función para mostrar alertas de error
    const showErrorPopup = (message) => {
        Swal.fire({
            icon: 'error',
            title: 'Error',
            confirmButtonText: 'Aceptar',
            text: message,
            customClass: {
                popup: 'modal-content modal-sm', // Clase de Bootstrap para el contenido del modal
                title: 'modal-title',     // Clase de Bootstrap para el título del modal
                content: 'modal-body',    // Clase de Bootstrap para el cuerpo del modal
                confirmButton: 'btn btn-danger'  // Clase de Bootstrap para el botón de aceptar
            },
        });
    };

    // Función para mostrar alertas de éxito
    const showSuccessPopup = (message) => {
        Swal.fire({
            icon: 'success',
            title: 'Éxito',
            text: message,
            confirmButtonText: 'Aceptar',
            customClass: {
                popup: 'modal-content modal-sm', // Clase de Bootstrap para el contenido del modal
                title: 'modal-title',     // Clase de Bootstrap para el título del modal
                content: 'modal-body',    // Clase de Bootstrap para el cuerpo del modal
                confirmButton: 'btn btn-primary'  // Clase de Bootstrap para el botón de aceptar
            },
        });
    };
    
    const showLoaderPopup = () => {
        Swal.fire({
            icon: 'info',
            title: 'Cargando...',
            text: 'Por favor, espere.',
            allowOutsideClick: false,
            onOpen: () => {
                Swal.showLoading();
            },
            customClass: {
                popup: 'modal-content modal-sm', // Clase de Bootstrap para el contenido del modal
                title: 'modal-title',     // Clase de Bootstrap para el título del modal
                content: 'modal-body',    // Clase de Bootstrap para el cuerpo del modal
                confirmButton: 'btn btn-primary'  // Clase de Bootstrap para el botón de aceptar
            }
        });
    };

    const hideLoaderPopup = () => {
        Swal.close();
    };

    useEffect(() => {
        window.showErrorPopup = showErrorPopup;
        window.showSuccessPopup = showSuccessPopup;
        window.showLoaderPopup = showLoaderPopup;
        window.hideLoaderPopup = hideLoaderPopup;
        // Agregar la clase sidebar-icon-only por defecto
        $('body').addClass('sidebar-icon-only');

        // Manejar eventos para cambiar las clases en el body
        $('[data-bs-toggle="minimize"], #sidebar').on("click mouseenter mouseleave", function(event) {
            const body = $('body');
            const logoEsquina = $('#logo_esquina');

            if (event.type === 'mouseenter') {
                // Al hacer mouseenter, aplicar la clase sidebar-absolute y ocultar el logo
                body.removeClass('sidebar-icon-only').addClass('sidebar-absolute');
                logoEsquina.hide();
            } else if (event.type === 'mouseleave') {
                // Al salir, revertir las clases y mostrar el logo
                body.removeClass('sidebar-absolute').addClass('sidebar-icon-only');
                logoEsquina.show();
            }
        });

        // Limpiar los event listeners al desmontar el componente
        return () => {
            $('[data-bs-toggle="minimize"], #sidebar').off("click mouseenter mouseleave");
        };
    }, []);
    
    return (
        <div className="container-scroller">
            <NotificationComponent subdominio={subdominio} user={user} />
            <ToastContainer />
            <ApiContext.Provider value={{ apiUrl, accessToken, user, subdominio }}>
                <Navbar handleSidebarToggle={handleSidebarToggle}/>
                <div className="container-fluid page-body-wrapper">
                    <SettingsButton />
                    <Sidebar sidebarOpen={sidebarOpen} closeSidebar={closeSidebar} />
                    <div className="main-panel">
                        {children}
                    </div>
                </div>
            </ApiContext.Provider>
        </div>
    );
}

export default Template; // Asegúrate de tener esta línea al final del archivo
