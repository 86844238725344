import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useApiContext } from '../layout/Template';
import { useNavigate } from 'react-router-dom';
import { useParams, Link } from 'react-router-dom';
import Spinner from '../widget/Spinner';
import logoRect from '../../../assets/img/edusnap/logo_hotizontal.png'; 
import logo_mineud from '../../../assets/img/min_edu2024.png'; 

const Ciclo = () => {
    const { matriculaId, cicloId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const { apiUrl, accessToken, user,subdominio } = useApiContext();
    
    const [estudiante, setEstudiante] = useState(null);
    const [escuela, setEscuela] = useState(null);
    const [ocultarBoletin, setOcultarBoletin] = useState(null);
    const [curso, setCurso] = useState(null);
    const [ciclos, setCiclos] = useState([]);
    const [materias, setMaterias] = useState([]);
    const [conducta, setConducta] = useState([]);
    const [cicloActual, setCicloActual] = useState(null);
    const [subciclos, setSubciclos] = useState([]);
    const [diasLaborados, setLaborados] = useState([]);
    const [promedioFormativo, setPromedioFormativo] = useState(null);
    const [porcentajeFormativo, setPorcentajeFormativo] = useState(null);
    const [promedioExamenes, setPromedioExamenes] = useState(null);
    const [porcentajeExamenes, setPorcentajeExamenes] = useState(null);
    const [promedioProyectos, setPromedioProyectos] = useState(null);
    const [porcentajeProyectos, setPorcentajeProyectos] = useState(null);
    const [porcentajeSumativo, setPorcentajeSumativo] = useState(null);
    const [promedioGeneral, setPromedioGeneral] = useState(null);
    const [cualitativa, setCualitativa] = useState(null);
    // Nuevas variables para asistencias
    const [justificadas, setJustificadas] = useState(0);
    const [injustificadas, setInjustificadas] = useState(0);
    const [atrasos, setAtrasos] = useState(0);

    const logoUrl = escuela?.logo_firmado
        ? `${escuela.logo_firmado}` // Ajusta la URL según tu configuración
        : logoRect;
    
    const fetchData = async () => {
        try {
            const response = await axios.get(`${apiUrl}/boletin/ciclo/${matriculaId}/${cicloId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json'
                }
            });
            const data = response.data;

            setEstudiante(data.estudiante);
            setEscuela(data.escuela);
            setOcultarBoletin(data.ocultarBoletin);
            setCurso(data.curso);
            setCiclos(data.ciclos);
            setMaterias(data.materias);
            setConducta(data.conducta);
            setCicloActual(data.cicloActual);
            setSubciclos(data.subciclos);
            setLaborados(data.diasLaborados);

            // Asignar los nuevos promedios y porcentajes a los estados correspondientes
            setPromedioFormativo(data.promedioFormativo);
            setPorcentajeFormativo(data.porcentajeFormativo);
            setPromedioExamenes(data.promedioExamenes);
            setPorcentajeExamenes(data.porcentajeExamenes);
            setPromedioProyectos(data.promedioProyectos);
            setPorcentajeProyectos(data.porcentajeProyectos);
            setPorcentajeSumativo(data.porcentajeSumativo);
            setPromedioGeneral(data.promedioGeneral);
            setCualitativa(data.cualitativa);

            // Asignar las nuevas variables de asistencia
            setJustificadas(data.justificadas);
            setInjustificadas(data.injustificadas);
            setAtrasos(data.atrasos);

            console.log(data)

        } catch (err) {
            if (err.response) {
                const errorMessage = err.response.data.error;
                const errorCode = err.response.data.code;
                if (errorCode === 403) {
                    // Mostrar el popup de error y redirigir al Dashboard
                    //navigate('/dashboard'); // Cambia '/dashboard' por la ruta real de tu componente Dashboard
                    window.showErrorPopup(errorMessage);
                    return;
                }
                window.showErrorPopup(errorMessage);
            } else if (err.request) {
                window.showErrorPopup('No se recibió respuesta del servidor.');
            } else {
                window.showErrorPopup('Error al configurar la solicitud: ' + err.message);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (user && user.estudiante === 1 && ocultarBoletin === 1) {
        window.showErrorPopup("Boletines Inhabilitados");
        return;
    }

    const generarPDF = async () => {
        console.log("procesando");
        try {
            const response = await axios.get(`${apiUrl}/boletin/ciclo/pdf/${matriculaId}/${cicloId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/pdf', // Asegúrate de que la respuesta sea un PDF
                },
                responseType: 'blob' // Importante para manejar la respuesta como archivo binario
            });
    
            // Crear un enlace de descarga
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
    
            // Forma el nombre del archivo con un formato seguro para nombres de archivo
            const nombreArchivo = `Trimestral ${estudiante.estudiante.nombres}.pdf`;
            link.setAttribute('download', nombreArchivo);
            document.body.appendChild(link);
            link.click();
    
            // Liberar el objeto URL y eliminar el enlace del DOM
            window.URL.revokeObjectURL(url);
            link.remove();
        } catch (err) {
            console.error('Error al descargar el PDF:', err);
            window.showErrorPopup('Error al descargar el archivo PDF.');
        }
        console.log("fin");
    };

    if (loading) {
        return <Spinner />;
    }
    
    return (
        <div className="py-3 px-3 content-wrapper">
            <style>
                {`
                    .boletin {
                        padding: 6px;
                        border: 1px solid black;
                        font-size: 13px;
                    }
                    
                    .bold {
                        font-weight: bold;
                    }
                    .mayusculas{
                        text-transform: uppercase;
                    }
                    .firma-container {
                        display: flex;
                        justify-content: space-between;
                        margin: 20px;
                    }
            
                    .firma {
                        width: 200px;
                        border-top: 1px solid #000;
                        margin-top: 10px;
                        padding-top: 10px;
                        text-align: center;
                    }
            
                    .firma-name {
                        font-weight: bold;
                    }
            
                    .firma-title {
                        margin-top: 5px;
                    }
                `}
            </style>
            <button className="btn btn-primary" onClick={generarPDF}>
                <i className="fas fa-file-pdf"></i> <span className="d-none d-lg-inline"> Generar PDF</span>
            </button>
            <div className='m-3' style={{ padding: "16px 16px 2px 16px", border: "2px solid", display: "flow-root", backgroundColor: "white" }}>
                <div>
                    <table cellPadding="0" cellSpacing="0" width="100%">
                        <tbody>
                            <tr>
                                <td style={{ width: '25%', textAlign: 'center' }}>
                                    <img src={logoUrl} alt="Logo" width="100" />
                                </td>
                                <td style={{ textAlign: 'center' }}> {/* Cambiado a un objeto */}
                                    <div style={{ fontSize: '17px' }}><strong>{escuela.nombre}</strong></div>
                                    <div style={{ fontSize: '19px' }} className="mayusculas">
                                        <strong> INFORME DE RENDIMIENTO ACADÉMICO</strong>
                                    </div>
                                    <div style={{ fontSize: '14px' }}>
                                        Correo: {escuela.correo}
                                    </div>
                                    <div className="d-sm-flex justify-content-between align-items-start px-5" style={{ fontSize: '14px' }}>
                                        <div>Cantón: {escuela.canton.nombre}</div>
                                        <div>Parroquia: {escuela.parroquia}</div>
                                    </div>
                                </td>
                                <td style={{ width: '25%', textAlign: 'center' }}>
                                    <img src={logo_mineud} alt="" width="120"/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br/>
                    <table
                        style={{
                            fontSize: '14px',
                            textAlign: 'center',
                            borderCollapse: 'collapse',
                            width: '100%',
                        }}
                        border="1"
                    >
                        <tbody className="mayusculas">
                            <tr>
                                <th className="boletin">NOMBRES:</th>
                                <td className="boletin">{estudiante.estudiante.nombres}</td>
                                <th className="boletin">AÑO LECTIVO:</th>
                                <td className="boletin">{curso.periodo.descripcion}</td>
                            </tr>
                            <tr>
                                <th className="boletin">CURSO:</th>
                                <td className="boletin" style={{ fontSize: '13px', fontWeight: 'bold' }}>
                                    {curso.nivel.nombre} {curso.tipo.alias} {curso.seccion.descripcion}
                                </td>
                                <th className="boletin" colSpan="2">
                                    {cicloActual.descripcion}
                                </th>
                            </tr>
                            <tr>
                                <th className="boletin" colSpan="2">Docente:</th>
                                <td className="boletin" colSpan="2">
                                    {curso.tutor.nombres}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br/>
                    <div
                        style={{
                            overflowX: 'scroll',
                            overflowX: 'visible',
                            paddingBottom: '5px',
                            width: 'auto'
                        }}
                    >
                        <table cellpadding="0" cellspacing="0" width="100%" border="1">
                            <tbody>
                                <tr>
                                    <td colspan="1" rowspan="2" className="boletin text-center bold"> Materias/Asignaturas </td>
                                    <td colspan="11" className="boletin text-center bold"> {cicloActual.descripcion} </td>
                                </tr>
                                <tr className="mayusculas">
                                    <th className="boletin text-center">Formativos (Parciales)</th>
                                    <th className="boletin text-center">70%</th>
                                    <th className="boletin text-center" colSpan={2}>Sumativo (Examen)</th>
                                    <th className="boletin text-center">30%</th>
                                    <th className="boletin text-center">PROMEDIO</th>
                                    <th className="boletin text-center">Cualitativa</th>
                                </tr>
                                {materias.map((materia) => {
                                    return (
                                        <tr key={materia.id}>
                                            <td className='boletin'>{materia.nombre}</td>
                                            <td className="boletin text-center">{materia.formativo}</td>
                                            <td className="boletin text-center">{materia.porcFormativo}</td>
                                            <td className="boletin text-center" colSpan={materia.proyecto ? 1 : 2}>{materia.notaExamen}</td>
                                            {materia.proyecto && (
                                                <td className="boletin text-center">{materia.notaProyecto}</td>
                                            )}
                                            <td className="boletin text-center">{materia.porcSumativo}</td>
                                            <td className="boletin text-center">{materia.promedio}</td>
                                            <td className="boletin text-center">{materia.cualitativa}</td>
                                        </tr>
                                    );
                                })}
                                <tr>
                                    <td className="boletin contenidobody bold promedio">PROMEDIO GENERAL</td>
                                    <td className="boletin contenidobody text-center bold promedio">
                                        { promedioFormativo }
                                    </td>
                                    <td className="boletin contenidobody text-center bold promedio">
                                        { porcentajeFormativo }
                                    </td>
                                    <td className="boletin contenidobody text-center bold promedio" colSpan={2}>
                                        -
                                    </td>
                                    <td className="boletin contenidobody text-center bold promedio">
                                        { porcentajeSumativo }
                                    </td>
                                    <td className="boletin contenidobody text-center bold promedio">
                                        { promedioGeneral }
                                    </td>
                                    <td className="boletin contenidobody text-center bold promedio">
                                        { cualitativa }
                                    </td>
                                </tr>

                                <tr>
                                    <td className="boletin contenidobody bold">
                                            COMPORTAMIENTO
                                    </td>
                                    <td colspan="10" className="boletin contenidobody text-center bold">
                                        { conducta }
                                    </td>
                                </tr>
                                <tr>
                                    <td className="boletin contenidobody">
                                    Días Laborados
                                    </td>
                                    <td colspan="10" className="boletin contenidobody text-center">
                                        {diasLaborados}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="boletin contenidobody">
                                    Faltas Injustificadas
                                    </td>
                                    <td colspan="10" className="boletin contenidobody text-center">
                                        {injustificadas}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="boletin contenidobody">
                                        Atrasos
                                    </td>
                                    <td colspan="10" className="boletin contenidobody text-center">
                                        {atrasos}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="boletin contenidobody">
                                        Faltas Justificadas
                                    </td>
                                    <td colspan="10" className="boletin contenidobody text-center">
                                        {justificadas}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br/>
                    <table cellpadding="0" cellspacing="0" border="1" width="100%">       
                        <tbody>
                            <tr>
                                <td className="boletin" valign="top">
                                    <center><strong>ESCALA DE APROVECHAMIENTO</strong></center> 
                                    <div>
                                        <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                            <tbody>
                                                {curso.inicial === 1 ? (
                                                    <>
                                                    <tr>
                                                        <td style={{width: "80%;"}}><div className="escalas">A - Destrezas o Aprendizajes Alcanzados.</div></td>
                                                        <td><div className="escalas">(9.00 a 10.00)</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{width: "80%;"}}><div className="escalas">EP - Destrezas o aprendizajes en Proceso de Desarrollo.</div></td>
                                                        <td><div className="escalas">(7.00 a 8.99)</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{width: "80%;"}}> <div className="escalas"> I - Destrezas o Aprendizaje Iniciado. </div></td>
                                                        <td><div className="escalas">(4.01 a 6.99)</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{width: "80%;"}}> <div className="escalas"> N.E. - Destrezas o Aprendizaje no Alcanzados. </div></td>
                                                        <td><div className="escalas">(0.00 a 4.00)</div></td>
                                                    </tr>
                                                    </>
                                                ) : (
                                                    <>
                                                    <tr>
                                                        <td style={{width: "80%;"}}><div className="escalas">DAR - Domina los aprendizajes requeridos.</div></td>
                                                        <td><div className="escalas">(9.00 a 10.00)</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{width: "80%;"}}><div className="escalas">AAR - Alcanza los aprendizajes requeridos.</div></td>
                                                        <td><div className="escalas">(7.00 a 8.99)</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{width: "80%;"}}> <div className="escalas"> PAAR - Está próximo a alcanzar los aprendizajes requeridos. </div></td>
                                                        <td><div className="escalas">(4.01 a 6.99)</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{width: "80%;"}}> <div className="escalas"> NAAR - No alcanza los aprendizajes requeridos. </div></td>
                                                        <td><div className="escalas">(0.00 a 4.00)</div></td>
                                                    </tr>
                                                    </>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="boletin">
                                    <center><strong>ESCALA COMPORTAMIENTO</strong></center>
                                    <div>
                                        S - Siempre idera el cumplimiento de los compromisos establecidos para la sana convivencia social. <br/>
                                        F - Frecuentemente cumple con los compromisos establecidos para la sana convivencia social. <br/>
                                        O - Ocasionalmente falla en el cumplimiento de los compromisos establecidos para la sana convivencia social. <br/>
                                        N - No cumple con los compromisos establecidos para la sana convivencia social. <br/>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br/>
                    <div className="firma-container">
                        <div className="firma">
                            <div className="firma-line"></div>
                            <div className="firma-name">{escuela.rector}</div>
                            <div className="firma-title">Diector/a</div>
                        </div>
                        <div className="firma">
                            <div className="firma-line"></div>
                            <div className="firma-name">{curso.tutor.nombres}</div>
                            <div className="firma-title">Tutor/a</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Ciclo;
