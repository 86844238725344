import axios from 'axios'
import React, {useState, useEffect} from 'react'
import { useNavigate, useParams , Link } from 'react-router-dom'
import { useApiContext  } from '../layout/Template';
import Spinner from '../widget/Spinner';
import FormContent from './FormContent';

const Create = () => {
    const [loading, setLoading] = useState(true);
    const { apiUrl, accessToken } = useApiContext();
    const [formData, setFormData] = useState({
        id_docente: '',
        id_materia: '',
        cuantitativa: false,
        estado: true,
        orden: '',
    });
    const [docentes, setDocentes] = useState([]);
    const [materias, setMaterias] = useState([]);
    const [errors, setErrors] = useState({}); // Estado para errores de validación
    const navigate = useNavigate();
    const {cursoId} = useParams();
    
    useEffect(() => {
        const getDatos = async () => {
            try {
                const response = await axios.get(`${apiUrl}/materiacurso/create/${cursoId}`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Accept': 'application/json',
                    }
                });
                setDocentes(response.data.docentes);
                setMaterias(response.data.materias);
                // Actualizar formData con el maxOrden obtenido
                setFormData(prevFormData => ({
                    ...prevFormData, // Mantenemos los demás valores intactos
                    orden: response.data.maxOrden // Sobreescribimos maxOrden
                }));
                setLoading(false);
            } catch (error) {
                console.error('Error al obtener el curso:', error.response ? error.response.data : error.message);
                setLoading(false); // Desactivar el cargador en caso de error
            }
        };
        getDatos();
    }, [apiUrl, accessToken, cursoId]);
    
    const update = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(`${apiUrl}/materiacurso/${cursoId}`, formData, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            });
            navigate(`/MisCursos/Info/${cursoId}`);
            window.showSuccessPopup(response.data.message);
        } catch (error) {
            // Aquí capturamos el error
            if (error.response && error.response.status === 500) {
                // Mostrar el mensaje de error usando el método window.showErrorPopup
                window.showErrorPopup(error.response.data.message);
            } else {
                // Otras respuestas de error (no 500) o errores de conexión
                console.error('Error al asignar la materia:', error.message);
            }
        }
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className='py-3 px-3 content-wrapper'>
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2 className="">Añadir Materia</h2>
                </div>
            </div>
            <div className="col-md-6 offset-md-3 grid-margin stretch-card mt-3">
                <div className="card">
                    <div className="card-body">
                        <form className="forms-sample" onSubmit={update}>
                            <FormContent
                                formData={formData}
                                setFormData={setFormData}
                                docentes={docentes}
                                materias={materias}
                                isEdit={false}
                                errors={errors}
                            />
                            <button type="submit" className="btn btn-primary me-2"><i className="fas fa-save"></i> Añadir Materia</button>
                            <Link className="btn btn-warning" to={`/MisCursos/Info/${cursoId}`}>Volver</Link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Create;
