import React, { useState } from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom'
import { useApiContext } from '../layout/Template';
import TextInput from '../../form/InputText';
import Swal from 'sweetalert2';

const FormEstablecimientos = ({ formData, setFormData, establecimientos, puntos_emision, setErrors, errors, onSuccess }) => {
    const { apiUrl, accessToken } = useApiContext();
    const [ptoEmision, setPtoEmision] = useState('');
    const [tipoDoc, setTipoDoc] = useState('');
    const [selectedEstablecimiento, setSelectedEstablecimiento] = useState('');

    const handlePtoEmisionChange = (e) => {
        setPtoEmision(e.target.value);
    };

    const handleTipoDocChange = (e) => {
        setTipoDoc(e.target.value);
    };

    const handleSelectedEstablecimientoChange = (e) => {
        setSelectedEstablecimiento(e.target.value);
    };

    const handlePuntoEmisionSubmit = async (e) => {
        e.preventDefault();
    
        const newPuntoEmision = {
            pto_emision: ptoEmision,
            tipo_doc: tipoDoc,
            establecimiento: selectedEstablecimiento,
        };
    
        try {
            window.showLoaderPopup();
            const response = await axios.post(`${apiUrl}/confFact/emision`, newPuntoEmision, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });
            setErrors({});
            window.showSuccessPopup(response.data.message);
            if (onSuccess) {

                // Cerrar el modal eliminando las clases de Bootstrap manualmente
                const modal = document.getElementById('exampleModal');
                modal.classList.remove('show');
                modal.setAttribute('aria-hidden', 'true');
                modal.style.display = 'none';
                
                // Eliminar el fondo (backdrop)
                const backdrop = document.querySelector('.modal-backdrop');
                if (backdrop) {
                    backdrop.remove();
                }
                onSuccess();
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.errors) {
                setErrors(error.response.data.errors); // Actualiza los errores específicos
            } else {
                setErrors({ general: 'Error inesperado al enviar los datos.' }); // Error genérico
            }
            console.error('Error al enviar Punto de Emisión:', error.response ? error.response.data : error.message);
        }
    };
    
    const handleDelete = async (id) => {
        // Mostrar el cuadro de confirmación
        const result = await Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás recuperar esta configuración después de eliminarlo!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
        });
    
        // Si el usuario confirma la eliminación
        if (result.isConfirmed) {
            try {
                await axios.delete(`${apiUrl}/confFact/emision/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Accept': 'application/json',
                    },
                });
                if (onSuccess) {
                    onSuccess();
                }
                Swal.fire({
                    icon: 'success',
                    title: 'Eliminado',
                    text: 'La configuración ha sido eliminado correctamente.',
                });
            } catch (error) {
                console.error('Error deleting configuración:', error.response ? error.response.data : error.message);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'No se pudo eliminar el configuración. Por favor, inténtelo de nuevo.',
                });
            }
        }
    };

    return (
        <div className="card mb-3">
            <div className="card-body">
                {/* Botón para abrir el modal */}
                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Añadir Punto de Emisión
                </button>
                <div className="my-2 table-responsive">
                    <table>
                        <tbody>
                            {puntos_emision.map(ptoEmi => (
                                <tr>
                                    <td>{ptoEmi.tipo_doc === '01' ? 'Factura' : 'N/A'}</td>
                                    <td><strong className="ms-2">{ptoEmi.establecimiento} - {ptoEmi.pto_emision}</strong></td>
                                    <td><Link to="#" onClick={()=>handleDelete(ptoEmi.id)} className="links text-danger ms-2"><i className="fas fa-trash-alt"></i></Link></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Modal para añadir punto de emisión */}
                <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Nuevo Punto de Emisión</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <form onSubmit={handlePuntoEmisionSubmit}>
                                <div className="modal-body row">
                                    <div className="form-group col-lg-4 col-sm-12">
                                        <label htmlFor="tipo_doc">Tipo Documento</label>
                                        <select
                                            className={`form-select ${errors.tipo_doc ? 'is-invalid' : ''}`}  // Agregar clase si hay un error
                                            id="tipo_doc"
                                            value={tipoDoc}
                                            onChange={handleTipoDocChange}
                                        >
                                            <option value="">Asignar Tipo Documento</option>
                                            <option value="01">Factura</option>
                                        </select>
                                        {errors.tipo_doc && (
                                            <div className="invalid-feedback">{errors.tipo_doc[0]}</div>  // Mostrar error específico
                                        )}
                                    </div>
                                    
                                    <div className="form-group col-lg-4 col-sm-12">
                                        <label htmlFor="establecimiento">Establecimiento</label>
                                        <select
                                            className={`form-select ${errors.establecimiento ? 'is-invalid' : ''}`}  // Agregar clase si hay un error
                                            id="establecimiento"
                                            value={selectedEstablecimiento}
                                            onChange={handleSelectedEstablecimientoChange}
                                        >
                                            <option value="">Asignar Establecimiento</option>
                                            {establecimientos.map((estab, index) => (
                                                <option key={index} value={estab}>{estab}</option>
                                            ))}
                                        </select>
                                        {errors.establecimiento && (
                                            <div className="invalid-feedback">{errors.establecimiento[0]}</div>  // Mostrar error específico
                                        )}
                                    </div>
                                    
                                    <div className="form-group col-lg-4 col-sm-12">
                                        <label htmlFor="pto_emision">Punto de Emisión</label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.pto_emision ? 'is-invalid' : ''}`}  // Agregar clase si hay un error
                                            id="pto_emision"
                                            value={ptoEmision}
                                            onChange={handlePtoEmisionChange}
                                            placeholder="Punto de Emisión"
                                            maxLength="3"
                                        />
                                        {errors.pto_emision && (
                                            <div className="invalid-feedback">{errors.pto_emision[0]}</div>  // Mostrar error específico
                                        )}
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-primary">Guardar</button>
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Cancelar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormEstablecimientos;
