import axios from 'axios'
import React, {useEffect, useState} from 'react'
import { useApiContext  } from '../layout/Template';
import DatosGeneralesContext from '../widget/DatosGeneralesContext';
import TextInput from '../../form/InputText';
import SelectInput from '../../form//SelectInput';
import CheckboxInput from '../../form//CheckboxInput';
import QuillEditor from '../../form/QuillEditor';
import FileInput from '../../form/FileInput';
import DateInput from '../../form/DateInput';
import DateTimeInput from '../../form/DateTimeInput';

const FormContent = ({ formData, setFormData, isEdit = false, errors }) => {
    const { apiUrl } = useApiContext();
    const { tiposElementos } = DatosGeneralesContext(apiUrl);
    
    // Definir handleInputChange dentro del componente
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCheckboxChange = (field, subField = null) => (e) => {
        const checked = e.target.checked;
        setFormData(prevFormData => {
            if (subField) {
                // Caso para subcampos
                return {
                    ...prevFormData,
                    [field]: {
                        ...prevFormData[field],
                        [subField]: checked
                    }
                };
            } else {
                // Caso para campos en el nivel superior
                return {
                    ...prevFormData,
                    [field]: checked
                };
            }
        });
    };

    const handleFileChange = (fileItems, name) => {
        // Extraer el archivo de fileItems y establecer en el estado
        const file = fileItems.length > 0 ? fileItems[0].file : null;
        setFormData((prevState) => ({
            ...prevState,
            [name]: file, // Usa el nombre del input para actualizar el estado
        }));
    };

    return (
        <>
            <TextInput
                name="titulo"
                value={formData.titulo}
                onChange={handleInputChange}
                placeholder="Titulo"
                errors={errors}
            />
            <TextInput
                name="resumen"
                value={formData.resumen}
                onChange={handleInputChange}
                placeholder="Resumen"
                errors={errors}
            />
            <QuillEditor
                name="descripcion"
                id="editor"
                value={formData.descripcion}
                onChange={handleInputChange}
                errors={errors}
            />
            <TextInput
                type="url"
                name="url"
                value={formData.url}
                onChange={handleInputChange}
                placeholder="URL"
                errors={errors}
            />
            {!isEdit && (
                <div className="mb-3">
                    <label>Tipo de Insumo</label>
                    <div className='ms-4'>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="tipo"
                                id="tipo"
                                value="actividad"
                                checked={formData.tipo === "actividad"}
                                onChange={handleInputChange}
                            />
                            <label className="form-check-label" htmlFor="tipo">
                                Actividad
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="tipo"
                                id="tipoEvaluacion"
                                value="evaluacion"
                                checked={formData.tipo === "evaluacion"}
                                onChange={handleInputChange}
                            />
                            <label className="form-check-label" htmlFor="tipoEvaluacion">
                                Evaluación (Aporte)
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="tipo"
                                id="tipoExamen"
                                value="examen"
                                checked={formData.tipo === "examen"}
                                onChange={handleInputChange}
                            />
                            <label className="form-check-label" htmlFor="tipoExamen">
                                Examen (Trimestral)
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="tipo"
                                id="tipoProyecto"
                                value="proyecto"
                                checked={formData.tipo === "proyecto"}
                                onChange={handleInputChange}
                            />
                            <label className="form-check-label" htmlFor="tipoProyecto">
                                Proyecto (Trimestral)
                            </label>
                        </div>
                    </div>
                </div>
            )}
            {formData.tipo === "actividad" && (
                <SelectInput
                    id="id_tipoelemento"
                    name="id_tipoelemento"
                    value={formData.id_tipoelemento}
                    onChange={handleInputChange}
                    placeholder="Tipo de elemento"
                    options={tiposElementos.map(tipo => ({
                        value: tipo.id,
                        label: tipo.descripcion
                    }))}
                    error={errors['id_tipoelemento'] && errors['id_tipoelemento'][0]}
                />
            )}
            <CheckboxInput 
                id="entregas"
                name="entregas"
                checked={formData.entregas}
                onChange={handleCheckboxChange('entregas')}
                label="Tarea Virtual"
            />
            {formData.entregas && (
                <CheckboxInput 
                    id="foro"
                    name="foro"
                    checked={formData.foro}
                    onChange={handleCheckboxChange('foro')}
                    label="Foro"
                />
            )}
            <div className='row'>
                <div className="col-md-4 col-sm-12 mb-3">
                    <DateInput
                        label="Fecha"
                        name="fecha"
                        value={formData.fecha}
                        onChange={handleInputChange}
                        placeholder="Fecha"
                        isDateTime={true}
                        errors={errors}
                    />
                </div>
                {formData.entregas && (
                    <>
                    <div className="col-md-4 col-sm-12 mb-3">
                        <DateTimeInput
                            label="Fecha Inicio"
                            name="fecha_inicio"
                            value={formData.fecha_inicio}
                            onChange={handleInputChange}
                            placeholder="Fecha y hora"
                            errors={errors}
                            disabled={false}
                            divClass={true}
                        />
                    </div>
                    <div className="col-md-4 col-sm-12 mb-3">
                        <DateTimeInput
                            label="Fecha Cierre"
                            name="fecha_fin"
                            value={formData.fecha_fin}
                            onChange={handleInputChange}
                            placeholder="Fecha y hora"
                            errors={errors}
                            disabled={false}
                            divClass={true}
                        />
                    </div>
                    </>
                )}
            </div>
            <FileInput
                label="Archivo"
                name="archivo"
                onChange={(files) => handleFileChange(files, 'archivo')} // Pasar el nombre
                errors={errors['archivo']}
                maxFiles={1}
                multiple={false}
                instantUpload={false}
                maxSize={50 * 1024 * 1024}
                fileUrl={isEdit && formData.archivo ? formData.archivo : null}
                isEdit={isEdit}
            />
        </>
    );
};

export default FormContent;