import axios from 'axios';
import React, { useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Spinner from '../widget/Spinner';
import { useApiContext } from '../layout/Template';
import moment from 'moment';
//componnentes
import Form  from './Form';

const Create = () => {
    const { materiaId, parcialId } = useParams();
    const { apiUrl, accessToken, user } = useApiContext(); // Ajusta según tu contexto API
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const endpoint = `${apiUrl}/actividad`;
    const [isSubmitting, setIsSubmitting] = useState(false);
    //variables
    const [formData, setFormData] = useState({
        titulo: '',
        resumen: '',
        descripcion: '',
        url: '',
        id_tipoelemento: '',
        fecha: moment().format('YYYY-MM-DD'),
        fecha_inicio: moment().format('YYYY-MM-DDTHH:mm'),
        fecha_fin: moment().add(1, 'weeks').format('YYYY-MM-DDTHH:mm'),
        archivo: null,
        entregas: true,
        foro: false,
        tipo: "actividad",
        materiaId: materiaId,
        parcialId: parcialId,
    });

    const handleSubmit = async (event) => {
        setIsSubmitting(true); 
        event.preventDefault();
        const formDataWithFile = new FormData();
        Object.keys(formData).forEach((key) => {
            formDataWithFile.append(key, formData[key]);
        });

        // Si hay un archivo, también añadirlo
        if (formData.archivo) {
            formDataWithFile.append('archivo', formData.archivo);
            console.log(formData.archivo)
        }else{
            formDataWithFile.append('archivo', '');
        }
        try {
            const response = await axios.post(endpoint, formDataWithFile, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                }
            });
            navigate(`/Materias/Contenido/Actividades/${response.data.id}`);
            window.showSuccessPopup(response.data.message);
        } catch (error) {
            setErrors(error.response.data.errors || {}); // Asegúrate de que existe
        }
        setIsSubmitting(false); 
    };

    if (loading) {
        return <Spinner />;
    }
    
    return (
        <div className="py-3 px-3 content-wrapper">
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2 className="">Crear Actividad Curricular</h2>
                </div>
            </div>
            
            <div className="col-md-6 offset-md-3 grid-margin stretch-card mt-3">
                <div className="card">
                    <div className="card-body">
                        <form className="forms-sample" onSubmit={handleSubmit}>
                            <Form
                                formData={formData}
                                setFormData={setFormData}
                                errors={errors}
                                setFile={(file) => setFormData(prev => ({ ...prev, archivo: file }))}
                            />
                            <button 
                                type="submit" 
                                className="btn btn-primary me-2 boton-procesar" 
                                disabled={isSubmitting}
                            >
                                {isSubmitting && (
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                )}
                                {isSubmitting ? 'Guardando...' : <><i className="fas fa-save"></i> Crear Actividad</>}
                            </button>
                            <Link className="btn btn-warning" to={`/Materias/Contenido/${materiaId}/${parcialId}`}>Volver</Link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Create;
