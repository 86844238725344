import axios from 'axios';
import React, { useEffect, useState, useRef  } from 'react';
import { useParams, Link } from 'react-router-dom';
import Spinner from '../widget/Spinner';
import FileViewer from '../widget/FileViewer';
import { validateAndFormatValue } from '../widget/ValidateAndFormatValue';
import { useApiContext } from '../layout/Template';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

const Show = () => {
    const { claseId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const { apiUrl, accessToken, user } = useApiContext();
    const [clase, setClase] = useState(null);
    const endpoint = `${apiUrl}/clase/${claseId}`;
    const [materia, setMateria] = useState(null);
    const [archivo_firmado, setaAchivo_firmado] = useState(null);
    const [archivo, setArchivo] = useState(null);
    const [comentario, setComentario] = useState('');
    const [realizada, setRealizada] = useState(false);
    const [estudiantes, setEstudiantes] = useState([]);
    const [filtroNombres, setFiltroNombres] = useState('');
    const [filtroAsistencia, setFiltroAsistencia] = useState('');
    const [asistencias, setAsistencias] = useState(estudiantes.map(estudiante => estudiante.asistencia || ''));
    const [asistencia, setAsistencia] = useState({});
    const [participacion, setParticipacion] = useState({});

    useEffect(() => {
        // Inicializar el estado con los datos de los estudiantes
        const inicializarDatos = () => {
            const asistenciaInicial = estudiantes.reduce((acc, estudiante) => {
                acc[estudiante.id] = estudiante.asistencia || '';
                return acc;
            }, {});
            const participacionInicial = estudiantes.reduce((acc, estudiante) => {
                acc[estudiante.id] = estudiante.participacion || 0;
                return acc;
            }, {});
            setAsistencia(asistenciaInicial);
            setParticipacion(participacionInicial);
        };

        inicializarDatos();
    }, [estudiantes]);

    const handleAsistenciaChange = (id, value) => {
        setAsistencia(prev => ({ ...prev, [id]: value }));
    };

    const handleParticipacionChange = (id, checked) => {
        setParticipacion(prev => ({ ...prev, [id]: checked ? 1 : 0 }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        // Preparar los datos para enviar
        const dataToSend = {
            asistencia,
            participacion,
            comentario: document.querySelector('textarea[name="comentario"]').value,
            realizada: document.querySelector('input[name="realizada"]').checked ? 'true' : 'false' 
        };
    
        try {
            // Enviar los datos al backend
            const response = await axios.put(endpoint, dataToSend, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json' // Asegúrate de que el Content-Type sea correcto
                }
            });
            window.showSuccessPopup(response.data.message);
        } catch (error) {
            // Manejar errores
            console.error('Error:', error.response ? error.response.data : error.message);
            // Puedes añadir una notificación de error aquí
            // window.showErrorPopup('Error al actualizar los datos.');
        }
    };
    
    const fetchClase = async () => {
        try {
            const response = await axios.get(endpoint, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json'
                }
            });
            const data = response.data;
            setClase(data.clase);
            setMateria(data.materia);
            setaAchivo_firmado(data.archivo_firmado);
            setComentario(data.clase.comentario || '');
            setRealizada(data.clase.realizada || false);
            setArchivo(data.archivo);
            setEstudiantes(data.estudiantes);
            console.log(data.estudiantes);
            //window.showSuccessPopup('Datos del curso cargados exitosamente.');
        } catch (err) {
            if (err.response) {
                const errorMessage = err.response.data.error;
                const errorCode = err.response.data.code;
                if (errorCode === 403) {
                    // Mostrar el popup de error y redirigir al Dashboard
                    navigate('/dashboard'); // Cambia '/dashboard' por la ruta real de tu componente Dashboard
                    window.showErrorPopup(errorMessage);
                    return;
                }
                window.showErrorPopup(errorMessage);
            } else if (err.request) {
                window.showErrorPopup('No se recibió respuesta del servidor.');
            } else {
                window.showErrorPopup('Error al configurar la solicitud: ' + err.message);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchClase();
    }, [claseId, apiUrl, accessToken]);

    const estudiantesFiltrados = estudiantes.sort((a, b) => 
        a.nombres.localeCompare(b.nombres)
    );

    if (loading) {
        return <Spinner />;
    }

    // Función para extraer el ID de YouTube desde la URL
    const getYouTubeVideoId = (url) => {
        const regex = /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        const match = url.match(regex);
        return (match && match[1]) ? match[1] : null;
    };

    const videoId = clase.url ? getYouTubeVideoId(clase.url) : null;
    
    return (
        <div className="py-3 px-3 content-wrapper">
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2>Materia</h2>
                </div>
                <div>
                    <div className="btn-group mb-2" role="group">
                        <Link className="btn btn-warning btn-sm" to={`/Materias/Contenido/${clase.id_materiacurso}/${clase.id_subciclo}`}><i className="fas fa-chevron-circle-left"></i> <span className="d-none d-lg-inline"> Volver</span></Link>
                        {user.estudiante !== 1 && materia.id_docente === user.id_docente && (
                            <>
                                <Link to={`/Materias/Contenido/Clase/edit/${claseId}`} className="btn btn-primary btn-sm"><i className="fas fa-edit"></i> <span className="d-none d-lg-inline"> Editar</span></Link>
                                <Link to={`/Materias/Contenido/Actividades/create/${clase.id_materiacurso}/${clase.id_subciclo}`} className="btn btn-info btn-sm"><i class="fas fa-tasks"></i> <span className="d-none d-lg-inline"> Añadir Actividad</span></Link>
                            </>
                        )}
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="grid-margin grid-margin-md-0 stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <header id="page-header" className="">
                                <div className="d-flex align-items-center">
                                    <div className="mr-auto">
                                        <div className="page-context-header">
                                            <div className="page-header-headings">
                                                <h1 className="h2">{clase.titulo}</h1>
                                                <p>{clase.resumen}</p>
                                                {videoId ? (
                                                    <div className="video-container">
                                                        <div class="ratio ratio-16x9">
                                                            <iframe src={`https://www.youtube.com/embed/${videoId}`} title="YouTube video" allowfullscreen></iframe>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    clase.url && (
                                                        <p>
                                                            <Link to={clase.url} target="_blank">
                                                                Recurso
                                                            </Link>
                                                        </p>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </header>
                            <div id="page-content" className=" d-print-block row align-items-center">
                                <div id="region-main-box">
                                    <section>
                                        <span className="notifications" id="user-notifications"></span>
                                        <span id="maincontent"></span>
                                        <div className="activity-header">
                                            <div data-region="activity-information" data-activityname="(Mobile assignment) View from your window" className="activity-information">
                                                <div className="completion-info" data-region="completion-info">
                                                    {clase.realizada === 1 ? (
                                                        <div className="badge badge-success">Realizada</div>
                                                    ) : (
                                                        <div className="badge badge-warning">Pendiente</div>
                                                    )}
                                                </div>
                                                <div data-region="activity-dates" className="activity-dates">
                                                    <div>
                                                        <strong>Fecha:</strong> {clase.fecha}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="activity-description">
                                                <div className="box py-3 generalbox boxaligncenter">
                                                    <div className="no-overflow">
                                                        <p dangerouslySetInnerHTML={{ __html: clase.descripcion }}></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    {archivo_firmado && (
                                                        <FileViewer
                                                            fileName={clase.archivo}
                                                            filePath={archivo_firmado}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <form onSubmit={handleSubmit}>
                <div className="mt-3 row">
                    <div className="col-md-7 grid-margin grid-margin-md-0 stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Registro de Asistencia</h4>
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th className="pt-1 ps-0">Estudiante</th>
                                                <th className="pt-1">Asistencia</th>
                                                <th className="pt-1">Participación</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {estudiantes.map(estudiante => (
                                                <tr key={estudiante.id}>
                                                    <td>{estudiante.nombres}</td>
                                                    <td>
                                                        <select
                                                            className="form-select form-select-sm mb-3"
                                                            value={asistencia[estudiante.id] || ''}
                                                            onChange={(e) => handleAsistenciaChange(estudiante.id, e.target.value)}
                                                            disabled={user.estudiante === 1 || materia.id_docente !== user.id_docente}
                                                        >
                                                            <option value="1">Asistencia</option>
                                                            <option value="2">Atraso</option>
                                                            <option value="3">Falta no Justificada</option>
                                                            <option value="4">Falta Justificada</option>
                                                        </select>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="form-check form-check-flat form-check-primary justify-content-center">
                                                            <label className="form-check-label">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input ms-5"
                                                                    name={`participacion[${estudiante.id}]`}
                                                                    checked={participacion[estudiante.id] === 1}
                                                                    onChange={(e) => handleParticipacionChange(estudiante.id, e.target.checked)}
                                                                    disabled={user.estudiante === 1 || materia.id_docente !== user.id_docente}
                                                                
                                                                />
                                                                <i className="input-helper"></i>
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-md-5 row">
                        <div className="grid-margin grid-margin-md-0 stretch-card">
                            <div className="card">
                                <div className="card-body text-center">
                                    <div>
                                        <div className="mb-3 form-floating">
                                            <textarea
                                                className="form-control"
                                                id="comentario"
                                                name="comentario"
                                                maxLength="500"
                                                value={comentario}
                                                onChange={(e) => setComentario(e.target.value)}
                                                disabled={user.estudiante === 1 || materia.id_docente !== user.id_docente}
                                            />
                                            <label htmlFor="comentario">Comentarios</label>
                                        </div>
                                    </div>
                                    <div className="form-check">
                                        <label className="form-check-label">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="realizada"
                                                name="realizada"
                                                checked={realizada}
                                                onChange={(e) => setRealizada(e.target.checked)}
                                                disabled={user.estudiante === 1 || materia.id_docente !== user.id_docente}
                                            />
                                            <i className="input-helper"></i>
                                            Finalizada
                                        </label>
                                    </div>
                                    {user.estudiante !== 1 && materia.id_docente === user.id_docente && (
                                        <>
                                        <button className="btn btn-info btn-sm mt-3 mb-4">Guardar</button>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Show;