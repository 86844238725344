import axios from 'axios'
import React, {useState, useEffect} from 'react'
import { useNavigate, useParams , Link } from 'react-router-dom'
import { useApiContext  } from '../layout/Template';
import Spinner from '../widget/Spinner';
import FormContent from './FormContent';

const Edit = () => {
    const [loading, setLoading] = useState(true);
    const { apiUrl, accessToken } = useApiContext();
    const [formData, setFormData] = useState({
        id_docente: '',
        id_materia: '',
        cuantitativa: '',
        estado: true,
        orden: '',
    });
    const [docentes, setDocentes] = useState([]);
    const [materias, setMaterias] = useState([]);
    const [idCurso, setIdCurso] = useState(null); // Guardar el id_curso
    const [errors, setErrors] = useState({}); // Estado para errores de validación
    const navigate = useNavigate();
    const {id} = useParams();
    
    useEffect(() => {
        const getDatos = async () => {
            try {
                const response = await axios.get(`${apiUrl}/materiacurso/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Accept': 'application/json',
                    }
                });
                setDocentes(response.data.docentes);
                setMaterias(response.data.materias);
                const materia = response.data.materia;
                setFormData({
                    id_docente: materia.id_docente,
                    id_materia: materia.id_materia,
                    cuantitativa: materia.cuantitativa ? true : false,
                    estado: materia.estado,
                    orden: materia.orden,
                });
                setIdCurso(materia.id_curso);
                setLoading(false);
            } catch (error) {
                console.error('Error al obtener el curso:', error.response ? error.response.data : error.message);
                setLoading(false); // Desactivar el cargador en caso de error
            }
        };
        getDatos();
    }, [apiUrl, accessToken, id]);
    
    const update = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.put(`${apiUrl}/materiacurso/${id}`, formData, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            });
            navigate(`/MisCursos/Info/${idCurso}`);
            window.showSuccessPopup(response.data.message);
        } catch (error) {
            // Aquí capturamos el error
            if (error.response && error.response.status === 500) {
                // Mostrar el mensaje de error usando el método window.showErrorPopup
                window.showErrorPopup(error.response.data.message);
            } else {
                // Otras respuestas de error (no 500) o errores de conexión
                console.error('Error al asignar la materia:', error.message);
            }
        }
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className='py-3 px-3 content-wrapper'>
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2 className="">Actualizar Materia</h2>
                </div>
            </div>
            <div className="col-md-6 offset-md-3 grid-margin stretch-card mt-3">
                <div className="card">
                    <div className="card-body">
                        <form className="forms-sample" onSubmit={update}>
                            <FormContent
                                formData={formData}
                                setFormData={setFormData}
                                docentes={docentes}
                                materias={materias}
                                isEdit={true}
                                errors={errors}
                            />
                            <button type="submit" className="btn btn-primary me-2"><i className="fas fa-save"></i> Actualizar Materia</button>
                            <Link className="btn btn-warning" to={`/MisCursos/Info/${idCurso}`}>Volver</Link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Edit;
