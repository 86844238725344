import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useApiContext  } from './Template';
import { useNavigate } from 'react-router-dom'
import NotificationDropdown from '../widget/NotificationDropdown';
import ChangePasswordModal from '../widget/ChangePasswordModal';
import Impersonate from '../widget/Impersonate';
import BorrarCache from '../widget/BorrarCache';
import Tooltip from '../widget/Tooltip';
import userImage from '../../../assets/img/user_profile.png'; 
import logoSqr from '../../../assets/img/edusnap/logo_sqr_comprimido.png'; 
import logoRect from '../../../assets/img/edusnap/logo_hotizontal.png'; 

const Navbar = ({ handleSidebarToggle }) => {
    const { apiUrl, accessToken, user, subdominio } = useApiContext();
    const navigate = useNavigate();
    const logoutEndpoint = `${apiUrl}/logout`;
    const [showPwdModal, setShowPwdModal] = useState(false);

    const handleOpenPwdModal = () => {
        setShowPwdModal(true);
    };

    const handleClosePwdModal = () => {
        setShowPwdModal(false);
    };

    const logout = async () => {
        try {
            const accessToken = localStorage.getItem('accessToken');
            if (!accessToken) {
                throw new Error('No hay token de acceso almacenado');
            }
            const response = await axios.post(logoutEndpoint, {}, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });

            console.log(response.data); // Verificar la respuesta
            localStorage.clear();
            navigate('/Login');
        } catch (error) {
            console.error('Error al cerrar sesión:', error.response ? error.response.data : error.message);
        }
    };

    return (
        <>
        <ChangePasswordModal show={showPwdModal} handleClose={handleClosePwdModal} apiUrl={apiUrl} accessToken={accessToken} />
        <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row" style={{ zIndex: '1029 !important'  }}>
            <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
                <div className="me-3">
                    <div className="logo_esquina" id="logo_esquina">
                        <Link to="/dashboard" className="navbar-brand brand-logo">
                            <img src={logoSqr} className="hidden-on-mobile" alt="logo_edusnap3" width="30"/>
                        </Link>
                    </div>
                </div>
                <div>
                    <Link to="/dashboard" className="navbar-brand brand-logo">
                        <img src={logoRect} alt="logo_edusnap1"/>
                    </Link>
                    <Link to="/dashboard" className="navbar-brand brand-logo-mini">
                        <img src={logoSqr} alt="logo_edusnap2"/>
                    </Link>
                </div>
            </div>
            <div className="navbar-menu-wrapper d-flex align-items-top">
                <ul className="navbar-nav">
                    <li className="nav-item font-weight-semibold d-none d-lg-block ms-0">
                    </li>
                </ul>
                <ul className="navbar-nav ms-auto">
                    {user && (user.estudiante === 0) && subdominio === "pordefinir" &&(
                    <li className="nav-item dropdown d-lg-block user-dropdown">
                        <Tooltip text="Suspensión por falta de pago">
                            <Link to="#" className="btn btn-danger btn-sm">
                                <i className="fas fa-exclamation-triangle"></i> Alerta de Suspensión
                            </Link>
                        </Tooltip>
                    </li>
                    )}
                    {user && (user.id_rol === 1 || user.superuser || localStorage.getItem('accessToken_old')) &&(
                        <Impersonate  apiUrl={apiUrl} accessToken={accessToken} />
                    )}
                    <BorrarCache />
                    <NotificationDropdown  apiUrl={apiUrl} accessToken={accessToken}/>
                    {user && (user.id_rol === 1 || user.superuser) &&(
                        <li className="nav-item">
                            <Link to="/config" className="nav-link">
                                <i className="fas fa-cog"></i>
                            </Link>
                        </li>
                    )}
                    <li className="nav-item dropdown d-lg-block user-dropdown">
                        <Link to="#" className="nav-link" id="UserDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                            <img className="img-xs rounded-circle" src={userImage} alt="Profile image1" /> 
                        </Link>
                        <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="UserDropdown">
                            {user ? (
                                <>
                                    <div className="dropdown-header text-center">
                                        <img className="img-xs rounded-circle" src={userImage} alt="Profile image2" />
                                        <p className="mb-1 mt-3 font-weight-semibold">{user.name}</p>
                                        <p className="fw-light text-muted mb-0">{user.email}</p>
                                    </div>
                                    <Link to="#" className="dropdown-item" onClick={handleOpenPwdModal}>
                                        <i className="dropdown-item-icon fas fa-key text-primary me-2"></i> Cambiar Contraseña
                                    </Link>
                                    <Link to="#" onClick={logout}className="dropdown-item">
                                        <i className="dropdown-item-icon fas fa-power-off text-primary me-2"></i> Cerrar Sesión
                                    </Link>
                                </>
                            ) : (
                                <p>Cargando datos del usuario...</p>
                            )}
                        </div>
                    </li>
                </ul>
                <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={handleSidebarToggle}>
                    <span><i className="fas fa-bars"></i></span>
                </button>
            </div>
        </nav>
        </>
    );
};

export default Navbar;