import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useApiContext } from '../layout/Template';
import Spinner from '../widget/Spinner';
import Swal from 'sweetalert2';
import ConfigNavTab from '../config/ConfigNavTab';
import TextInput from '../../form/InputText';

const Roles = ({ isActive }) => {
    const [loading, setLoading] = useState(true);
    const { apiUrl, accessToken } = useApiContext();
    const [roles, setRoles] = useState([]);
    const [descripcion, setDescripcion] = useState(''); // Estado para la descripción
    const [editDescripcion, setEditDescripcion] = useState(''); // Descripción en el modal de edición
    const [editRoleId, setEditRoleId] = useState(null); // ID del rol que se está editando
    const [errors, setErrors] = useState({});

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const response = await axios.get(`${apiUrl}/roles`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                },
            });
            setRoles(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching roles', error.response ? error.response.data : error.message);
        }
    };

    const toggleStatus = async (id, currentStatus) => {
        try {
            const newStatus = currentStatus === 1 ? 0 : 1;
            await axios.patch(`${apiUrl}/roles/${id}/status`, { status: newStatus }, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                },
            });
            setRoles(roles.map(role => role.id === id ? { ...role, estado: newStatus } : role));
        } catch (error) {
            console.error('Error updating status', error.response ? error.response.data : error.message);
        }
    };

    const handleDelete = async (id) => {
        const result = await Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás recuperar este rol después de eliminarlo!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
        });
    
        if (result.isConfirmed) {
            try {
                await axios.delete(`${apiUrl}/roles/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Accept': 'application/json',
                    },
                });
                setRoles(roles.filter(role => role.id !== id));
                Swal.fire({
                    icon: 'success',
                    title: 'Eliminado',
                    text: 'El rol ha sido eliminado correctamente.',
                });
            } catch (error) {
                console.error('Error deleting role:', error.response ? error.response.data : error.message);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'No se pudo eliminar el rol. Por favor, inténtelo de nuevo.',
                });
            }
        }
    };

    const handleCreateRole = async () => {
        try {
            await axios.post(`${apiUrl}/roles`, { descripcion }, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                },
            });
            getData();
            Swal.fire({
                icon: 'success',
                title: 'Rol creado',
                text: 'El rol se ha creado exitosamente.',
            });
        } catch (error) {
            if (error.response) {
                setErrors(error.response.data.errors);
            } else {
                console.error('Error al crear el rol:', error.response ? error.response.data : error.message);
            }
        }
    };

    const handleEdit = (role) => {
        setEditRoleId(role.id);
        setEditDescripcion(role.descripcion);
    };
    const handleUpdateRole = async (event) => {
        event.preventDefault(); // Para evitar el comportamiento por defecto
        try {
            const response = await axios.put(`${apiUrl}/roles/${editRoleId}`, { descripcion: editDescripcion }, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            });
    
            // Actualiza la lista de roles después de la edición
            getData();
            Swal.fire({
                icon: 'success',
                title: 'Rol actualizado',
                text: 'La descripción del rol se ha actualizado correctamente.',
            });
        } catch (error) {
            if (error.response) {
                // Manejar errores de validación
                setErrors(error.response.data.errors);
            } else {
                console.error('Error actualizando el rol', error.response ? error.response.data : error.message);
            }
        }
    };
    
    if (loading) {
        return <Spinner />;
    }

    return (
        <div className='py-3 px-3 content-wrapper'>
            <ConfigNavTab isActive={isActive} />
            <div className="d-sm-flex justify-content-between align-items-start mb-3">
                <div>
                    <h2>Consultar Roles</h2>
                </div>
                <div>
                    <div className="btn-group" role="group" aria-label="Basic outlined example">
                        <button type="button" className="btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#createRoleModal">
                            <i className="fas fa-plus-circle"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div className="col-12 grid-margin stretch-card">
                <div className="card card-rounded">
                    <div className="card-body">
                        <div className="table-responsive mt-1">
                            <table className="table select-table">
                                <thead>
                                    <tr>
                                        <th>Descripción</th>
                                        <th>Estado</th>
                                        <th>Creación</th>
                                        <th>Opciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {roles.map(rol => (
                                        <tr style={{ fontSize: '20px' }} key={rol.id}>
                                            <td>{rol.descripcion}</td>
                                            <td>
                                                <button
                                                    onClick={() => toggleStatus(rol.id, rol.estado)}
                                                    className={`btn btn-sm ${rol.estado === 1 ? 'btn-success' : 'btn-danger'}`}
                                                >
                                                    <i className={`fas fa-${rol.estado === 1 ? 'check' : 'times'}`}></i>
                                                </button>
                                            </td>
                                            <td>{new Date(rol.created_at).toLocaleDateString()}</td>
                                            <td>
                                                <div className="dropdown">
                                                    <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown">
                                                        <i className="fas fa-cogs"></i>
                                                    </button>
                                                    <ul className="dropdown-menu">
                                                        <li>
                                                            <button className="dropdown-item" onClick={() => handleEdit(rol)} data-bs-toggle="modal" data-bs-target="#editRoleModal">Editar</button>
                                                        </li>
                                                        <li>
                                                            <button className="dropdown-item" onClick={() => handleDelete(rol.id)}>Eliminar</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal de Crear Rol */}
            <div className="modal fade" id="createRoleModal" tabIndex="-1" aria-labelledby="createRoleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="createRoleModalLabel">Crear Rol</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                        </div>
                        <div className="modal-body">
                            <TextInput
                                label="Descripción"
                                name="descripcion"
                                value={descripcion}
                                onChange={(e) => setDescripcion(e.target.value)}
                                placeholder="Ingrese descripción del rol"
                                errors={errors}
                            />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                            <button type="button" className="btn btn-primary" onClick={handleCreateRole}>Crear</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal de Editar Rol */}
            <div className="modal fade" id="editRoleModal" tabIndex="-1" aria-labelledby="editRoleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="editRoleModalLabel">Editar Rol</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                        </div>
                        <div className="modal-body">
                            <TextInput
                                label="Descripción"
                                name="editDescripcion"
                                value={editDescripcion}
                                onChange={(e) => setEditDescripcion(e.target.value)}
                                placeholder="Ingrese nueva descripción"
                                errors={errors}
                            />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                            <button type="button" className="btn btn-primary" onClick={handleUpdateRole}>Guardar cambios</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Roles;