import React from 'react';
import TextInput from '../../form/InputText';
import SelectInput from '../../form/SelectInput';
import SimpleFileUpload from '../../form/SimpleFileUpload';

const FormContent = ({ formData, setFormData, cantones, tiposEscuela, handleFileChange, errors }) => {

    // Definir handleInputChange dentro del componente
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <div className="">
            {/* Checkboxes */}
            <TextInput
                id="nombre"
                name="nombre"
                value={formData.nombre || ''}
                onChange={handleInputChange}
                placeholder="Nombre Institución"
                errors={errors}
            />
            <TextInput
                id="nivel"
                name="nivel"
                value={formData.nivel || ''}
                onChange={handleInputChange}
                placeholder="Nivel De Educación"
                errors={errors}
            />
            <div className='row'>
                <div className='col-lg-6'>
                    <TextInput
                        id="direccion"
                        name="direccion"
                        value={formData.direccion || ''}
                        onChange={handleInputChange}
                        placeholder="Dirección"
                        errors={errors}
                    />
                </div>
                <div className='col-lg-6'>
                    <TextInput
                        id="sucursal"
                        name="sucursal"
                        value={formData.sucursal || ''}
                        onChange={handleInputChange}
                        placeholder="Sucursal"
                        errors={errors}
                    />
                </div>
                <div className='col-lg-6'>
                    <TextInput
                        id="correo"
                        name="correo"
                        value={formData.correo || ''}
                        onChange={handleInputChange}
                        placeholder="Correo"
                        errors={errors}
                    />
                </div>
                <div className='col-lg-4 col-md-6 col-sm-6'>
                    <TextInput
                        id="telefono"
                        name="telefono"
                        value={formData.telefono || ''}
                        onChange={handleInputChange}
                        placeholder="Telefonos"
                        errors={errors}
                    />
                </div>
                <div className='col-lg-4 col-md-6 col-sm-6'>
                    <TextInput
                        id="codigo"
                        name="codigo"
                        value={formData.codigo || ''}
                        onChange={handleInputChange}
                        placeholder="Amie"
                        errors={errors}
                    />
                </div>
                <div className='col-lg-4 col-md-6 col-sm-6'>
                    <SelectInput
                        id="id_tipo"
                        name="id_tipo"
                        value={formData.id_tipo}
                        onChange={handleInputChange}
                        placeholder="Tipo Escuela"
                        options={tiposEscuela.map(tipo => ({
                            value: tipo.id,
                            label: tipo.descripcion
                        }))}
                        error={errors['id_tipo'] && errors['id_tipo'][0]}
                    />
                </div>
                <div className='col-lg-4 col-md-6 col-sm-6'>
                    <SelectInput
                        id="id_canton"
                        name="id_canton"
                        value={formData.id_canton}
                        onChange={handleInputChange}
                        placeholder="Cantón"
                        options={cantones.map(tipo => ({
                            value: tipo.id,
                            label: tipo.nombre
                        }))}
                        error={errors['id_canton'] && errors['id_canton'][0]}
                    />
                </div>
                <div className='col-lg-4 col-md-6 col-sm-6'>
                    <TextInput
                        id="parroquia"
                        name="parroquia"
                        value={formData.parroquia || ''}
                        onChange={handleInputChange}
                        placeholder="Parroquia"
                        errors={errors}
                    />
                </div>
                <div className='col-lg-4 col-md-6 col-sm-6'>
                    <SimpleFileUpload
                        label="Logo"
                        name="logo"
                        accept="image/png, image/jpeg"
                        fileUrl={formData.logoView}
                        handleFileChange={handleFileChange('logo')}
                        buttonText="Ver Logo"
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                    <TextInput
                        id="rector"
                        name="rector"
                        value={formData.rector || ''}
                        onChange={handleInputChange}
                        placeholder="Rector"
                        errors={errors}
                    />
                </div>
                <div className='col-lg-4 col-md-3 col-sm-6'>
                    <TextInput
                        id="rector_identificacion"
                        name="rector_identificacion"
                        value={formData.rector_identificacion || ''}
                        onChange={handleInputChange}
                        placeholder="Identificación Rector"
                        errors={errors}
                    />
                </div>
                <div className='col-lg-4 col-md-3 col-sm-6'>
                    <TextInput
                        id="cargo_rector"
                        name="cargo_rector"
                        value={formData.cargo_rector || ''}
                        onChange={handleInputChange}
                        placeholder="Cargo Rector"
                        errors={errors}
                    />
                </div>
                <div className='col-lg-4 col-md-6 col-sm-6'>
                    <SimpleFileUpload
                        label="Firma Rector"
                        name="firma_rector"
                        accept="image/png, image/jpeg"
                        fileUrl={formData.firmaRector}
                        handleFileChange={handleFileChange('firma_rector')}
                        buttonText="Ver Firma"
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-6'>
                    <TextInput
                        id="vicerector"
                        name="vicerector"
                        value={formData.vicerector || ''}
                        onChange={handleInputChange}
                        placeholder="Vicerector"
                        errors={errors}
                    />
                </div>
                <div className='col-6'>
                    <TextInput
                        id="vicerector_identificacion"
                        name="vicerector_identificacion"
                        value={formData.vicerector_identificacion || ''}
                        onChange={handleInputChange}
                        placeholder="Identificación Vicerector"
                        errors={errors}
                    />
                </div>
                <div className='col-6'>
                    <TextInput
                        id="secretario"
                        name="secretario"
                        value={formData.secretario || ''}
                        onChange={handleInputChange}
                        placeholder="Secretario"
                        errors={errors}
                    />
                </div>
                <div className='col-6'>
                    <TextInput
                        id="secretario_identificacion"
                        name="secretario_identificacion"
                        value={formData.secretario_identificacion || ''}
                        onChange={handleInputChange}
                        placeholder="Identificación Secretario/a"
                        errors={errors}
                    />
                </div>
                <div className='col-6'>
                    <TextInput
                        id="tesoreria"
                        name="tesoreria"
                        value={formData.tesoreria || ''}
                        onChange={handleInputChange}
                        placeholder="Tesoreria"
                        errors={errors}
                    />
                </div>
                <div className='col-6'>
                    <TextInput
                        id="tesoreria_identificacion"
                        name="tesoreria_identificacion"
                        value={formData.tesoreria_identificacion || ''}
                        onChange={handleInputChange}
                        placeholder="Identificación Tesoreria"
                        errors={errors}
                    />
                </div>
                <div className='col-6'>
                    <TextInput
                        id="gerente"
                        name="gerente"
                        value={formData.gerente || ''}
                        onChange={handleInputChange}
                        placeholder="Gerente"
                        errors={errors}
                    />
                </div>
                <div className='col-6'>
                    <TextInput
                        id="gerente_identificacion"
                        name="gerente_identificacion"
                        value={formData.gerente_identificacion || ''}
                        onChange={handleInputChange}
                        placeholder="Identificación Gerente"
                        errors={errors}
                    />
                </div>
            </div>
        </div>
    );
};

export default FormContent;
