// src/routes/MovimientosRoutes.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from '../componentes/auth/ProtectedRoute';
import Template from '../componentes/client/layout/Template';

// movimientos
import Index from '../componentes/client/movimientos/Index';
import Create from '../componentes/client/movimientos/Create';
import Show from '../componentes/client/movimientos/Show';
import Edit from '../componentes/client/movimientos/Edit';

const MovimientosRoutes = () => {
    return (
        <Routes>
            <Route
                path=""
                element={
                    <ProtectedRoute>
                        <Template>
                            <Index />
                        </Template>
                    </ProtectedRoute>
                }
            />
            <Route
                path="create"
                element={
                    <ProtectedRoute>
                        <Template>
                            <Create />
                        </Template>
                    </ProtectedRoute>
                }
            />
            <Route
                path=":comprobanteId"
                element={
                    <ProtectedRoute>
                        <Template>
                            <Show />
                        </Template>
                    </ProtectedRoute>
                }
            />
            <Route
                path=":comprobanteId/edit"
                element={
                    <ProtectedRoute>
                        <Template>
                            <Edit />
                        </Template>
                    </ProtectedRoute>
                }
            />
        </Routes>
    );
};

export default MovimientosRoutes;
