import React, { useEffect, useRef } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

const QuillEditor = ({ name, value, onChange, errors, disabled = false }) => {
    const quillRef = useRef(null);
    const quillInstance = useRef(null);

    useEffect(() => {
        // Inicializa Quill solo una vez
        if (!quillInstance.current) {
            quillInstance.current = new Quill(quillRef.current, {
                theme: 'snow',
                modules: {
                    toolbar: [
                        ['bold', 'italic'],
                        ['link', 'blockquote', 'code-block'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                    ],
                },
            });

            // Manejar el evento de cambio de texto
            quillInstance.current.on('text-change', () => {
                const content = quillInstance.current.root.innerHTML;
                onChange({
                    target: {
                        name: name,
                        value: content,
                    },
                });
            });
        }

        // Establecer el contenido del editor cuando cambia el prop 'value'
        if (quillInstance.current && value !== quillInstance.current.root.innerHTML) {
            quillInstance.current.root.innerHTML = value || ''; // Asegúrate de que no sea undefined
        }
        
        // Deshabilitar o habilitar el editor
        if (disabled) {
            quillInstance.current.enable(false); // Deshabilitar
        } else {
            quillInstance.current.enable(true); // Habilitar
        }
    }, [value, disabled]); // Reaccionar a cambios en 'value' y 'disabled'
    
    const error = errors?.[name];

    return (
        <div>
            <div 
              ref={quillRef}
              style={{
                height: '200px',
                borderColor: error ? 'red' : '#ced4da',
                borderWidth: '1px', // Asegúrate de que el borde se vea
                borderStyle: 'solid',
              }}
            />
            {error && (
              <div className="invalid-feedback" style={{ marginTop: '5px', display: 'block' }}>
                {error[0]}
              </div>
            )}
        </div>
    );
};

export default QuillEditor;
